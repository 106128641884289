import { SvgIcon, SvgIconProps } from '@mui/material';

export const ZipIcon = (props: SvgIconProps) => (
	<SvgIcon {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M4 2.5C4 2.22386 4.22386 2 4.5 2H12.5C12.6326 2 12.7598 2.05268 12.8536 2.14645L19.8536 9.14645C19.9473 9.24021 20 9.36739 20 9.5V21.5C20 21.7761 19.7761 22 19.5 22H4.5C4.22386 22 4 21.7761 4 21.5V2.5ZM5 3V21H19V10H12.5C12.2239 10 12 9.77614 12 9.5V3H5ZM13 3.70711L18.2929 9H13V3.70711ZM15.3021 14.9958L13.6643 15.0074V18.9901H14.4471V17.5944C14.6277 17.5983 14.8987 17.6003 15.26 17.6003C15.6494 17.6003 15.9765 17.5496 16.2415 17.4484C16.5104 17.3472 16.7112 17.1993 16.8436 17.0046C16.9801 16.81 17.0483 16.5725 17.0483 16.2922C17.0483 16.008 16.9841 15.7705 16.8557 15.5797C16.7312 15.3851 16.5405 15.2391 16.2836 15.1418C16.0267 15.0444 15.6995 14.9958 15.3021 14.9958ZM16.121 15.9243C16.1893 16.0216 16.2234 16.1481 16.2234 16.3038C16.2234 16.4557 16.1893 16.5803 16.121 16.6776C16.0568 16.7749 15.9545 16.8489 15.814 16.8995C15.6775 16.9462 15.5008 16.9696 15.2841 16.9696C14.9027 16.9696 14.6237 16.9676 14.4471 16.9637V15.6381C14.6197 15.6342 14.8987 15.6323 15.2841 15.6323C15.5008 15.6323 15.6775 15.6556 15.814 15.7024C15.9545 15.7491 16.0568 15.823 16.121 15.9243ZM10.3365 18.9901H7.01263V18.3477L9.25863 15.6381C8.96157 15.642 8.50796 15.644 7.89778 15.644H7.10296V15.0074H10.2461V15.644L7.99413 18.3536C8.59627 18.3458 9.01576 18.3419 9.25261 18.3419H10.3365V18.9901ZM11.5287 15.0074H12.3115V18.9901H11.5287V15.0074Z"
		/>
	</SvgIcon>
);
