import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import { useProductCenterApi } from 'api';
import { Namespace, ParseKeys } from 'i18next';
import { getCustomErrorWithAction } from 'library/snackbar';
import { ProductIndividualTitleLinksAction } from 'modules/ProductIndividualview/Components';
import { validateSearchProductResponse } from 'modules/Shared/State/unit';

export const useReplacementValidation = () => {
	const { enqueueSnackbar } = useSnackbar();
	const { t } = useTranslation<Namespace>();
	const { api } = useProductCenterApi();

	const isSerialNumberValid = async (
		serialNumber: string
	): Promise<boolean> => {
		try {
			const trimmedInput = serialNumber.trim();

			const response = await api.searchProductIndividualBySerialNumber({
				serialNumber: trimmedInput,
			});

			const errorResponse = validateSearchProductResponse(
				trimmedInput,
				response
			);

			if (errorResponse) {
				let additionalSnackbarConfiguration = {};

				const { serialNumber, errorMessage } = errorResponse;

				if (serialNumber) {
					additionalSnackbarConfiguration = getCustomErrorWithAction(
						<ProductIndividualTitleLinksAction
							serialNumbers={[serialNumber]}
							title={t('common:view-product')}
						/>
					);
				}

				enqueueSnackbar(t(errorMessage as ParseKeys<Namespace>), {
					variant: 'error',
					...additionalSnackbarConfiguration,
				});

				return false;
			}

			return true;
		} catch {
			enqueueSnackbar(t('common:error-unexpected'), {
				variant: 'error',
			});
		}

		return false;
	};

	return { isSerialNumberValid };
};
