import { useRef, useState } from 'react';

export interface UseDialogStateReturnType<T = unknown> {
	open: boolean;
	data?: T;
	handleOpen: (data?: T) => void;
	handleClose: () => void;
	toggleDialog: () => void;
}

export const useDialogState = <T = unknown>(
	initialState?: boolean,
	initialData?: T
): UseDialogStateReturnType<T> => {
	const [data, setData] = useState(initialData);
	const [open, setOpen] = useState(initialState || false);
	const handleOpen = useRef((data?: T) => {
		setData(data);
		setOpen(true);
	});

	const handleClose = useRef(() => {
		setData(initialData);
		setOpen(false);
	});

	const toggleDialog = useRef(() => {
		setOpen((prev) => !prev);
	});

	return {
		data,
		open,
		handleOpen: handleOpen.current,
		handleClose: handleClose.current,
		toggleDialog: toggleDialog.current,
	};
};
