import {
	FormControl,
	FormControlLabel,
	FormControlLabelProps,
	FormGroup,
	FormHelperText,
	Switch,
	SwitchProps,
	Typography,
} from '@mui/material';
import {
	FieldPath,
	FieldValues,
	UseControllerProps,
	useController,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { displayError, getHelperText } from './functions';

export type ControlledSwitchProps<
	TFieldValues extends FieldValues = FieldValues,
	TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = Omit<SwitchProps, 'name'> &
	Pick<UseControllerProps<TFieldValues, TName>, 'name' | 'control'> & {
		validation?: UseControllerProps<TFieldValues, TName>['rules'];
		label?: FormControlLabelProps['label'];
		helperText?: string;
	};

export const ControlledSwitch = <
	TFieldValues extends FieldValues = FieldValues,
	TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
	name,
	validation = {},
	required,
	disabled,
	label,
	control,
	helperText,
	sx,
	...rest
}: ControlledSwitchProps<TFieldValues, TName>): JSX.Element => {
	const { t } = useTranslation();

	if (Boolean(validation.required) && !required) {
		required = true;
	}

	const {
		field: { onChange, value },
		fieldState: { error },
	} = useController<TFieldValues, TName>({
		name,
		control,
		rules: validation,
	});

	const parsedHelperText = getHelperText(t, disabled, error, helperText);
	const isErrored = displayError(disabled, error);

	return (
		<FormControl required={required} disabled={disabled} error={isErrored}>
			<FormGroup row>
				<FormControlLabel
					control={
						<Switch
							{...rest}
							sx={[
								...(Array.isArray(sx) ? sx : [sx]),
								{
									color: isErrored ? 'error.main' : 'inherit',
								},
							]}
							value={value}
							checked={!!value}
							onChange={(_, checked: boolean) => {
								onChange(checked);
							}}
						/>
					}
					label={
						<Typography
							sx={[
								...(Array.isArray(sx) ? sx : [sx]),
								{
									color: isErrored ? 'error.main' : 'inherit',
								},
							]}>
							{label}
							{required && (
								<Typography component="span" color="error">
									{' *'}
								</Typography>
							)}
						</Typography>
					}
				/>
			</FormGroup>
			{parsedHelperText && (
				<FormHelperText error={isErrored}>
					{parsedHelperText}
				</FormHelperText>
			)}
		</FormControl>
	);
};
