import { Link, Tooltip } from '@mui/material';
import {
	GridRenderCellParams,
	GridTreeNodeWithRender,
} from '@mui/x-data-grid-pro';
import { memo } from 'react';

import { ServiceProtocolDto } from 'api';
import { CheckmarkCircle, Remove } from 'assets/volvoIcons/Control';
import { ServiceProtocolStatus } from 'domain/serviceProtocol/ServiceProtocolDto';
import { getOldPCServiceProtocolDetailsLink } from 'library/components/link';

const ServiceProtocolStatusCellInternal = ({
	row,
}: GridRenderCellParams<
	ServiceProtocolDto,
	never,
	never,
	GridTreeNodeWithRender
>): JSX.Element => {
	return (
		// TODO: switch to link to RWD, currently redirects to old MVC, which will redirect back
		<Link href={getOldPCServiceProtocolDetailsLink(row.id)}>
			<ServiceProtocolSymbol status={row.status} />
		</Link>
	);
};

export const ServiceProtocolStatusCell = memo(
	ServiceProtocolStatusCellInternal
);

const ServiceProtocolSymbol = ({
	status,
}: {
	status: ServiceProtocolStatus;
}): JSX.Element | null => {
	switch (status) {
		case 'Cancelled':
			return (
				<Tooltip title={'Cancelled'}>
					<span>
						<Remove color="disabled" />
					</span>
				</Tooltip>
			);
		case 'Finalized':
			return (
				<Tooltip title={'Finalized'}>
					<span>
						<CheckmarkCircle color="success" />
					</span>
				</Tooltip>
			);
		case 'Initiated':
			return (
				<Tooltip title={'Initiated'}>
					<span>
						<CheckmarkCircle color="warning" />
					</span>
				</Tooltip>
			);
		default:
			return null;
	}
};
