import { ProductIndividualType } from 'domain/unit/enums';

export interface IndividualReplaceProps {
	unitId: number;
	chassisId: string;
	matchingIndividualsToSwap?: string[];
	productCategory: ProductIndividualType;
	serialNumber: string;
}

export type IndividualReplaceAccordionType =
	| 'RepowerPurposeUpdateDriveLine'
	| 'RepowerPurposeKeepDriveLine'
	| 'ChangedUnderWarrantyKeepDriveLine'
	| 'CorrectingError'
	| 'ReplaceLongBlock'
	| 'SwapIndividual'
	| null;

export interface ReplaceIndividualFormType {
	newSerialNumber: string;
	replaceReason: string;
	approvalCaseNumber?: string;
}

export interface SwapIndividualFormType {
	targetSerialNumber: string;
	replaceReason: string; // backend does not accept this field
}

export const isEngine = (productCategory: ProductIndividualType): boolean =>
	productCategory === 'Engine';

export const isEngineType = (productCategory: ProductIndividualType): boolean =>
	productCategory === 'Engine' || productCategory === 'Outboard';
