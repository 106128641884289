import { Typography } from '@mui/material';
import { getGridSingleSelectOperators } from '@mui/x-data-grid-pro';
import { memo } from 'react';

import {
	LocationText,
	LocationTextProps,
} from 'modules/Shared/Components/OperatingLocation/LocationText';

import { CountryAutoCompleteInput } from '../Inputs/CountryAutoCompleteInput';

const CountryCellInternal = ({
	countryCode,
	city,
}: Pick<LocationTextProps, 'countryCode' | 'city'>): JSX.Element => (
	<Typography
		className="MuiDataGrid-cellContent"
		sx={{ display: 'flex', alignItems: 'center' }}>
		<LocationText countryCode={countryCode} city={city} />
	</Typography>
);

export const CountryCell = memo(CountryCellInternal);

export const getFilterOperators = getGridSingleSelectOperators()
	.filter((operator) => operator.value === 'is')
	.map((operator) => ({
		...operator,
		InputComponent: operator.InputComponent
			? CountryAutoCompleteInput
			: undefined,
	}));
