import { useTranslation } from 'react-i18next';

import { MainSegment } from 'api/responses/models/Unit/UnitStructure/MainSegment';
import { UnitIdentification } from 'api/responses/models/Unit/UnitStructure/UnitIdentification';
import { TextDisplay } from 'library/components';
import { isUnitIndustrial } from 'modules/Shared/State/unit';

export const UnitIdentificationDisplay = ({
	mainSegment,
	unitIdentification,
}: {
	mainSegment: MainSegment | null | undefined;
	unitIdentification: UnitIdentification | null | undefined;
}): JSX.Element => {
	const { t } = useTranslation('commonUnit');

	return isUnitIndustrial(mainSegment) ? (
		<TextDisplay
			label={t('machine-id')}
			value={unitIdentification?.hullMachineId}
		/>
	) : (
		<>
			<TextDisplay
				label={t('hull-id')}
				value={unitIdentification?.hullId}
			/>
			<TextDisplay
				label={t('imo-number')}
				value={unitIdentification?.imoNumber}
			/>
			<TextDisplay
				label={t('mmsi-number')}
				value={unitIdentification?.mmsiNumber}
			/>
			<TextDisplay
				label={t('call-sign')}
				value={unitIdentification?.callSign}
			/>
		</>
	);
};
