import { createEntityAdapter } from '@reduxjs/toolkit';

import { UnitOtherPartDto } from 'api';
import { getOtherPartId } from 'api/responses/models/Unit/UnitOtherPartsDto';

import { CreateUnitState } from './Types';

export const otherPartsList = createEntityAdapter<UnitOtherPartDto, string>({
	selectId: getOtherPartId,
});

export const otherPartsListSelectors =
	otherPartsList.getSelectors<CreateUnitState>((state) => state.otherParts);
