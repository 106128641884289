import { Button, ButtonProps } from '@mui/material';
import { HTMLAttributeAnchorTarget } from 'react';

import { getUnitActionsLink } from './linkUtils';

interface LegacyButtonProps
	extends Pick<
		ButtonProps,
		'variant' | 'color' | 'size' | 'sx' | 'disabled' | 'endIcon'
	> {
	label: string;
	mvcAction: string;
	target?: HTMLAttributeAnchorTarget;
	unitId: number;
}

export const LegacyButton = ({
	color = 'secondary',
	disabled = false,
	mvcAction,
	label,
	size = 'large',
	sx = {
		mt: 2,
	},
	target = '_self',
	unitId,
	variant = 'contained',
	...props
}: LegacyButtonProps) => (
	<Button
		disabled={disabled}
		color={color}
		href={getUnitActionsLink(mvcAction, unitId)}
		size={size}
		sx={sx}
		target={target}
		variant={variant}
		{...props}>
		{label}
	</Button>
);
