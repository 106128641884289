import { LoadingButton } from '@mui/lab';
import {
	Alert,
	AlertTitle,
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
} from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import { useProductCenterApi } from 'api';
import { unitKeys } from 'api/queries';
import { useCheckConsoleDebuggingReadAccess } from 'main/auth/checkUserAccess';

export interface CustomWarningDialogProps {
	unitId: number;
	isOpen: boolean;
	onAccept: () => void;
	onDecline: () => void;
	onCancel: () => void;
}

export const UpdateEVCWarning = ({
	unitId,
	isOpen,
	onAccept,
	onDecline,
	onCancel,
}: CustomWarningDialogProps): JSX.Element => {
	const { t } = useTranslation(['unitOverview', 'common', 'commonButton']);
	const { enqueueSnackbar } = useSnackbar();
	const { api } = useProductCenterApi();

	const { checkConsoleDebuggingReadAccess } =
		useCheckConsoleDebuggingReadAccess();
	const queryClient = useQueryClient();

	const evcUpdateMutation = useMutation<unknown, unknown, number>({
		mutationKey: unitKeys.evcStatus(unitId),
		mutationFn: (id) => api.updateEvcDriveline(id),
		onSuccess: async (_data, _) => {
			await queryClient.invalidateQueries({
				queryKey: unitKeys.single(unitId),
			});

			onAccept();
		},
		onError: (error) => {
			checkConsoleDebuggingReadAccess() && console.error(error);
			enqueueSnackbar((error as Error)?.message, {
				variant: 'error',
				style: { whiteSpace: 'pre-line' },
			});
		},
	});

	const handleOnAccept = () => {
		evcUpdateMutation.mutate(unitId);
	};

	return (
		<Dialog open={isOpen} onClose={onDecline}>
			<DialogContent>
				<Alert severity={'warning'}>
					<AlertTitle>
						{t('unitOverview:edit-unit-configuration')}
					</AlertTitle>
					{t('unitOverview:update-EVC-status-window-text')}
				</Alert>
			</DialogContent>
			<DialogActions sx={{ justifyContent: 'space-between' }}>
				<Button
					variant="outlined"
					color="secondary"
					disableElevation
					disabled={evcUpdateMutation.isPending}
					onClick={onCancel}
					sx={{
						alignSelf: 'flex-start',
					}}>
					{t('commonButton:cancel')}
				</Button>
				<Box sx={{ 'button:nth-of-type(odd)': { mr: 2 } }}>
					<Button
						variant="outlined"
						color="secondary"
						disableElevation
						disabled={evcUpdateMutation.isPending}
						onClick={onDecline}>
						{t('commonButton:no')}
					</Button>
					<LoadingButton
						variant="contained"
						color="secondary"
						disableElevation
						disabled={evcUpdateMutation.isPending}
						loading={evcUpdateMutation.isPending}
						onClick={handleOnAccept}>
						{t('commonButton:yes')}
					</LoadingButton>
				</Box>
			</DialogActions>
		</Dialog>
	);
};
