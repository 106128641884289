import { Edit } from '@mui/icons-material';
import { useSuspenseQuery } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';

import { useProductCenterApi } from 'api';
import { unitEvcStatusQuery } from 'api/queries';
import { CustomSeverityDialog, SectionButton } from 'library/components';
import { useDialogState } from 'library/hooks';
import { unitEditPageUrl } from 'main/routes/urls/RouteUrls';

import { UpdateEVCWarning } from './UpdateEVCWarning';

export const UnitEditConfigurationButton = ({ unitId }: { unitId: number }) => {
	const { t } = useTranslation(['unitOverview', 'common', 'commonButton']);
	const navigate = useNavigate();
	const { open, handleClose, handleOpen } = useDialogState();
	const {
		open: evcWarningOpen,
		handleClose: evcWarningHandleClose,
		handleOpen: evcWarningHandleOpen,
	} = useDialogState();

	const { api } = useProductCenterApi();

	const { data: unitEvcStatus } = useSuspenseQuery(
		unitEvcStatusQuery(api, unitId)
	);

	const unitEditPath = useMemo(
		() =>
			generatePath(unitEditPageUrl, {
				unitId: unitId.toString(),
			}),
		[unitId]
	);

	const alertUser = (event: React.MouseEvent<HTMLButtonElement>) => {
		if (unitEvcStatus.unitControlSystemStatus === 'UpdateBlocked') {
			event.preventDefault();
			event.stopPropagation();
			handleOpen();
		} else if (unitEvcStatus.unitControlSystemStatus === 'Updated') {
			event.preventDefault();
			event.stopPropagation();
			evcWarningHandleOpen();
		} else {
			navigate(unitEditPath);
		}
	};

	const handleGoToEdit = useCallback(() => {
		handleClose();
		evcWarningHandleClose();
		navigate(unitEditPath);
	}, [handleClose, navigate, evcWarningHandleClose, unitEditPath]);

	return (
		<>
			<CustomSeverityDialog
				isOpen={open}
				headerText={t('unitOverview:edit-unit-configuration')}
				content={t(
					'unitOverview:update-EVC-blocked-status-window-text',
					{
						chassisId:
							unitEvcStatus.newControlSystemChassisId ?? '',
					}
				)}
				acceptButtonLabel={t('commonButton:ok')}
				declineButtonLabel={t('commonButton:cancel')}
				onDecline={handleClose}
				onAccept={handleGoToEdit}
			/>
			<UpdateEVCWarning
				unitId={unitId}
				isOpen={evcWarningOpen}
				onAccept={handleGoToEdit}
				onDecline={handleGoToEdit}
				onCancel={evcWarningHandleClose}
			/>
			<SectionButton
				text={t('unitOverview:edit-unit-configuration')}
				icon={<Edit />}
				isLink={true}
				onClick={alertUser}
				to={unitEditPath}
			/>
		</>
	);
};
