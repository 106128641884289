import { Close } from '@mui/icons-material';
import {
	Alert,
	AlertTitle,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	Theme,
	useMediaQuery,
} from '@mui/material';
import { PropsWithChildren, ReactNode } from 'react';

interface CustomInfoDialogProps {
	open: boolean;
	headerText: ReactNode;
	dialogTitle?: ReactNode;
	closeButtonLabel?: string;
	onClose: () => void;
}

export const CustomInfoDialog = ({
	open,
	headerText,
	dialogTitle,
	closeButtonLabel,
	onClose,
	children,
}: PropsWithChildren<CustomInfoDialogProps>) => {
	const fullScreen = useMediaQuery((theme: Theme) =>
		theme.breakpoints.only('xs')
	);

	return (
		<Dialog open={open} onClose={onClose} fullScreen={fullScreen}>
			{dialogTitle && (
				<DialogTitle sx={{ marginRight: 2 }}>
					{dialogTitle}
					<IconButton
						aria-label="close"
						onClick={onClose}
						sx={(theme) => ({
							position: 'absolute',
							right: theme.spacing(1),
							top: theme.spacing(1.5),
							color: theme.palette.grey[500],
						})}>
						<Close />
					</IconButton>
				</DialogTitle>
			)}
			<DialogContent>
				<Alert severity="info">
					<AlertTitle>{headerText}</AlertTitle>
					{children}
				</Alert>
			</DialogContent>
			{closeButtonLabel && (
				<DialogActions>
					<Button
						variant="contained"
						color="secondary"
						disableElevation
						onClick={onClose}>
						{closeButtonLabel}
					</Button>
				</DialogActions>
			)}
		</Dialog>
	);
};
