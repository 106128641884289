import { getVppnToken } from 'api/axios/vppn';

import { EmptyProvider } from './Empty';
import { MsalProvider } from './Msal';
import { AuthenticationProvider } from './Provider';
import { AuthProviderStorage } from './Storage';
import { VppnProvider } from './Vppn';

const defaultProvider = new EmptyProvider();

const initializeAuthProviderStorage = async () => {
	await getVppnToken()
		.then((token) => {
			AuthProviderStorage.initialize(
				token ? new VppnProvider(token) : new MsalProvider()
			);
		})
		.catch(() => {
			AuthProviderStorage.initialize(defaultProvider);
		});
};

const isVppn = (): boolean => {
	return (
		AuthProviderStorage.getInstance().getProvider() instanceof VppnProvider
	);
};

export {
	initializeAuthProviderStorage,
	isVppn,
	AuthenticationProvider,
	AuthProviderStorage,
	EmptyProvider,
	MsalProvider,
	VppnProvider,
};
