import { useTranslation } from 'react-i18next';

import { Unit } from 'api/responses/models/Unit/UnitStructure/Unit';
import { TextDisplay } from 'library/components';

import { IdentifiersContainer } from '../Components/IdentifiersContainer';

export const BrandModelViewSection = ({ unitData }: { unitData: Unit }) => {
	const { t } = useTranslation(['unitCreation', 'commonUnit']);

	return (
		<IdentifiersContainer
			variant="bottomBorder"
			title={t('unitCreation:unit-information-brand-model-title')}>
			<TextDisplay
				label={t('commonUnit:brand')}
				value={unitData?.brandOrType}
			/>
			<TextDisplay
				label={t('commonUnit:model')}
				value={unitData?.model}
			/>
		</IdentifiersContainer>
	);
};
