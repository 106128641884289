import { withAITracking } from '@microsoft/applicationinsights-react-js';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import { Button, Grid, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { getDeployAlertNotification } from 'library/redux/application';
import { useAppDispatch } from 'library/redux/hooks';
import { reactPlugin } from 'main/azureInsights/config';
import TitleWrapper from 'main/layout/TitleWrapper';
import SubApps from 'modules/Home/SubApps';
import 'modules/Home/assets/styles/HomeContent.scss';

const getHelpUrl = import.meta.env.VITE_URL_GET_HELP;

const backgroundArray = ['bg1', 'bg2', 'bg3', 'bg4', 'bg5', 'bg6'];
const randomIndex = Math.floor(Math.random() * backgroundArray.length);
const selectedBackground = backgroundArray[randomIndex];

const HomePage = (): JSX.Element => {
	const { t } = useTranslation(['home']);
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(getDeployAlertNotification());
	}, [dispatch]);

	return (
		<>
			<TitleWrapper
				category={t('home:content-label')}
				title={t('home:content-subTitle')}
			/>
			<Grid
				container
				minHeight={400}
				className={'homeContent homeContent--' + selectedBackground}>
				<Grid container className="homeContent homeContent--overlay">
					<Grid
						container
						className="homeContent homeContent--gradient">
						<Grid
							item
							xs={12}
							md={5}
							p={2}
							display="flex"
							alignItems="center"
							justifyContent="center">
							<Typography variant="h4">
								{t('home:content-label')}
							</Typography>
						</Grid>
						<Grid
							item
							xs={12}
							md={6}
							p={2}
							display="flex"
							flexDirection="column"
							justifyContent="center"
							className="homeContent__text">
							<Typography py={2}>
								{t('home:content-text2')}
							</Typography>
							{getHelpUrl && (
								<Button
									href={getHelpUrl}
									variant="outlined"
									color="primary"
									endIcon={<MenuBookIcon />}
									className="homeContent__button">
									{t('home:content-getHelp')}
								</Button>
							)}
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<Grid
				container
				direction="column"
				alignItems="center"
				justifyContent="center">
				<SubApps />
			</Grid>
		</>
	);
};

export default withAITracking(
	reactPlugin,
	HomePage,
	'HomePage',
	'application-insight-container'
);
