import {
	DataGridPro,
	DataGridProProps,
	GridLocaleText,
	GridValidRowModel,
} from '@mui/x-data-grid-pro';
import * as MUILocale from '@mui/x-data-grid/locales';
import { camelCase, reduce } from 'lodash';

import { selectLanguage } from 'library/redux/application';
import { useAppSelector } from 'library/redux/hooks';

const languageKeyMapper = (lang: string) => {
	if (lang === 'zhCn') return 'zhHans';
	return lang === 'arSd' ? 'arSa' : lang;
};

const locales = reduce(
	MUILocale,
	(result, value, key) => {
		result[languageKeyMapper(camelCase(key))] =
			value.components.MuiDataGrid.defaultProps.localeText;

		return result;
	},
	{} as { [key: string]: Partial<GridLocaleText> }
);

export const LocalizedDataGrid = <R extends GridValidRowModel = any>(
	props: DataGridProProps<R> & React.RefAttributes<HTMLDivElement>
) => {
	const language = useAppSelector(selectLanguage);

	return <DataGridPro localeText={locales[camelCase(language)]} {...props} />;
};
