import { FC, memo } from 'react';

export interface Item {
	id: number | string;
}

export interface ItemActionProps<T> {
	onClick?: (item: T | null) => void;
}
export interface ItemProps<T> {
	item: T;
	isSelected?: boolean;
}

export interface InfinityScrollProps<T extends Item, P>
	extends ItemActionProps<T> {
	data: readonly T[];
	Item: FC<ItemProps<T>>;
	itemProps?: P;
	size?: number;
}

export const withMemoItem =
	<T extends Item, P>(
		InfinityScroll: FC<InfinityScrollProps<T, P>>
	): FC<InfinityScrollProps<T, P>> =>
	({ Item, ...props }) => (
		<InfinityScroll
			Item={memo(
				Item,
				(
					{ item: { id }, isSelected },
					{ item: { id: nextId }, isSelected: nextIsSelected }
				) => id === nextId && isSelected === nextIsSelected
			)}
			{...props}
		/>
	);
