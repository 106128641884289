import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { unitStructureQuery } from 'api/queries';
import { Id } from 'domain/Id';
import { isDemoDealerRegisterButtonDisabled } from 'domain/unit';

import { LegacySection } from '../Components/LegacySection';

export const UnregisterDemoDealerUsage = ({ id }: Id) => {
	const { t } = useTranslation(['unitOverview']);
	const { data: unitData } = useQuery({
		...unitStructureQuery(id),
	});

	return (
		<LegacySection
			unitId={unitData?.id ?? 0}
			mvcAction={'UnitOverview/DemoUsageOverview'}
			buttonLabel={t('unitOverview:register-demo-usage')}
			buttonDisabled={isDemoDealerRegisterButtonDisabled(unitData)}
		/>
	);
};
