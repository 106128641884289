import { SvgIcon, SvgIconProps } from '@mui/material';

export const ImgIcon = (props: SvgIconProps) => (
	<SvgIcon {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M4 2.5C4 2.22386 4.22386 2 4.5 2H12.5C12.6326 2 12.7598 2.05268 12.8536 2.14645L19.8536 9.14645C19.9473 9.24021 20 9.36739 20 9.5V21.5C20 21.7761 19.7761 22 19.5 22H4.5C4.22386 22 4 21.7761 4 21.5V2.5ZM5 3V21H19V10H12.5C12.2239 10 12 9.77614 12 9.5V3H5ZM13 3.70711L18.2929 9H13V3.70711ZM9 12.9972C9.55362 12.9972 10 12.5494 10 12C10 11.4506 9.55362 11.0028 9 11.0028C8.44638 11.0028 8 11.4506 8 12C8 12.5494 8.44638 12.9972 9 12.9972ZM9 13.9972C10.1046 13.9972 11 13.103 11 12C11 10.897 10.1046 10.0028 9 10.0028C7.89543 10.0028 7 10.897 7 12C7 13.103 7.89543 13.9972 9 13.9972ZM13.5339 12.7273C13.7259 12.5542 14.0182 12.5561 14.208 12.7316L17.3395 15.6273C17.4418 15.7219 17.5 15.855 17.5 15.9944V18.9901C17.5 19.2663 17.2761 19.4901 17 19.4901H7C6.72386 19.4901 6.5 19.2663 6.5 18.9901V16.9929C6.5 16.8456 6.56502 16.7057 6.6777 16.6107L8.62004 14.973C8.80991 14.8129 9.08852 14.8164 9.27426 14.9813L10.1601 15.7676L13.5339 12.7273ZM13.8642 13.7757L10.4973 16.8098C10.3082 16.9803 10.0211 16.9813 9.83068 16.8123L8.93393 16.0163L7.5 17.2254V18.4901H16.5V16.213L13.8642 13.7757Z"
		/>
	</SvgIcon>
);
