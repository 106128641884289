import { SvgIcon, SvgIconProps } from '@mui/material';

export const PptIcon = (props: SvgIconProps) => (
	<SvgIcon {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M4.00003 2.5C4.00003 2.22386 4.22389 2 4.50003 2H12.5C12.6326 2 12.7598 2.05268 12.8536 2.14645L19.8536 9.14645C19.9474 9.24021 20 9.36739 20 9.5V21.5C20 21.7761 19.7762 22 19.5 22H4.50003C4.22389 22 4.00003 21.7761 4.00003 21.5V2.5ZM5.00003 3V21H19V10H12.5C12.2239 10 12 9.77614 12 9.5V3H5.00003ZM13 3.70711L18.2929 9H13V3.70711ZM6.70187 14.9039L8.33974 14.8919C8.73716 14.8919 9.06433 14.9418 9.32125 15.0417C9.57817 15.1415 9.76886 15.2913 9.8933 15.491C10.0218 15.6868 10.086 15.9304 10.086 16.222C10.086 16.5096 10.0177 16.7533 9.88126 16.953C9.74878 17.1527 9.54807 17.3045 9.2791 17.4083C9.01415 17.5122 8.68698 17.5641 8.29759 17.5641C7.93629 17.5641 7.66532 17.5621 7.48468 17.5581V18.9901H6.70187V14.9039ZM9.26104 16.234C9.26104 16.0742 9.22691 15.9444 9.15867 15.8445C9.09444 15.7407 8.99207 15.6648 8.85157 15.6169C8.71508 15.5689 8.53845 15.545 8.32167 15.545C7.93629 15.545 7.65729 15.547 7.48468 15.551V16.911C7.66131 16.915 7.94031 16.917 8.32167 16.917C8.53845 16.917 8.71508 16.8931 8.85157 16.8451C8.99207 16.7932 9.09444 16.7173 9.15867 16.6174C9.22691 16.5176 9.26104 16.3898 9.26104 16.234ZM10.8299 14.9039L12.4678 14.8919C12.8652 14.8919 13.1924 14.9418 13.4493 15.0417C13.7062 15.1415 13.8969 15.2913 14.0214 15.491C14.1498 15.6868 14.2141 15.9304 14.2141 16.222C14.2141 16.5096 14.1458 16.7533 14.0093 16.953C13.8768 17.1527 13.6761 17.3045 13.4072 17.4083C13.1422 17.5122 12.815 17.5641 12.4256 17.5641C12.0644 17.5641 11.7934 17.5621 11.6127 17.5581V18.9901H10.8299V14.9039ZM13.3891 16.234C13.3891 16.0742 13.355 15.9444 13.2867 15.8445C13.2225 15.7407 13.1201 15.6648 12.9796 15.6169C12.8431 15.5689 12.6665 15.545 12.4497 15.545C12.0644 15.545 11.7854 15.547 11.6127 15.551V16.911C11.7894 16.915 12.0684 16.917 12.4497 16.917C12.6665 16.917 12.8431 16.8931 12.9796 16.8451C13.1201 16.7932 13.2225 16.7173 13.2867 16.6174C13.355 16.5176 13.3891 16.3898 13.3891 16.234ZM14.6213 15.5569H15.8738V18.9901H16.6686V15.5569H17.9211V14.9039H14.6213V15.5569Z"
		/>
	</SvgIcon>
);
