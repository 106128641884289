import {
	COMMISSIONING_NAME,
	PRODUCT_IN_OPERATION_NAME,
	UNIT_CREATION_NAME,
} from 'domain/constants';

export enum ADDITIONAL_FILES_ACTIONS {
	// @ts-ignore
	PRODUCT_IN_OPERATION = PRODUCT_IN_OPERATION_NAME,
	// @ts-ignore
	COMMISSIONING = COMMISSIONING_NAME,
	// @ts-ignore
	UNIT_CREATION = UNIT_CREATION_NAME,
}

export enum ADDITIONAL_FILES_API_ACTIONS {
	PRODUCT_IN_OPERATION,
	COMMISSIONING,
	UNIT_CREATION,
}
