import { Skeleton } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useProductCenterApi } from 'api';
import { lengthUnitsQuery, weightUnitsQuery } from 'api/queries';
import { convertWeightUnits } from 'api/responses/models/Unit/UnitMeasurement';
import {
	ControlledFormattedTextField,
	ControlledRadioButtonGroup,
	ControlledSelect,
} from 'library/components/controls';
import {
	DecimalNumberUnitMaskProps,
	MAX_SAFE_FLOAT,
} from 'library/constants/Validations';
import { selectUser } from 'library/redux/application';
import { useAppSelector } from 'library/redux/hooks';
import { DimensionBox } from 'modules/UnitOverview/UnitInformation/Components/DimensionBox';
import { IdentifiersContainer } from 'modules/UnitOverview/UnitInformation/Components/IdentifiersContainer';
import { dimensionTypes } from 'modules/UnitOverview/UnitInformation/Components/dimensionTypes';

import { validateDimensions } from '../Validations';
import { FormSectionProps } from './Types';

export const DimensionsSection = ({ control }: FormSectionProps) => {
	const { t } = useTranslation('unitCreation');
	const measurementLabel = t('measurement-unit');
	const countryCode = useAppSelector(selectUser)?.language;
	const { api } = useProductCenterApi();

	const { data: dataUnitsLength, isSuccess: isSuccessUnitsLength } = useQuery(
		lengthUnitsQuery(api, countryCode)
	);
	const { data: dataUnitsWeight, isSuccess: isSuccessUnitsWeight } = useQuery(
		{
			...weightUnitsQuery(api, countryCode),
			select: convertWeightUnits(t),
		}
	);

	const dimensionTypesMemoized = useMemo(() => dimensionTypes(t), [t]);

	return (
		<IdentifiersContainer
			title={t('unit-information-dimensions-title')}
			subtitle={
				<ControlledRadioButtonGroup
					options={dimensionTypesMemoized}
					row
					name="dimensionsType"
					control={control}
					required
					radioProps={{ size: 'small', color: 'secondary' }}
					sx={{ pl: 1 }}
				/>
			}>
			<DimensionBox>
				<ControlledFormattedTextField
					id="text-field-length"
					name="length"
					label={t('unit-information-length-label')}
					control={control}
					size="small"
					imaskProps={DecimalNumberUnitMaskProps}
					validation={{
						max: {
							value: MAX_SAFE_FLOAT,
							message: 'common:validation-max-number',
						},
					}}
				/>
				{isSuccessUnitsLength ? (
					<ControlledSelect
						id="text-field-length-unit"
						label={measurementLabel}
						size="small"
						options={dataUnitsLength || []}
						control={control}
						name="lengthUnit"
						validation={validateDimensions('length')}
					/>
				) : (
					<Skeleton variant="rounded" height="unset" />
				)}
			</DimensionBox>
			<DimensionBox>
				<ControlledFormattedTextField
					id="text-field-width"
					name="width"
					label={t('unit-information-width-label')}
					control={control}
					size="small"
					imaskProps={DecimalNumberUnitMaskProps}
					validation={{
						max: {
							value: MAX_SAFE_FLOAT,
							message: 'common:validation-max-number',
						},
					}}
				/>
				{isSuccessUnitsLength ? (
					<ControlledSelect
						id="text-field-width-unit"
						label={measurementLabel}
						size="small"
						options={dataUnitsLength || []}
						control={control}
						name="widthUnit"
						validation={validateDimensions('width')}
					/>
				) : (
					<Skeleton variant="rounded" height="unset" />
				)}
			</DimensionBox>
			<DimensionBox>
				<ControlledFormattedTextField
					id="text-field-displacement"
					name="displacement"
					label={t('unit-information-displacement-label')}
					control={control}
					size="small"
					imaskProps={DecimalNumberUnitMaskProps}
					validation={{
						max: {
							value: MAX_SAFE_FLOAT,
							message: 'common:validation-max-number',
						},
					}}
				/>
				{isSuccessUnitsWeight ? (
					<ControlledSelect
						id="text-field-displacement-unit"
						label={measurementLabel}
						size="small"
						options={dataUnitsWeight || []}
						control={control}
						name="displacementUnit"
						validation={validateDimensions('displacement')}
					/>
				) : (
					<Skeleton variant="rounded" height="unset" />
				)}
			</DimensionBox>
		</IdentifiersContainer>
	);
};
