import { FileUpload } from '@mui/icons-material';
import { Button } from '@mui/material';
import { noop } from 'lodash';
import { useTranslation } from 'react-i18next';

import { AreaName } from 'domain/constants';
import { useDialogState } from 'library/hooks';
import { OperationType } from 'library/models/enums/OperationType';
import { createArea } from 'library/utils/helpers/AreaHelper';
import Authorize from 'main/auth/Authorize';

import {
	AdditionalFileUploadProps,
	UploadAdditionalFilesDialog,
} from './UploadAdditionalFilesDialog';

export const AdditionalFileUploadButton = ({
	handler,
	onClose = noop,
}: AdditionalFileUploadProps): JSX.Element => {
	const { t } = useTranslation(['unitOverview', 'fileUpload']);
	const { open, handleOpen, handleClose } = useDialogState();

	const handleDialogClose = (isSubmitting: boolean) => {
		onClose(isSubmitting);
		handleClose();
	};

	return (
		<>
			<Authorize
				area={createArea(
					AreaName.AddCommissioningDocuments,
					OperationType.Create
				)}
				displayIntermediatePages={false}
				redirectWhenNotAuthorized={false}>
				<Button
					startIcon={<FileUpload />}
					color="secondary"
					onClick={handleOpen}>
					{t('fileUpload:upload-files-title')}
				</Button>
			</Authorize>
			{open && (
				<UploadAdditionalFilesDialog
					handler={handler}
					onClose={handleDialogClose}
				/>
			)}
		</>
	);
};
