import { styled } from '@mui/material/styles';
import { TreeItem } from '@mui/x-tree-view';

export const TreeNode = styled(TreeItem)(({ theme }) => ({
	'.MuiTreeItem-content': {
		marginBottom: 5,
		padding: 0,

		'&:hover, &.Mui-selected, &.Mui-selected:hover, &.Mui-focused.Mui-selected':
			{
				backgroundColor: theme.palette.background.blue,
			},
		'&.Mui-focused': {
			backgroundColor: 'transparent',
		},
	},
	a: {
		textUnderlineOffset: '4px',
	},
}));

export const treeNodeLinkSx = {
	'&:hover': {
		textDecoration: 'underline',
	},
} as const;
