import { AnyAction, ThunkDispatch, createAsyncThunk } from '@reduxjs/toolkit';

import { productCenterApi } from 'api';
import { isVppn } from 'main/auth/provider';
import { RootState } from 'store';

import { setMenu } from './Slice';

const getMenu = async (
	dispatch: ThunkDispatch<unknown, unknown, AnyAction>
) => {
	const response = await productCenterApi.getMenu({
		isExternal: isVppn(),
	});
	if (response?.data) {
		dispatch(setMenu(response.data));
	}
};

export const updateLanguage = createAsyncThunk(
	'application/updateLanguage',
	async (language: string, { dispatch }) => {
		await productCenterApi.setUserLanguage({ language });
		await getMenu(dispatch);

		return language;
	}
);

export const getUserData = createAsyncThunk(
	'application/getUserData',
	async (_, { getState, dispatch }) => {
		const state = getState() as RootState;

		if (state.application.loadingUserDataIsProgressing === 1) {
			const userRequest = productCenterApi.getUser();
			const menuRequest = getMenu(dispatch);

			const [userResponse] = await Promise.all([
				userRequest,
				menuRequest,
			]);
			const user = userResponse.data;

			return {
				user,
			};
		}

		return null;
	}
);

export const getDeployAlertNotification = createAsyncThunk(
	'application/getDeployAlertNotification',
	async () => {
		const response = await productCenterApi.getNotificationText();

		if (response?.data) {
			return response.data;
		}

		return null;
	}
);

export const updateDeployAlertNotification = createAsyncThunk(
	'application/updateDeployAlertNotification',
	async (text: string) => {
		let response = await productCenterApi.updateNotificationText({ text });

		if (response?.status === 204) {
			response = await productCenterApi.addNotificationText({ text });
			if (response?.data) {
				return response.data;
			}
		}

		if (response?.data) return response.data;

		return null;
	}
);

export const deleteDeployAlertNotification = createAsyncThunk(
	'application/deleteDeployAlertNotification',
	async () => {
		const response = await productCenterApi.removeNotificationText();
		if (response?.status === 200) {
			return '';
		}

		return null;
	}
);
