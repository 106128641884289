import { AxiosError } from 'axios';
import { endsWith } from 'lodash';

import { replace } from 'api/axios/helpers/url';
import { BASE_URL } from 'main/routes';
import {
	errors_AuthenticationUrl,
	errors_AuthorizationUrl,
} from 'main/routes/urls/RouteUrls';

export const authErrorResponseInterceptor = (
	error: AxiosError
): Promise<AxiosError> => {
	if (error.response?.status === 403) {
		redirectToErrorPage(errors_AuthorizationUrl);
	} else if (error.response?.status === 401) {
		redirectToErrorPage(errors_AuthenticationUrl);
	}

	return Promise.reject(error);
};

export const redirectToErrorPage = (errorPageUrl: string) => {
	if (!endsWith(window.location.href, errorPageUrl)) {
		// replace is done to remove all double slashes except for protocol - https://
		window.location.href = replace(
			`${BASE_URL}${errorPageUrl}`,
			/\/\//g,
			'/',
			1
		);
	}
};
