/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosRequestHeaders, InternalAxiosRequestConfig } from 'axios';

import { suburl_vpp_refresh_token } from 'api/ApiUrls';
import { baseConfig } from 'api/axios/config';
import { getAuthorizationHeader } from 'api/axios/headers/BaseHeader';
import { AuthProviderStorage } from 'main/auth/provider/Storage';

const AUTH_REFRESH_INTERVAL = 500;
const { timeout: AUTH_REFRESH_TIMEOUT = 10000 } = baseConfig; // default is 10 seconds

// function checks if authentication refresh process is ongoing and stop executing request until refresh's completion or timeout
// in case of a new token it's being attached to request
export const authRequestInterceptor = async ({
	headers,
	url,
	...rest
}: InternalAxiosRequestConfig): Promise<InternalAxiosRequestConfig<any>> => {
	const provider = AuthProviderStorage.getInstance().getProvider();
	const accessToken = await provider.getTokenAsync();

	if (!accessToken) {
		throw new Error('Access token is empty.');
	}

	headers.Authorization = `Bearer ${accessToken}`;

	const token = await new Promise<null | string>((resolve) => {
		// if token isn't refreshing or it's a refresh request, then proceed the request
		if (
			!provider.isRefreshing() ||
			url?.includes(suburl_vpp_refresh_token)
		) {
			resolve(null);
			return;
		}

		let intervalCounter = 0;
		// interval checks in a loop if token's refresh has completed. If timeout has elapsed, then just break the checking loop and allow to make the request having invalid token
		const intervalId = setInterval(async () => {
			const isRefreshing = provider.isRefreshing();

			if (
				!isRefreshing ||
				intervalCounter * AUTH_REFRESH_INTERVAL > AUTH_REFRESH_TIMEOUT
			) {
				if (isRefreshing) {
					console.error(
						`JWT token refreshing hasn't finished in ${
							AUTH_REFRESH_TIMEOUT / 1000
						}s`
					);
				}

				clearInterval(intervalId);
				resolve(await provider.getTokenAsync());
				return;
			}

			intervalCounter++;
		}, AUTH_REFRESH_INTERVAL);
	});

	// if there is a new token it need to be replaced
	return {
		url,
		headers: token
			? ({
					...getAuthorizationHeader(token),
					...headers,
				} as unknown as AxiosRequestHeaders)
			: headers,
		...rest,
	};
};
