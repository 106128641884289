import { InteractionType } from '@azure/msal-browser';
import {
	MsalAuthenticationTemplate,
	useAccount,
	useMsal,
} from '@azure/msal-react';
import { PropsWithChildren, useEffect } from 'react';

import { setADUser } from 'library/redux/application';
import AuthenticationLoader from 'main/auth/AuthenticationLoader';
import ErrorUnauthenticatedPage from 'pages/errors/ErrorUnauthenticatedPage';

import { redirectRequest } from './config';

export const Authenticate = ({
	children,
	onMsalChanged,
}: PropsWithChildren<{
	onMsalChanged: (isInitialized: boolean) => void;
}>): JSX.Element => {
	const { instance, accounts, inProgress } = useMsal();
	const account = useAccount(accounts[0] || {});

	useEffect(() => {
		if (account && inProgress === 'none') {
			instance.setActiveAccount(account);
			setADUser(account);
			onMsalChanged(!!account);
		}
	}, [account, instance, onMsalChanged, inProgress]);

	return (
		<MsalAuthenticationTemplate
			interactionType={InteractionType.Redirect}
			authenticationRequest={redirectRequest}
			errorComponent={ErrorUnauthenticatedPage}
			loadingComponent={AuthenticationLoader}>
			{children}
		</MsalAuthenticationTemplate>
	);
};
