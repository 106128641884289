import { memo } from 'react';

import { Spinner } from '../controls';

interface LoaderProps {
	translatedText: string;
}

export const Loader = ({ translatedText }: LoaderProps) => {
	return (
		<div className="screen__spinner">
			<div
				className="spinner-border screen__spinner__component"
				role="status">
				<Spinner />
			</div>
			<div className="screen__spinner__text">
				<span className="screen__spinner__text__message">
					{translatedText}
				</span>
			</div>
		</div>
	);
};

export default memo(Loader);
