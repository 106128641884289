import { COMMISSIONING_NAME } from 'domain/constants';
import {
	createFileUploadSlice,
	initialFileState,
} from 'library/redux/fileUpload';

import { resetState } from './Thunk';

const commissioningFilesSlice = createFileUploadSlice({
	name: COMMISSIONING_NAME,
	initialState: initialFileState,
	reducers: {},
	extraReducers(builder) {
		builder.addCase(resetState.fulfilled, () => initialFileState);
	},
});

export const { fileUploaded, fileDeleted, attachmentFolderCreated } =
	commissioningFilesSlice.actions;

export default commissioningFilesSlice.reducer;
