import { AccountInfo } from '@azure/msal-browser';
import { Draft, PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Location } from 'react-router';

import { MenuDto } from 'api';
import { mapToADUser } from 'domain/user/User';
import { ADUser } from 'library/models/ADUser';
import { Alert, StaticNotification } from 'library/models/Alert';
import { User } from 'library/models/User';
import { AlertType } from 'library/models/enums/AlertType';

import {
	deleteDeployAlertNotification,
	getDeployAlertNotification,
	getUserData,
	updateDeployAlertNotification,
	updateLanguage,
} from './Thunks';
import { ApplicationState } from './Types';

const initialState: ApplicationState = {
	user: {
		language: 'en-US',
	} as User,
	adUser: {} as ADUser,
	menu: [],
	loader: {
		isVisible: false,
		message: null,
	},
	alert: {
		isVisible: false,
		message: null,
		type: AlertType.Info,
		customHeader: undefined,
		shouldBeCloseManually: undefined,
		visibilityTime: 3000,
	},
	history: {
		previous: undefined,
		current: undefined,
	},
	staticNotification: null,
	deployAlertNotification: '',
	loadingUserDataIsProgressing: 0,
};

export const applicationSlice = createSlice({
	name: 'application',
	initialState,
	reducers: {
		setMenu: (state, { payload }: PayloadAction<Array<MenuDto>>) => {
			state.menu = payload;
		},
		setADUser: (state, { payload }: PayloadAction<AccountInfo | null>) => {
			const adUser = mapToADUser(payload);
			state.adUser = adUser;
		},
		toggleLoader: (
			state,
			{ payload }: PayloadAction<string | null | undefined>
		) => {
			if (payload) {
				showLoader(state, payload);
			} else {
				hideLoader(state);
			}
		},
		showStaticNotification: (
			state,
			{ payload }: PayloadAction<StaticNotification>
		) => {
			if (payload) {
				state.staticNotification = payload;
			}
		},
		hideStaticNotification: (state) => {
			state.staticNotification = null;
		},
		setAlert: (state, { payload }: PayloadAction<Alert>) => {
			Object.assign(state.alert, {
				isVisible: payload.isVisible,
				type: payload.type,
				message: payload.message,
				customHeader: payload.customHeader,
				shouldBeCloseManually: payload.shouldBeCloseManually,
				visibilityTime: payload.visibilityTime ?? 3000,
			});
		},
		setHistory: (state, { payload }: PayloadAction<Location>) => {
			const previous = state.history.current;

			// save in case of different path and ignore search params
			if (payload.pathname !== previous?.pathname) {
				state.history.previous = previous;
				state.history.current = payload;
			}
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getUserData.pending, (state) => {
			state.loadingUserDataIsProgressing += 1;
			showLoadingLoader(state);
		});
		builder.addCase(getUserData.fulfilled, (state, { payload }) => {
			state.loadingUserDataIsProgressing -= 1;

			const user = payload?.user;

			if (user) {
				if (user.language == null) {
					user.language = 'en-US';
				}

				state.user = user;
			}

			hideLoader(state);
		});
		builder.addCase(getUserData.rejected, (state) => {
			hideLoader(state);
		});
		builder.addCase(updateLanguage.pending, (state) => {
			showLoadingLoader(state);
		});
		builder.addCase(updateLanguage.fulfilled, (state, { payload }) => {
			state.user.language = payload as string;
			hideLoader(state);
		});
		builder.addCase(updateLanguage.rejected, (state) => {
			hideLoader(state);
		});
		builder.addCase(
			getDeployAlertNotification.fulfilled,
			updateNotification
		);
		builder.addCase(
			updateDeployAlertNotification.fulfilled,
			updateNotification
		);
		builder.addCase(deleteDeployAlertNotification.fulfilled, (state) => {
			state.deployAlertNotification = '';
		});
	},
});

export const {
	setMenu,
	setADUser,
	toggleLoader,
	showStaticNotification,
	hideStaticNotification,
	setAlert,
	setHistory,
} = applicationSlice.actions;

export default applicationSlice.reducer;

const showLoadingLoader = (state: Draft<ApplicationState>) => {
	return showLoader(state, 'Loading...');
};

const showLoader = (state: Draft<ApplicationState>, message: string) => {
	state.loader = {
		isVisible: true,
		message,
	};
};

const hideLoader = (state: Draft<ApplicationState>) => {
	state.loader = {
		isVisible: false,
		message: null,
	};
};

const updateNotification = (
	state: Draft<ApplicationState>,
	action: PayloadAction<string | null>
) => {
	if (action.payload) {
		state.deployAlertNotification = action.payload;
	}
};
