import {
	createFileUploadSlice,
	initialFileState,
} from 'library/redux/fileUpload';

import { resetState } from './Thunk';

const productInOperationFilesSlice = createFileUploadSlice({
	name: 'productInOperationFiles',
	initialState: initialFileState,
	reducers: {},
	extraReducers(builder) {
		builder.addCase(resetState.fulfilled, () => initialFileState);
	},
});

export const { fileUploaded, fileDeleted, attachmentFolderCreated } =
	productInOperationFilesSlice.actions;

export default productInOperationFilesSlice.reducer;
