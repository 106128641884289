import { createEntityAdapter } from '@reduxjs/toolkit';

import { ProductIndividualSearchResult } from 'domain/productIndividual';

import { Installation, UnitState } from './Types';

export const installationList = createEntityAdapter<Installation, string>({
	selectId: ({ generatedUuid }) => generatedUuid,
});

export const installationListSelectors =
	installationList.getSelectors<UnitState>(
		({ installationList }) => installationList
	);

export const searchItemsList = createEntityAdapter<
	ProductIndividualSearchResult,
	string
>({
	selectId: ({ generatedUuid }) => generatedUuid,
});

export const searchItemsListSelectors = searchItemsList.getSelectors<UnitState>(
	({ searchItemsList }) => searchItemsList
);

export const searchItemWithConfigurationDetected = createEntityAdapter<
	ProductIndividualSearchResult,
	string
>({
	selectId: (entity) => entity.generatedUuid,
});
