import {
	CreateOrUpdateConsumer,
	EndConsumerDto,
	EndConsumersSearchResponse,
} from 'api';

import { Customer } from '../State';

const isLegacyCustomerDisabled =
	import.meta.env.VITE_LEGACY_END_CUSTOMER === 'false';

export function mapEndCustomerFormDataToRequestData(
	values: Customer
): CreateOrUpdateConsumer {
	const isCompany = values.consumerType === 'Company';
	if (!values.isConsumerAgreeForPentaStorageOfUserInformation) {
		return {
			id: values.id,
			consumerType: values.consumerType,
			countryCode: values.countryCode ?? '',
			city: values.city ?? '',
			zipCode: values.zipCode ?? '',
			isConsumerAgreeForPentaStorageOfUserInformation:
				values.isConsumerAgreeForPentaStorageOfUserInformation,
			notificationLanguage: values.notificationLanguage ?? '',
		};
	}

	return {
		...values,
		id: values.id,
		countryCode: values.countryCode ?? '',
		city: values.city ?? '',
		zipCode: values.zipCode ?? '',
		notificationLanguage: values.notificationLanguage ?? '',
		companyName:
			isLegacyCustomerDisabled || isCompany ? values.companyName : null,
		vatNumber:
			isLegacyCustomerDisabled || isCompany ? values.vatNumber : null,
	};
}

export function getNewSearchResults(
	searchData: EndConsumersSearchResponse | null,
	updatedCustomer: EndConsumerDto,
	wasDeleted: boolean
): EndConsumersSearchResponse | null {
	if (!searchData?.results) {
		return searchData;
	}

	let newData = searchData.results;

	if (wasDeleted) {
		newData = newData.filter(
			(customer) => customer.id !== updatedCustomer.id
		);
	} else {
		newData = newData.map((item) =>
			item.id === updatedCustomer.id ? updatedCustomer : item
		);
	}

	return {
		results: newData,
		resultMoreThan10: searchData.resultMoreThan10,
	};
}
