import { AuthenticationProvider } from 'main/auth/provider';

export const createDownloadFileFormOnClick = (
	token: string,
	url = '',
	name = ''
) => {
	const form = document.createElement('form');
	document.body.appendChild(form);
	form.setAttribute(
		'action',
		`${url}${url.includes('?') ? '&' : '?'}AuthType=FileDownload`
	);
	form.setAttribute('type', 'hidden');
	form.setAttribute('target', '_blank');
	form.setAttribute('method', 'post');
	form.setAttribute('name', name);
	const body = document.createElement('input');
	body.setAttribute('type', 'hidden');
	body.setAttribute('name', 'token');
	body.setAttribute('value', token);
	form.appendChild(body);

	form.submit();
	form.removeChild(body);
	document.body.removeChild(form);
};

export const isBlob = (url: string) => url.includes('blob');

export const onDownloadLinkClick =
	(
		provider: AuthenticationProvider,
		url: string | undefined,
		trimmedFilename: string
	) =>
	async (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
		if (!isBlob(url || '')) {
			event.preventDefault();
			createDownloadFileFormOnClick(
				await provider.getTokenAsync(),
				url,
				trimmedFilename
			);
		}
	};
