import { CalendarToday, Comment } from '@mui/icons-material';
import { Box, Stack, Typography } from '@mui/material';

import { FileSetDto } from 'api';
import { getUrlFileSetDownload } from 'api/ApiUrls';
import UserIcon from 'assets/volvoIcons/People/UserIcon';
import { FileDisplayBase, getFileType } from 'library/components/controls';
import { getLocalizedDateFormat } from 'library/formatters';

export const FileSetDisplay = ({
	id,
	comment,
	submittedBy,
	timestamp,
	files,
}: FileSetDto) => {
	return (
		<Box
			className="server-file-display_root"
			display="grid"
			alignItems="center"
			gap={2}
			gridTemplateAreas={{
				xs: `"files files"
					"comment comment"
					"date user"`,
				md: `"files comment date user"`,
			}}
			gridTemplateColumns={{
				xs: '1fr 1fr',
				md: '6fr 7fr 2fr 3fr',
			}}
			sx={{
				'.server-file-display_comment-icon, .server-file-display_calendar-icon, .server-file-display_user-icon':
					{
						verticalAlign: 'bottom',
						mr: 1,
					},
			}}>
			<Stack gridArea="files" direction="column" spacing={2}>
				{files.map((file) => (
					<FileDisplayBase
						key={file.id}
						name={file.fileName}
						url={getUrlFileSetDownload(id, file.id)}
						type={getFileType(file.fileName)}
					/>
				))}
			</Stack>
			<Typography gridArea="comment" whiteSpace="pre-wrap">
				<Comment className="server-file-display_comment-icon" />
				{comment}
			</Typography>
			<Typography gridArea="date">
				<CalendarToday className="server-file-display_calendar-icon" />
				{getLocalizedDateFormat(timestamp)}
			</Typography>
			<Typography gridArea="user">
				<UserIcon className="server-file-display_user-icon" />
				{submittedBy}
			</Typography>
		</Box>
	);
};
