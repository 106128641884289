import { ButtonBase, SvgIcon } from '@mui/material';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useProductCenterApi } from 'api';
import { getSupportedLanguagesQuery, productKeys } from 'api/queries';
import IconLanguage from 'assets/images/main/icon_language.svg?react';
import { ParseKeys } from 'i18next';
import { ChangeLanguageDialog } from 'library/components/controls';
import { getChangeLanguageLink } from 'library/components/link';
import { useDialogState } from 'library/hooks';
import { selectUser, updateLanguage } from 'library/redux/application';
import { useAppDispatch, useAppSelector } from 'library/redux/hooks';

const ChangeLanguageButton = () => {
	const { language: userLanguage } = useAppSelector(selectUser);
	const dispatch = useAppDispatch();
	const { open, handleOpen, handleClose } = useDialogState();
	const { t } = useTranslation(['language']);
	const queryClient = useQueryClient();

	const { api } = useProductCenterApi();
	const { data, isLoading } = useQuery(getSupportedLanguagesQuery(api));

	const closeDialogAndChangeLanguage = async (language: string) => {
		handleClose();
		await dispatch(updateLanguage(language)).unwrap();

		// Set the lang attribute of the <html> element directly after updating the language
		document.documentElement.lang = language;

		invalidateTranslatableQueries();
		window
			.open(
				getChangeLanguageLink(),
				'_blank',
				'width= 640, height= 480, left=0, top=0, resizable=yes, toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=no, copyhistory=no'
			)
			?.blur();
		window.focus();
	};

	const invalidateTranslatableQueries = () => {
		queryClient.invalidateQueries({
			queryKey: productKeys.detailedSegmentsAll(),
		});
		queryClient.invalidateQueries({
			queryKey: productKeys.lineOfBusinessAll(),
		});
		queryClient.invalidateQueries({
			queryKey: productKeys.unitPurposes(),
		});
		queryClient.invalidateQueries({
			queryKey: productKeys.productCategoriesAll(),
		});
	};

	const currentLanguageTranslation = useMemo(
		() =>
			t(
				`language:changeLanguage-${userLanguage.toLocaleLowerCase()}` as ParseKeys<
					['language']
				>
			),
		[t, userLanguage]
	);

	return (
		<>
			{!isLoading && (
				<>
					<ButtonBase
						className="language-selector"
						onClick={handleOpen}>
						{`${t(
							'language:currentLanguage'
						)} - ${currentLanguageTranslation}`}
						<SvgIcon component={IconLanguage} />
					</ButtonBase>
					<ChangeLanguageDialog
						isOpen={open}
						onChangeLanguage={closeDialogAndChangeLanguage}
						onClose={handleClose}
						defaultValue={userLanguage}
						supportedLanguages={data ?? {}}
					/>
				</>
			)}
		</>
	);
};

export default ChangeLanguageButton;
