import { useQuery } from '@tanstack/react-query';
import { PropsWithChildren } from 'react';
import { Navigate } from 'react-router';

import { useProductCenterApi } from 'api';
import { securityMatrixQuery } from 'api/queries/ApplicationQueries';
import { Area } from 'library/models/Area';
import AuthenticationLoader from 'main/auth/AuthenticationLoader';
import { errors_AuthorizationUrl } from 'main/routes/urls/RouteUrls';
import ErrorUnauthenticatedPage from 'pages/errors/ErrorUnauthenticatedPage';

import { hasUserAccess } from './Authorization';

type AuthorizationProps = {
	area: Area;
	displayIntermediatePages?: boolean;
	redirectWhenNotAuthorized?: boolean;
};

const Authorize = ({
	area,
	children,
	displayIntermediatePages = true,
	redirectWhenNotAuthorized = true,
}: PropsWithChildren<AuthorizationProps>): JSX.Element | null => {
	const { api } = useProductCenterApi();
	const query = useQuery(securityMatrixQuery(api));

	if (query.isLoading) {
		return displayIntermediatePages ? <AuthenticationLoader /> : null;
	}

	if (query.isError || query.data === undefined) {
		return displayIntermediatePages ? <ErrorUnauthenticatedPage /> : null;
	}

	const availableAreas = query.data;

	if (!hasUserAccess(area, availableAreas)) {
		return redirectWhenNotAuthorized ? (
			<Navigate to={errors_AuthorizationUrl} replace />
		) : null;
	} else {
		return <>{children}</>;
	}
};

export default Authorize;
