import { Box, Paper, Typography } from '@mui/material';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import InformationPlateImg from 'assets/images/wizard/information_plate.svg';
import { Sxable } from 'library/types';

interface InformationPlateProps extends Sxable {
	id?: string;
}

export const InformationPlate = memo(
	({ id, sx = [] }: InformationPlateProps): JSX.Element => {
		const { t } = useTranslation('componentInformationPlate');

		return (
			<Paper
				id={id}
				className="information-plate__root"
				elevation={0}
				sx={[
					{
						display: 'flex',
						flexDirection: 'column',
						boxSizing: 'border-box',
						gap: 1,
						p: 2.5,
						backgroundColor: 'background.default',
					},
					...(Array.isArray(sx) ? sx : [sx]),
				]}>
				<Typography
					className="information-plate__title"
					variant="body2">
					{t('title')}
				</Typography>
				<Box
					className="information-plate__image"
					sx={(theme) => ({
						backgroundImage: `url(${InformationPlateImg})`,
						backgroundRepeat: 'no-repeat',
						backgroundSize: 'contain',
						opacity: 0.5,
						height: theme.spacing(12),
						flex: '1 0 auto',
					})}></Box>
			</Paper>
		);
	}
);
