import { AxiosRequestConfig } from 'axios';

import { IRequest } from '../requests/BaseRequest';
import { IDeleteRequest } from '../requests/DeleteRequest';
import { IGetRequest } from '../requests/GetRequest';
import { IHeadRequest } from '../requests/HeadRequest';
import { IPostRequest } from '../requests/PostRequest';
import { IPutRequest } from '../requests/PutRequest';
import { IUploadRequest } from '../requests/UploadRequest';
import { mapHeader } from './HeaderMapper';

const mapRequestToConfig = (request: IRequest): AxiosRequestConfig => {
	return {
		url: request.url,
		responseType: request.responseType,
		headers: request.headers ? mapHeader(request.headers) : undefined,
		method: request.method,
	} as AxiosRequestConfig;
};

export const mapGetRequestToConfig = (
	getRequest: IGetRequest
): AxiosRequestConfig => {
	return {
		...mapRequestToConfig(getRequest),
		params: getRequest.params,
		paramsSerializer: getRequest.paramsSerializer,
	} as AxiosRequestConfig;
};

export const mapHeadRequestToConfig = (
	headRequest: IHeadRequest
): AxiosRequestConfig => {
	return {
		...mapRequestToConfig(headRequest),
		params: headRequest.params,
		paramsSerializer: headRequest.paramsSerializer,
	} as AxiosRequestConfig;
};

export const mapPostRequestToConfig = (
	postRequest: IPostRequest
): AxiosRequestConfig => {
	return {
		...mapRequestToConfig(postRequest),
		params: postRequest.params,
		data: postRequest.data,
	} as AxiosRequestConfig;
};

export const mapPutRequestToConfig = (
	putRequest: IPutRequest
): AxiosRequestConfig => {
	return {
		...mapRequestToConfig(putRequest),
		params: putRequest.params,
		data: putRequest.data,
	} as AxiosRequestConfig;
};

export const mapDeleteRequestToConfig = (
	deleteRequest: IDeleteRequest
): AxiosRequestConfig => {
	return {
		...mapRequestToConfig(deleteRequest),
		params: deleteRequest.params,
	} as AxiosRequestConfig;
};

export const mapDownloadRequestToConfig = (
	downloadRequest: IHeadRequest
): AxiosRequestConfig => {
	return {
		...mapRequestToConfig(downloadRequest),
		params: downloadRequest.params,
	} as AxiosRequestConfig;
};

export const mapUploadRequestToConfig = (
	uploadRequest: IUploadRequest
): AxiosRequestConfig => {
	return {
		...mapRequestToConfig(uploadRequest),
		params: uploadRequest.params,
		data: uploadRequest.data,
	} as AxiosRequestConfig;
};
