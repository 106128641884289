import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { styled } from '@mui/material';
import { PropsWithChildren } from 'react';

import { useLayoutStyle } from 'library/hooks/useLayoutStyle';

import { RedirectCounter } from './RedirectCounter';

interface ErrorContentProps extends PropsWithChildren {
	title: React.ReactNode;
	subtitle: React.ReactNode;
	description?: React.ReactNode;
}

const StyledContainer = styled('div')({
	alignItems: 'center',
	display: 'flex',
	justifyContent: 'center',
	textAlign: 'center',
});

export const ErrorContent = ({
	children,
	title,
	subtitle,
	description,
}: ErrorContentProps): JSX.Element => {
	const { minHeight: height } = useLayoutStyle();

	return (
		<StyledContainer sx={{ height }}>
			<div className="alert alert-danger" role="alert">
				<ReportProblemIcon fontSize="large" />
				<h4 className="alert-heading">{title}</h4>
				<p>{subtitle}</p>
				{description && (
					<>
						<hr />
						<p className="mb-0">{description}</p>
					</>
				)}
				{children}
				<RedirectCounter />
			</div>
		</StyledContainer>
	);
};
