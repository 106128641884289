import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import { SubmitErrorResponse } from 'library/exceptions/ValidationErrors';

export interface UseValidationErrorsSnackbarReturnType {
	enqueueValidationSnackbar: (validationError: Error) => void;
}

export function useValidationErrorsSnackbar(): UseValidationErrorsSnackbarReturnType {
	const { enqueueSnackbar } = useSnackbar();
	const { t } = useTranslation(['common']);

	const enqueueValidationSnackbar = (error: Error) => {
		let message = t('common:validation-error-message');
		let validationError = error as unknown as SubmitErrorResponse;

		if (error instanceof AxiosError || error.name === 'AxiosError') {
			const ae = error as AxiosError;

			validationError = {
				errorCode: ae.response?.status,
				errorMessage: ae.message,
				data: ae.response?.data,
			} as SubmitErrorResponse;
		}

		if (
			validationError?.errorCode === 400 ||
			validationError?.errorCode === 422
		) {
			if (validationError.data?.invalidItems) {
				message = validationError.data?.invalidItems.reduce(
					(acc, curr) => acc + curr.errorMessage + '\n',
					''
				);
			} else if (validationError.data?.errors) {
				let msg = '';

				Object.entries(validationError.data?.errors).forEach(
					([key, value]) => (msg = msg + `${key}: ${value}\n`)
				);

				message = msg;
			} else if (validationError.data?.detailedMessage) {
				message = validationError.data?.detailedMessage;
			}
		} else if (validationError?.errorCode === 500) {
			message = t('common:error-unexpected');
		} else if (validationError?.axiosCode === 'ERR_NETWORK') {
			message = validationError.errorMessage;
		}

		enqueueSnackbar(message, {
			variant: 'error',
			style: { whiteSpace: 'pre-line' },
			autoHideDuration: 30000,
			preventDuplicate: true,
		});
	};

	return {
		enqueueValidationSnackbar,
	};
}
