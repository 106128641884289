import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { OperatingLocationFormType } from 'modules/Shared/Components/OperatingLocation/OperatingLocationForm';

import { AddCustomerState, Customer, SourceType } from './Types';

const initialState: AddCustomerState = {
	unitId: 0,
	owner: null,
	ownerSourceType: null,
	unitManagerSourceType: null,
	operatingLocation: null,
};

export const addEndCustomerSlice = createSlice({
	name: 'addEndCustomer',
	initialState,
	reducers: {
		initializeState: (state, { payload }: PayloadAction<number>) => {
			if (!isNaN(payload) && payload >= 0) {
				// reset state
				return { ...initialState, unitId: payload };
			}

			return state;
		},
		setUnitId: (state, { payload }: PayloadAction<number>) => {
			if (!isNaN(payload) && payload >= 0) {
				state.unitId = payload;
			}
		},
		ownerChanged: (state, { payload }: PayloadAction<Customer>) => {
			state.owner = payload;
		},
		ownerSourceChanged: (state, { payload }: PayloadAction<SourceType>) => {
			state.ownerSourceType = payload;
		},
		unitManagerChanged: (state, { payload }: PayloadAction<Customer>) => {
			state.unitManager = payload;
		},
		unitManagerSourceChanged: (
			state,
			{ payload }: PayloadAction<SourceType>
		) => {
			state.unitManagerSourceType = payload;
		},
		clearCustomerData: (state, { payload }: PayloadAction<boolean>) => {
			if (payload) {
				state.owner = null;
			} else {
				state.unitManager = undefined;
			}
		},
		operatingLocationChanged: (
			state,
			{ payload }: PayloadAction<OperatingLocationFormType>
		) => {
			state.operatingLocation = payload;
		},
	},
});

export const {
	initializeState,
	setUnitId,
	ownerChanged,
	ownerSourceChanged,
	unitManagerChanged,
	unitManagerSourceChanged,
	clearCustomerData,
	operatingLocationChanged,
} = addEndCustomerSlice.actions;

export default addEndCustomerSlice.reducer;
