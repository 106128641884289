import { ProductIndividualStatus } from '../unit/enums';

export const WARRANTY_STARTED_STATUS_LIST: (ProductIndividualStatus | null)[] =
	['Commissioned', 'ProductInOperation'];

export const CONSUMER_REGISTER_ALLOWED_STATUS_LIST: (ProductIndividualStatus | null)[] =
	['RegisteredUnit', 'DemoUseRegistered'];

export const DEMO_REGISTER_ALLOWED_STATUS_LIST: (ProductIndividualStatus | null)[] =
	['RegisteredUnit'];

export const PRODUCT_IN_OPERATION_ALLOWED_STATUS_LIST: (ProductIndividualStatus | null)[] =
	['ConsumerRegistered'];

export const END_CUSTOMER_AVAILABLE_STATUS_LIST: (ProductIndividualStatus | null)[] =
	['ConsumerRegistered', 'ProductInOperation', 'Commissioned'];

export const PRODUCT_IN_OPERATION_AVAILABLE_STATUS_LIST: (ProductIndividualStatus | null)[] =
	['ProductInOperation', 'Commissioned'];

export const COMMISSIONING_ALLOWED_STATUS_LIST: (ProductIndividualStatus | null)[] =
	['ProductInOperation'];

export const COMMISSIONING_AVAILABLE_STATUS_LIST: (ProductIndividualStatus | null)[] =
	['Commissioned'];

export const RELOCATE_AVAILABLE_STATUS_LIST: (ProductIndividualStatus | null)[] =
	['RegisteredUnit', 'ConsumerRegistered', 'DemoUseRegistered'];

export const SCRAP_STATUS_LIST: (ProductIndividualStatus | null)[] = [
	'Invoiced',
	'InternallyInvoiced',
	'Manufactured',
	'Replaced',
];

export const RESET_STATUS_LIST: (ProductIndividualStatus | null)[] = [
	'Scrapped',
	'Replaced',
];
