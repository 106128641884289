import { CreateOrUpdateCommissioningCommand, productCenterApi } from 'api';
import { COMMISSIONING_NAME } from 'domain/constants';
import { createWizardSubmitThunk } from 'library/hooks/Wizard/WizardSubmitHelpers';
import { mapFileUploadStateToAttachmentFileSet } from 'library/redux/fileUpload';
import { createResetStateThunk } from 'library/redux/fileUpload/Thunks';

import { selectCommissioningFiles, selectUnitId } from './Selectors';

export const resetState = createResetStateThunk(
	COMMISSIONING_NAME + '/resetState',
	(s) => s.commissioningFileState
);

export const submitCommissioning = createWizardSubmitThunk(
	`${COMMISSIONING_NAME}/submitCommissioning`,
	(root) => {
		if (!root.commissioning.commissioningFormData) {
			throw new Error(
				'Should be called only, when commissioning form was filled.'
			);
		}
		if (!root.commissioning.operatingLocation) {
			throw new Error('Operating location is required.');
		}

		const commissioningFiles = selectCommissioningFiles(root);

		const unitId = selectUnitId(root);
		const request: CreateOrUpdateCommissioningCommand = {
			commissioningDate: new Date(
				root.commissioning.commissioningFormData?.commissioningDate
			),
			additionalComment:
				root.commissioning.commissioningFormData?.additionalComment,
			isInformationSharedWithCustomer:
				root.commissioning.commissioningFormData
					?.isInformationSharedWithCustomer,
			wasSeaTrialPerformed:
				root.commissioning.commissioningFormData?.wasSeaTrialPerformed,
			distance: root.commissioning.travelExpenses?.distance,
			distanceUnit: root.commissioning.travelExpenses?.distanceUnit,
			from: root.commissioning.travelExpenses?.from,
			hours: root.commissioning.travelExpenses?.hours,
			to: root.commissioning.travelExpenses?.to,
			travelDescription:
				root.commissioning.travelExpenses?.travelDescription,
			operatingLocation: root.commissioning.operatingLocation,
			attachmentInfo:
				mapFileUploadStateToAttachmentFileSet(commissioningFiles),
		};

		return productCenterApi.performCommissioning(unitId, request);
	}
);
