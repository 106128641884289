import { Kit } from 'api';
import { generateUUID } from 'library/utils/generateUuid';

export interface KitDto extends Kit {
	id: string;
}

export const mapToModelFromDto = (dto: Kit): KitDto => ({
	...dto,
	id: generateUUID(),
});
