import { UnitOtherPartDto } from 'api';

export const isValid = (
	otherParts: UnitOtherPartDto[],
	partNumber: string,
	serialNumber?: string
) => {
	const items = otherParts.filter((item) => item.partNumber === partNumber);

	// There are 3 cases when an other part is INVALID when it exists on the list and user tries to add the same part number:
	//  - WITHOUT serial number
	const withoutSerialNumber = items.length > 0 && !serialNumber;
	//  - WITH existing serial number
	const withExistingSerialNumber = items.some(
		(item) => item.serialNumber === serialNumber
	);
	//  - WITH different serial number and the part has been delivered > 0 and installed > 0
	const withDifferentSerialNumber = items.some(
		(item) =>
			!item.serialNumber &&
			item.delivered != null &&
			item.delivered > 0 &&
			item.installed != null &&
			item.installed > 0
	);
	return !(
		withoutSerialNumber ||
		withExistingSerialNumber ||
		withDifferentSerialNumber
	);
};
