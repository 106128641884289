import { UnitEditCommand } from 'domain/unit/EditCommand/UnitEditCommand';
import { UnitRegisterCommand } from 'domain/unit/RegisterCommand/UnitRegisterCommand';

import {
	url_unit_configuration,
	url_unit_get_unit_register,
	url_unit_get_unit_structure,
	url_unit_info,
} from './ApiUrls';
import { ApiClient } from './axios/ApiClient';
import { IGetRequest } from './axios/requests/GetRequest';
import { IPostRequest } from './axios/requests/PostRequest';
import { IBaseResponse } from './axios/responses/BaseResponse';
import { Unit } from './responses/models/Unit/UnitStructure/Unit';
import { UpdateUnitInfoRequest } from './responses/models/Unit/UpdateUnitInfoRequest';

export class UnitApi extends ApiClient {
	public getUnitStructure(id: number): Promise<IBaseResponse<Unit>> {
		return this.getAsync({
			url: url_unit_get_unit_structure(id),
		} as IGetRequest);
	}

	public register(
		unitData: UnitRegisterCommand
	): Promise<IBaseResponse<number>> {
		return this.postAsync({
			url: url_unit_get_unit_register,
			data: unitData,
		} as IPostRequest);
	}

	async updateUnitInformation(
		unitData: UpdateUnitInfoRequest
	): Promise<IBaseResponse<boolean>> {
		return this.postAsync({
			url: url_unit_info(unitData.unitId),
			data: unitData.unitInfo,
		} as IPostRequest);
	}

	public edit(data: UnitEditCommand): Promise<IBaseResponse<number>> {
		return this.postAsync({
			url: url_unit_configuration,
			data,
		} as IPostRequest);
	}
}
