import { ApplicationApi, ModelDto, SelectItemDto } from 'api';
import { infinityCacheTime } from 'api/ReactQueryOptions';

export const travelUnitsQuery = (api: ApplicationApi) => ({
	queryKey: installationKeys.travelUnits(),
	queryFn: async () => (await api.getTravelReimbursementUnits()).data,
	...infinityCacheTime<string[]>(),
});

export const brandsQuery = (api: ApplicationApi, mainSegment: string) => ({
	queryKey: installationKeys.brands(mainSegment),
	queryFn: async () =>
		(await api.getBrandsByMainSegment({ mainSegment })).data,
	...infinityCacheTime<SelectItemDto[]>(),
});

export const modelsQuery = (api: ApplicationApi, brandId: number) => ({
	queryKey: installationKeys.models(brandId),
	queryFn: async () => (await api.getModelsByBrandId({ brandId })).data,
	...infinityCacheTime<ModelDto[]>(),
});

export const demoUseQuery = (api: ApplicationApi, id: number) => ({
	queryKey: installationKeys.demoUse(id),
	queryFn: async () => (await api.getDemoUse({ id })).data,
});

export const demoUsesQuery = (api: ApplicationApi, unitId: number) => ({
	queryKey: installationKeys.demoUses(unitId),
	queryFn: async () => (await api.getDemoUses({ unitId })).data,
});

export const installationKeys = {
	all: ['installation'] as const,
	brands: (mainSegment: string) =>
		[...installationKeys.all, 'brands', mainSegment] as const,
	models: (brand: number) =>
		[...installationKeys.all, 'models', brand] as const,
	travelUnits: () => [...installationKeys.all, 'travel-units'] as const,
	demoUse: (id: number) => [...installationKeys.all, 'demoUse', id] as const,
	demoUses: (unitId: number) =>
		[...installationKeys.all, 'demoUses', unitId] as const,
};
