/* Borrowed from Rick Strahl -> https://weblog.west-wind.com/posts/2014/jan/06/javascript-json-date-parsing-and-real-dates */
/* eslint-disable @typescript-eslint/no-explicit-any */

export {};

declare global {
	interface JSON {
		useDateParser: (reset?: any) => void;
		dateParser: (key: any, value: any) => any;
		parseWithDate: (json: string) => any;
		dateStringToDate: (dtString: any, nullDateVal: any) => any;
		_parseSaved: JSON['parse'] | null;
	}
}

if (globalThis.JSON && !globalThis.JSON.dateParser) {
	const ISO_8601 =
		/^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*))(?:Z|(\+|-)([\d|:]*))?$/;

	globalThis.JSON.useDateParser = function (reset: any) {
		/// <summary>
		/// Globally enables JSON date parsing for JSON.parse().
		/// replaces the default JSON parser with parse plus dateParser extension
		/// </summary>
		/// <param name="reset" type="bool">when set restores the original JSON.parse() function</param>

		// if any parameter is passed reset
		if (typeof reset != 'undefined') {
			if (JSON._parseSaved) {
				JSON.parse = JSON._parseSaved;
				JSON._parseSaved = null;
			}
		} else {
			if (!JSON._parseSaved) {
				JSON._parseSaved = JSON.parse;
				JSON.parse = JSON.parseWithDate;
			}
		}
	};

	globalThis.JSON.dateParser = function (_, value) {
		/// <summary>
		/// Globally enables JSON date parsing for JSON.parse().
		/// Replaces the default JSON.parse() method and adds
		/// the datePaser() extension to the processing chain.
		/// </summary>
		/// <param name="key" type="string">property name that is parsed</param>
		/// <param name="value" type="any">property value</param>
		/// <returns type="date">returns date or the original value if not a date string</returns>
		if (typeof value === 'string') {
			if (ISO_8601.test(value)) return new Date(value);
		}
		return value;
	};

	globalThis.JSON.parseWithDate = function (json) {
		/// <summary>
		/// Wrapper around the JSON.parse() function that adds a date
		/// filtering extension. Returns all dates as real JavaScript dates.
		/// </summary>
		/// <param name="json" type="string">JSON to be parsed</param>
		/// <returns type="any">parsed value or object</returns>
		const parse = JSON._parseSaved ? JSON._parseSaved : JSON.parse;
		try {
			return parse(json, JSON.dateParser);
		} catch (e) {
			// orignal error thrown has no error message so rethrow with message
			throw new Error('JSON content could not be parsed');
		}
	};

	globalThis.JSON.dateStringToDate = function (dtString, nullDateVal) {
		/// <summary>
		/// Converts a JSON ISO date or real date a date value.
		/// Supports both JSON encoded dates or plain date formatted strings
		/// (without the JSON string quotes).
		/// If you pass a date the date is returned as is. If you pass null
		/// null or the nullDateVal is returned.
		/// </summary>
		/// <param name="dtString" type="var">Date String in ISO or MSAJAX format</param>
		/// <param name="nullDateVal" type="var">value to return if date can't be parsed</param>
		/// <returns type="date">date or the nullDateVal (null by default)</returns>
		if (!nullDateVal) nullDateVal = null;

		if (!dtString) return nullDateVal; // empty

		if (dtString.getTime) return dtString; // already a date

		if (dtString[0] === '"' || dtString[0] === "'")
			// strip off JSON quotes
			dtString = dtString.substr(1, dtString.length - 2);

		const a = ISO_8601.test(dtString);
		if (a) return new Date(dtString);

		return nullDateVal;
	};
}

// use date parser for all JSON.parse() requests
// make sure to call before any JSON conversions
globalThis.JSON.useDateParser();
