import { useQueryClient } from '@tanstack/react-query';
import { ReactNode } from 'react';

import { UseCheckUserAccessProps, checkUserAccess } from './checkUserAccess';

const AuthorizeComponent = ({
	children,
	...area
}: React.PropsWithChildren<UseCheckUserAccessProps>): ReactNode => {
	const queryClient = useQueryClient();
	// as components are loaded after loading routes, security matrix should be loaded already
	return checkUserAccess(queryClient, area) ? children : null;
};

export default AuthorizeComponent;
