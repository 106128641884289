import { lazy } from 'react';
import { isMobile } from 'react-device-detect';
import { Outlet, Route } from 'react-router-dom';

import { AreaName } from 'domain/constants';
import { OperationType } from 'library/models/enums/OperationType';
import { createArea } from 'library/utils/helpers/AreaHelper';
import Authorize from 'main/auth/Authorize';
import CommissioningSectionPage from 'pages/unitoverview/sectionPages/CommissioningSectionPage';
import ConfigurationSectionPage from 'pages/unitoverview/sectionPages/ConfigurationSectionPage';
import DemoDealerUsageSectionPage from 'pages/unitoverview/sectionPages/DemoDealerUsageSectionPage';
import DiagnosticSectionPage from 'pages/unitoverview/sectionPages/DiagnosticSectionPage';
import EndCustomerSectionPage from 'pages/unitoverview/sectionPages/EndCustomerSectionPage';
import InformationSectionPage from 'pages/unitoverview/sectionPages/InformationSectionPage';
import KitsSectionPage from 'pages/unitoverview/sectionPages/KitsSectionPage';
import OperatingLocationSectionPage from 'pages/unitoverview/sectionPages/OperatingLocationSectionPage';
import OtherProductsSectionPage from 'pages/unitoverview/sectionPages/OtherProductsSectionPage';
import ProductInOperationSectionPage from 'pages/unitoverview/sectionPages/ProductInOperationSectionPage';
import ServiceAgreementsSectionPage from 'pages/unitoverview/sectionPages/ServiceAgreementsSectionPage';
import ServiceProtocolsPage from 'pages/unitoverview/sectionPages/ServiceProtocolsPage';
import TelematicsSectionPage from 'pages/unitoverview/sectionPages/TelematicsSectionPage';
import VariantsSectionPage from 'pages/unitoverview/sectionPages/VariantsSectionPage';
import WarrantySectionPage from 'pages/unitoverview/sectionPages/WarrantySectionPage';

import { PageRedirect } from './PageRedirect';
import {
	UnitOverview_Configuration,
	UnitOverview_DemoDealerUsage,
	UnitOverview_Diagnostic,
	UnitOverview_EndCustomer,
	UnitOverview_Information,
	UnitOverview_Kits,
	UnitOverview_OperatingLocation,
	UnitOverview_OtherProducts,
	UnitOverview_ServiceAgreements,
	UnitOverview_ServiceProtocols,
	UnitOverview_Telematics,
	UnitOverview_Variants,
	UnitOverview_Warranty,
	unitCommissioning,
	unitOverviewEventLog,
	unitProductInOperation,
} from './urls/RouteUrls';

const UnitOverviewPage = lazy(
	() => import('pages/unitoverview/UnitOverviewPage')
);

const EventLogSectionPage = lazy(
	() => import('pages/unitoverview/sectionPages/EventLogSectionPage')
);

export const getUnitOverviewRoutes = (): JSX.Element[] => [
	<Route
		key={'unitoverview'}
		element={
			<Authorize area={createArea(AreaName.Unit, OperationType.Read)}>
				<Outlet />
			</Authorize>
		}>
		<Route element={<UnitOverviewPage />}>
			<Route
				index
				element={
					<PageRedirect
						url={
							isMobile
								? UnitOverview_Information
								: UnitOverview_Configuration
						}
					/>
				}
			/>
			<Route
				path={UnitOverview_Information}
				element={<InformationSectionPage />}
			/>
			<Route
				path={UnitOverview_Configuration}
				element={<ConfigurationSectionPage />}
			/>
			<Route
				path={UnitOverview_EndCustomer}
				element={<EndCustomerSectionPage />}
			/>
			<Route
				path={unitProductInOperation}
				element={<ProductInOperationSectionPage />}
			/>
			<Route
				path={unitCommissioning}
				element={<CommissioningSectionPage />}
			/>
			<Route
				path={UnitOverview_ServiceAgreements}
				element={<ServiceAgreementsSectionPage />}
			/>
			<Route
				path={UnitOverview_Warranty}
				element={<WarrantySectionPage />}
			/>
			<Route
				path={UnitOverview_OtherProducts}
				element={<OtherProductsSectionPage />}
			/>
			<Route
				path={UnitOverview_OperatingLocation}
				element={<OperatingLocationSectionPage />}
			/>
			<Route
				path={UnitOverview_ServiceProtocols}
				element={<ServiceProtocolsPage />}
			/>
			<Route
				path={UnitOverview_DemoDealerUsage}
				element={<DemoDealerUsageSectionPage />}
			/>
			<Route path={UnitOverview_Kits} element={<KitsSectionPage />} />
			<Route
				path={UnitOverview_Telematics}
				element={<TelematicsSectionPage />}
			/>
			<Route
				path={UnitOverview_Variants}
				element={<VariantsSectionPage />}
			/>
			<Route
				path={UnitOverview_Diagnostic}
				element={<DiagnosticSectionPage />}
			/>
			<Route
				path={unitOverviewEventLog}
				element={<EventLogSectionPage />}
			/>
		</Route>
	</Route>,
];
