import { createEntityAdapter } from '@reduxjs/toolkit';

import { Installation } from 'modules/Shared/State/unit';

import { EditUnitState } from './Types';

export const originInstallationsEntity = createEntityAdapter<
	Installation,
	string
>({
	selectId: ({ generatedUuid }) => generatedUuid,
});

export const originInstallationsEntitySelectors =
	originInstallationsEntity.getSelectors<EditUnitState>(
		({ originInstallations: removedInstallations }) => removedInstallations
	);
