import { styled } from '@mui/material/styles';
import { camelCase, last, map, split } from 'lodash';
import { useTranslation } from 'react-i18next';

import { CommissioningQuestionsDto } from 'api';
import { Namespace, ParseKeys } from 'i18next';
import { AccordionContainer, AccordionItem } from 'library/components';

interface QuestionsProps {
	questions: CommissioningQuestionsDto;
}

const StyledQuestion = styled('div')({
	columnGap: '2rem',
	display: 'flex',
	justifyContent: 'space-between',
});

const StyledValue = styled('span')({
	whiteSpace: 'nowrap',
});

const addTransPrefix = (key: string): ParseKeys<Namespace> =>
	`commissioning:${key}` as ParseKeys<Namespace>;

export const Questions = ({ questions }: QuestionsProps) => {
	const { t } = useTranslation<Namespace>();
	const { fullThrottleValue, speedAtFullUnit, speedAtFullValue, ...rest } =
		questions;

	return (
		<AccordionContainer>
			{map(rest, (sectionQuestions, key) => (
				<AccordionItem
					accordionName={key}
					header={t(addTransPrefix(key))}
					key={key}>
					{map(sectionQuestions, ({ answerValue, questionKey }) => (
						<StyledQuestion key={questionKey}>
							<span>
								{t(
									addTransPrefix(
										camelCase(last(split(questionKey, '_')))
									)
								)}
							</span>
							<StyledValue>
								{t(addTransPrefix(answerValue))}
							</StyledValue>
						</StyledQuestion>
					))}
				</AccordionItem>
			))}
			<AccordionItem
				accordionName="commisioningQuestionTestDriveData"
				header={t(addTransPrefix('commisioningQuestionTestDriveData'))}>
				{map(
					{
						fullThrottleValue,
						speedAtFullValue: speedAtFullValue
							? `${speedAtFullValue} ${t(
									addTransPrefix(
										speedAtFullUnit?.toString() || ''
									)
								)}`
							: '',
					},
					(value, key) => (
						<StyledQuestion key={key}>
							<span>{t(addTransPrefix(key))}</span>
							<StyledValue>{value}</StyledValue>
						</StyledQuestion>
					)
				)}
			</AccordionItem>
		</AccordionContainer>
	);
};
