import {
	CheckCircleOutline,
	Close,
	ErrorOutline,
	InfoOutlined,
	WarningAmberOutlined,
} from '@mui/icons-material';
import { Collapse, IconButton } from '@mui/material';
import { SnackbarKey, SnackbarProvider, closeSnackbar } from 'notistack';
import { PropsWithChildren, memo } from 'react';

import { SNACKBAR_TIMEOUT, SnackbarUtils } from './SnackbarUtils';

const iconSx = { marginInlineEnd: 1 };

const CloseAction = (snackbarId: SnackbarKey) => (
	<IconButton
		onClick={() => {
			closeSnackbar(snackbarId);
		}}
		sx={{
			color: (theme) => theme.palette.error.contrastText,
		}}>
		<Close />
	</IconButton>
);

const Component = ({ children }: PropsWithChildren<unknown>): JSX.Element => {
	return (
		<SnackbarProvider
			maxSnack={3}
			TransitionComponent={Collapse}
			autoHideDuration={SNACKBAR_TIMEOUT}
			iconVariant={{
				info: <InfoOutlined sx={iconSx} />,
				error: <ErrorOutline sx={iconSx} />,
				success: <CheckCircleOutline sx={iconSx} />,
				warning: <WarningAmberOutlined sx={iconSx} />,
			}}
			action={CloseAction}>
			<SnackbarUtils />
			{children}
		</SnackbarProvider>
	);
};

export const SnackbarWrapper = memo(Component);
