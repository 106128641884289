import { useEffect, useRef, useState } from 'react';

const defaultUnlockTime = 30 * 1000; // 30 secs

export const useBlockClick = (props?: { unlockTime?: number }) => {
	const timeout = useRef(defaultUnlockTime);
	if (props?.unlockTime) {
		timeout.current = props?.unlockTime;
	}

	const [clicked, setClicked] = useState(false);

	useEffect(() => {
		if (clicked) {
			const timeoutRef = setTimeout(() => {
				setClicked(false);
			}, timeout.current);
			return () => clearTimeout(timeoutRef);
		}

		return undefined;
	}, [clicked]);

	const handleClick = useRef(() => {
		setClicked(true);
	});

	return {
		disabled: clicked,
		handleClick: handleClick.current,
	};
};
