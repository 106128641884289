import { map } from 'lodash';

import { MenuDto } from 'api';
import { BASE_URL } from 'main/routes';

import IBaseMenuItem from '../models/IBaseMenuItem';
import IMenu from '../models/IMenu';
import { createMenu, createMenuItem, createSubMenu } from './MenuGenerator';

export const getAggregateMenus = (menu: Array<MenuDto>): IMenu[] => {
	if (menu !== null) {
		return map(menu, (element) => {
			return createMenu(
				element.title,
				getRoute(element),
				createMenuItems(element.childItems)
			);
		});
	}

	return [];
};

const createMenuItems = (childItems: MenuDto[]): IBaseMenuItem[] => {
	return map(childItems, (element) => {
		if (element.childItems && element.childItems.length > 0) {
			return createSubMenu(
				element.title,
				createMenuItems(element.childItems)
			);
		} else {
			return createMenuItem(element.title, getRoute(element));
		}
	});
};

const getRoute = (menu: MenuDto): string => {
	if (menu.project === 'PLM') {
		return menu.resolvedUrl.replace(BASE_URL, '');
	}

	return menu.resolvedUrl;
};
