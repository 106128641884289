import { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import {
	DeployAlertNotificationAlert,
	StaticNotificationAlert,
} from 'library/components/alerts';
import {
	selectCurrentPagePath,
	selectDeployAlertNotification,
	selectStaticNotification,
} from 'library/redux/application';
import { useAppSelector } from 'library/redux/hooks';
import { home_HomeUrl } from 'main/routes/urls/RouteUrls';

import TitleInfo, { ITitleInfoProps } from './header/TitleInfo';

const TitleWrapper = ({
	title,
	category,
	action,
	displayBackButton,
}: ITitleInfoProps) => {
	const location = useLocation();
	const { t } = useTranslation('home');

	const firstTwoPathsSegments = location.pathname
		?.split('/')
		.slice(0, 3)
		.join('/');

	const menuPaths = useAppSelector(
		selectCurrentPagePath(firstTwoPathsSegments)
	);
	const deployAlertNotification = useAppSelector(
		selectDeployAlertNotification
	);
	const staticNotification = useAppSelector(selectStaticNotification);

	const isHome = !location.pathname || location.pathname === home_HomeUrl;

	if (menuPaths && menuPaths.length > 1) {
		category ??= menuPaths[0].title;
		title ??= menuPaths[1].title;
	}

	useEffect(() => {
		document.title =
			(isHome || !title ? '' : `${title} - `) + t('content-label');
	}, [isHome, title, t]);

	return (
		<TitleInfo
			category={category}
			title={title}
			action={action}
			displayBackButton={displayBackButton}>
			{isHome ? (
				<DeployAlertNotificationAlert
					notification={deployAlertNotification}
				/>
			) : (
				<StaticNotificationAlert notification={staticNotification} />
			)}
		</TitleInfo>
	);
};

export default memo(TitleWrapper);
