// Taken from the https://www.w3.org/TR/uievents-key/#named-key-attribute-values
export enum keys {
	ENTER = 'Enter',
	SPACE = ' ',
	ESCAPE = 'Escape',
	TAB = 'Tab',
	BACKSPACE = 'Backspace',
	ARROW_DOWN = 'ArrowDown',
	ARROW_UP = 'ArrowUp',
	ARROW_LEFT = 'ArrowLeft',
	ARROW_RIGHT = 'ArrowRight',
	END = 'End',
	HOME = 'Home',
}
