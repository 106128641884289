import * as React from 'react';

import { DisplayDevice } from '../../../../../library/models/enums/DisplayDevice';

export interface IBackActions {
	goToPreviousMenu: () => void;
}

export interface IBackButton extends IBackActions {
	displayDevice: DisplayDevice;
}

const BackButton = ({
	goToPreviousMenu,
	displayDevice,
}: IBackButton): JSX.Element => {
	const handleOnClick = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.preventDefault();
		goToPreviousMenu();
	};

	const baseClassName = 'header__nav__back-btn';

	return (
		<button
			id={displayDevice}
			className={`${baseClassName} ${baseClassName}--${displayDevice}`}
			onClick={handleOnClick}
		>
			<i className={`${baseClassName}__icon`}></i>
			<span className={`${baseClassName}__text`}>BACK</span>
		</button>
	);
};

export default React.memo(BackButton);
