import { useQuery } from '@tanstack/react-query';
import { FieldPath, FieldValues } from 'react-hook-form';

import { useProductCenterApi } from 'api';
import { countryInfosQuery } from 'api/queries';
import { mapCountryInfoToIdLabelPhonePrefix } from 'api/responses/models/Configuration/CountryInfoDto';
import {
	ControlledAutocomplete,
	ControlledAutocompleteProps,
} from 'library/components/controls';
import { Option } from 'library/models/Option';
import { selectUser } from 'library/redux/application';
import { useAppSelector } from 'library/redux/hooks';

export type PhonePrefixInputProps<
	TFieldValues extends FieldValues = FieldValues,
	TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = Omit<
	ControlledAutocompleteProps<Option<string>, TFieldValues, TName>,
	'options'
>;

export const PhonePrefixInput = <
	TFieldValues extends FieldValues = FieldValues,
	TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
	props: PhonePrefixInputProps<TFieldValues, TName>
): JSX.Element => {
	const languageCode = useAppSelector(selectUser).language;
	const { api } = useProductCenterApi();
	const countriesQuery = useQuery(countryInfosQuery(api, languageCode));

	const phonePrefixOptions = countriesQuery.data?.map(
		mapCountryInfoToIdLabelPhonePrefix
	);

	return (
		<ControlledAutocomplete options={phonePrefixOptions || []} {...props} />
	);
};
