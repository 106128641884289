import { VariantComparison } from 'api';

export const getVariantsComparisonList = (
	model: VariantComparison,
	ignoreColumn: (elem: boolean, index: number) => boolean,
	differencesOnly?: boolean
) => {
	if (model.rows == null) {
		return [];
	}

	// skip rows, where every visible driveline has false value
	const listWithoutEmptyRows = model.rows.filter(
		(row) =>
			!row.drivelineColumns.every(
				(elem, index) => ignoreColumn(elem, index) || !elem
			)
	);

	if (differencesOnly) {
		// skip rows, where there is no differences between driveline
		return listWithoutEmptyRows.filter(
			(row) =>
				!row.drivelineColumns.every(
					(elem, index) => ignoreColumn(elem, index) || elem
				)
		);
	}

	return listWithoutEmptyRows;
};
