import { lazy } from 'react';
import { Outlet, Route } from 'react-router';

import { AreaName } from 'domain/constants';
import { OperationType } from 'library/models/enums/OperationType';
import { createArea } from 'library/utils/helpers/AreaHelper';
import Authorize from 'main/auth/Authorize';

import {
	UNIT_ROUTES,
	unitEditEndCustomerConnect,
	unitRelativeUrl,
} from './urls/RouteUrls';

const [
	ADD_END_CUSTOMER,
	PRODUCT_IN_OPERATION,
	COMMISSIONING,
	EDIT,
	EDIT_END_CUSTOMER,
] = UNIT_ROUTES;

const CommissioningPage = lazy(
	() => import('pages/unit/UnitCommissioningPage')
);
const AddEndCustomerPage = lazy(
	() => import('pages/unit/UnitAddEndCustomerPage')
);
const ProductInOperationPage = lazy(
	() => import('pages/unit/UnitProductInOperationPage')
);
const UnitEditPage = lazy(() => import('pages/unit/UnitEditPage'));

const UnitEditConnectedCustomersPage = lazy(
	() => import('pages/unit/UnitEditConnectedCustomersPage')
);

export const getUnitRoutes = (): JSX.Element[] => {
	return [
		<Route
			key={ADD_END_CUSTOMER}
			path={ADD_END_CUSTOMER}
			element={
				<Authorize
					area={createArea(AreaName.Unit, OperationType.Create)}>
					<Outlet />
				</Authorize>
			}>
			<Route index element={<AddEndCustomerPage />} />
			<Route path={unitRelativeUrl} element={<AddEndCustomerPage />} />
		</Route>,
		<Route
			key={PRODUCT_IN_OPERATION}
			path={PRODUCT_IN_OPERATION}
			element={
				<Authorize
					area={createArea(
						AreaName.ProductInOperation,
						OperationType.Create
					)}>
					<Outlet />
				</Authorize>
			}>
			<Route index element={<ProductInOperationPage />} />
			<Route
				path={unitRelativeUrl}
				element={<ProductInOperationPage />}
			/>
		</Route>,
		<Route
			key={COMMISSIONING}
			path={COMMISSIONING}
			element={
				<Authorize
					area={createArea(
						AreaName.Commissioning,
						OperationType.Create
					)}>
					<Outlet />
				</Authorize>
			}>
			<Route index element={<CommissioningPage />} />
			<Route path={unitRelativeUrl} element={<CommissioningPage />} />
		</Route>,
		<Route
			key={EDIT}
			path={EDIT}
			element={
				<Authorize
					area={createArea(AreaName.Unit, OperationType.Write)}>
					<Outlet />
				</Authorize>
			}>
			<Route index element={<UnitEditPage />} />
			<Route path={unitRelativeUrl} element={<UnitEditPage />} />
		</Route>,
		<Route
			key={EDIT_END_CUSTOMER}
			path={EDIT_END_CUSTOMER}
			element={
				<Authorize
					area={createArea(AreaName.Unit, OperationType.Create)}>
					<Outlet />
				</Authorize>
			}>
			<Route
				path={`${unitRelativeUrl}/${unitEditEndCustomerConnect}`}
				element={<UnitEditConnectedCustomersPage />}
			/>
		</Route>,
	];
};
