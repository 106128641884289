import CloseIcon from '@mui/icons-material/Close';
import {
	Dialog,
	DialogContent,
	DialogProps,
	DialogTitle,
	IconButton,
	Theme,
	useMediaQuery,
} from '@mui/material';
import { noop } from 'lodash';
import { PropsWithChildren, useCallback } from 'react';

import { Disable, Sxable } from 'library/types';

import { PageButtons } from '../buttons';

export interface BaseDialogProps extends Sxable, Disable {
	closeButtonText?: string;
	closeOnConfirm?: boolean;
	confirmButtonText?: string;
	isSubmitting?: boolean;
	open?: boolean;
	onConfirm?: () => void;
	onClose?: () => void;
	maxWidth?: DialogProps['maxWidth'];
	title?: string;
	withCloseIcon?: boolean;
}

/**
 * Base component to use for creating dialogs.
 * @param {boolean} [withCloseIcon=true] - Can be used to hide close icon, but beware, as on mobile view the dialog is covering whole screen
 */
export const BaseDialog = ({
	children,
	closeButtonText,
	closeOnConfirm = false,
	confirmButtonText,
	disabled = false,
	isSubmitting = false,
	onConfirm = noop,
	onClose = noop,
	open = true,
	maxWidth,
	sx,
	title = '',
	withCloseIcon = true,
}: PropsWithChildren<BaseDialogProps>) => {
	const fullScreen = useMediaQuery(({ breakpoints }: Theme) =>
		breakpoints.only('xs')
	);

	const handleOnConfirm = useCallback(async () => {
		await onConfirm();

		if (closeOnConfirm) {
			onClose();
		}
	}, [onConfirm, closeOnConfirm, onClose]);

	return (
		<Dialog
			className="base-dialog__root"
			open={open}
			onClose={onClose}
			fullScreen={fullScreen}
			maxWidth={maxWidth !== undefined ? maxWidth : 'sm'}
			fullWidth
			sx={sx}>
			{title && (
				<DialogTitle
					className="base-dialog__title"
					sx={{
						padding: ({ spacing }) => ({
							sm: spacing(2, 4, 2, 3),
						}),
					}}>
					{title}
				</DialogTitle>
			)}
			{withCloseIcon && onClose && (
				<IconButton
					className="base-dialog__title-close-button"
					aria-label="close"
					onClick={onClose}
					sx={{
						position: 'absolute',
						right: 0,
						top: 0,
						color: ({
							palette: {
								gray: { dark },
							},
						}) => dark,
					}}>
					<CloseIcon />
				</IconButton>
			)}
			<DialogContent className="base-dialog__content">
				{children}
				{confirmButtonText && (
					<PageButtons
						primaryButtonDisabled={disabled}
						primaryButtonLabel={confirmButtonText}
						secondaryButtonLabel={closeButtonText}
						primaryButtonAction={handleOnConfirm}
						secondaryButtonAction={onClose}
						withDivider={false}
						displayLoadingIndicator={isSubmitting}
					/>
				)}
			</DialogContent>
		</Dialog>
	);
};
