import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { AuthProviderStorage } from 'main/auth/provider/Storage';
import { ErrorContent } from 'main/errors/ErrorContent';
import { home_HomeUrl } from 'main/routes/urls/RouteUrls';

const ErrorUnauthenticatedPage = (): JSX.Element => {
	const { t } = useTranslation('error');
	const navigate = useNavigate();

	useEffect(() => {
		AuthProviderStorage.getInstance()
			.getProvider()
			.isAuthenticated()
			.then((result) => {
				if (result) {
					navigate(home_HomeUrl);
				}
			});
	}, []);

	return (
		<ErrorContent
			description={t('contact-support')}
			subtitle={t('unauthenticated-subtitle')}
			title={t('unauthenticated-title')}
		/>
	);
};

export default ErrorUnauthenticatedPage;
