import { useTranslation } from 'react-i18next';

import { useProductCenterApi } from 'api';
import { useQueryLineOfBusiness } from 'api/queries';
import { MainSegment } from 'api/responses/models/Unit/UnitStructure/MainSegment';
import { TextDisplay } from 'library/components';

export const LineOfBusinessDisplay = ({
	mainSegment,
	lineOfBusiness,
}: {
	mainSegment: MainSegment | null;
	lineOfBusiness: string | null;
}): JSX.Element => {
	const { t } = useTranslation('unitCreation');

	const { api } = useProductCenterApi();
	const lineOfBusinessQuery = useQueryLineOfBusiness(api, mainSegment);

	const lineOption = lineOfBusiness
		? lineOfBusinessQuery.data?.find(
				(line) =>
					line.value.localeCompare(lineOfBusiness, undefined, {
						sensitivity: 'accent',
					}) === 0
			)
		: null;

	return (
		<TextDisplay
			label={t('configuration-select-line-of-business')}
			loading={lineOfBusinessQuery.isLoading}
			value={lineOption?.text ?? lineOfBusiness}
		/>
	);
};
