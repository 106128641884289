import { WarningAmber } from '@mui/icons-material';
import { Alert } from '@mui/material';

import { StaticNotification } from 'library/models/Alert';

interface StaticNotificationAlertProps {
	notification: StaticNotification | null;
}

export const StaticNotificationAlert = ({
	notification,
}: StaticNotificationAlertProps): JSX.Element | null =>
	notification ? (
		<Alert
			iconMapping={{ error: <WarningAmber /> }}
			severity={notification.severity || 'info'}
			sx={{ alignItems: 'center', pt: 1, pb: 1, pl: 2, pr: 2 }}>
			{notification.message}
		</Alert>
	) : (
		<></>
	);
