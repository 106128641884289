import { lazy } from 'react';
import { Route } from 'react-router-dom';

import { AreaName } from 'domain/constants';
import { OperationType } from 'library/models/enums/OperationType';
import { createArea } from 'library/utils/helpers/AreaHelper';
import Authorize from 'main/auth/Authorize';

import { serviceProtocolParam } from './urls/RouteUrls';

const GenerateServiceProtocolPage = lazy(
	() => import('pages/serviceprotocol/GenerateServiceProtocolPage')
);
const ViewServiceProtocolPage = lazy(
	() => import('pages/serviceprotocol/ViewServiceProtocolPage')
);

export const getServiceProtocolRoutes = (): JSX.Element[] => [
	<Route
		key={'generateServiceProtocol'}
		path={`${serviceProtocolParam}?/generate`}
		element={
			<Authorize
				area={createArea(
					AreaName.ServiceProtocol,
					OperationType.Create
				)}>
				<GenerateServiceProtocolPage />
			</Authorize>
		}></Route>,
	<Route
		key={'viewServiceProtocol'}
		path={`${serviceProtocolParam}/view`}
		element={
			<Authorize
				area={createArea(AreaName.ServiceProtocol, OperationType.Read)}>
				<ViewServiceProtocolPage />
			</Authorize>
		}></Route>,
];
