import {
	IndividualReplacementType,
	ReplaceProductIndividualCommand,
} from 'api';

import { ReplaceIndividualFormType } from './Types';

export const mapFormToRequest =
	(replacementType: IndividualReplacementType, serialNumber: string) =>
	({
		newSerialNumber,
		replaceReason,
		approvalCaseNumber,
	}: ReplaceIndividualFormType): ReplaceProductIndividualCommand => ({
		replacementType,
		newSerialNumber: newSerialNumber.trim(),
		oldSerialNumber: serialNumber.trim(),
		replaceReason: replaceReason.trim(),
		approvalCaseNumber: approvalCaseNumber?.trim(),
	});
