import { FileUploadState } from 'library/redux/fileUpload';
import { OperatingLocationFormType } from 'modules/Shared/Components/OperatingLocation/OperatingLocationForm';
import { RootState } from 'store';

import {
	ProductInOperationType,
	ProductInOperationWarrantyErrors,
} from './Types';

export const selectUnitId = (state: RootState): number =>
	state.productInOperation.unitId;

export const selectOperatingLocation = (
	state: RootState
): OperatingLocationFormType | null =>
	state.productInOperation.operatingLocation;

export const selectProductInOperation = (
	state: RootState
): ProductInOperationType | null => state.productInOperation.productInOperation;

export const selectProductInOperationFiles = (
	state: RootState
): FileUploadState => state.productInOperationFiles;

export const selectWarrantyErrors = (
	state: RootState
): ProductInOperationWarrantyErrors | null =>
	state.productInOperation.warrantyErrors;
