import { createSelector } from '@reduxjs/toolkit';

import { MenuDto } from 'api';
import { StaticNotification } from 'library/models/Alert';
import { User } from 'library/models/User';
import { RootState } from 'store';

export const selectMenuItems = (state: RootState): MenuDto[] =>
	state.application.menu;

export const selectUser = (state: RootState): User => state.application.user;

export const selectLanguage = (state: RootState): string =>
	state.application.user.language;

export const selectDeployAlertNotification = (state: RootState): string =>
	state.application.deployAlertNotification;

export const selectStaticNotification = (
	state: RootState
): StaticNotification | null => state.application.staticNotification;

export const selectHistory = ({ application: { history } }: RootState) =>
	history;

export const selectAlertsAndNotifications = createSelector(
	[
		(state: RootState) => state.application.loader,
		(state: RootState) => state.application.alert,
		selectDeployAlertNotification,
		selectStaticNotification,
	],
	(loader, alert, deployAlertNotification, staticNotification) => ({
		loader,
		alert,
		deployAlertNotification,
		staticNotification,
	})
);

export const selectCurrentPagePath = (relativeUrl: string) =>
	createSelector([({ application: { menu } }: RootState) => menu], (menu) =>
		findMenuItemRecursiveInternal(menu, relativeUrl.toLowerCase())
	);

const findMenuItemRecursiveInternal = (
	items: MenuDto[],
	relativeUrl: string
): MenuDto[] | null => {
	if (items.length === 0) {
		return null;
	}

	const item = items.find((item) =>
		item.resolvedUrl.toLowerCase().endsWith(relativeUrl)
	);

	if (item) {
		return [item];
	}

	for (const parent of items) {
		const items = findMenuItemRecursiveInternal(
			parent.childItems,
			relativeUrl
		);
		if (items) {
			return [parent, ...items];
		}
	}

	return null;
};
