export const PRODUCT_IN_OPERATION_NAME = 'productInOperation';
export const COMMISSIONING_NAME = 'commissioning';
export const UNIT_CREATION_NAME = 'unitCreation';

export enum AreaName {
	ConsoleDebugging = 'Debugging',
	DevelopersTestPage = 'DevelopersTestPage',
	ManageDeploymentAlert = 'ManageDeploymentAlert',
	Consumer = 'Consumer',
	Unit = 'Unit',
	UnitDelete = 'UnitConfiguration_DeleteUnit',
	DemoUse = 'DemoUse',
	TransferUnit = 'UnitConfiguration_TransferUnit',
	SearchPublication = 'SearchPublication',
	MaintenancePlanner = 'MaintenancePlanner',
	MaintenancePlannerAdmin = 'MaintenancePlannerAdmin',
	ServiceCalculation_Search = 'ServiceCalculation_Search',
	ServiceCalculation_CalculationWizard = 'ServiceCalculation_CalculationWizard',
	ServiceCalculation_ServicePlanner = 'ServiceCalculation_ServicePlanner',
	ServiceProtocol = 'ServiceProtocol',
	ServiceProtocol_ViewCancelled = 'ServiceProtocol_ViewCancelled',
	ServiceProtocol_CancelFinalized = 'ServiceProtocol_CancelFinalized',
	DeleteInitiatedProtocol = 'DeleteInitiatedProtocol',
	ShowOrderButtonOnOrderPage = 'ShowOrderButtonOnOrderPage',
	UnitComments = 'Unit_Comments',
	Commissioning = 'Commissioning',
	AddCommissioningDocuments = 'AddCommissioningDocuments',
	ProductInOperation = 'ProductInOperation',
	MaintainCoverage = 'MaintainCoverage',
	ForceSync = 'ForceSync',
	VmmReset = 'VmmReset',
	Individual = 'Individual',
	Repair = 'RepairEvent_BrowseHistory',
	VstCode = 'ProductIndividualVSTCode',
	LogisticTrail = 'ProductIndividualLogisticTrail',
	ProductIndividualEventLog = 'ProductIndividualEventLog',
	UploadProductionAndClassificationDocuments = 'UploadProductionAndClassificationDocuments',
	IndividualViewClaim = 'ViewClaimHistoryDetails',
}

export const RPM_UNIT = 'rpm';
export const HP_UNIT = 'hp';
