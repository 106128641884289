import { Route } from 'react-router-dom';

import ErrorUnauthenticatedPage from 'pages/errors/ErrorUnauthenticatedPage';
import ErrorUnauthorizedPage from 'pages/errors/ErrorUnauthorizedPage';

export const getErrorRoutes = (): JSX.Element[] => [
	<Route
		key={'authentication'}
		path={'authentication'}
		element={<ErrorUnauthenticatedPage />}
	/>,
	<Route
		key={'authorization'}
		path={'authorization'}
		element={<ErrorUnauthorizedPage />}
	/>,
];
