type SortablePosition = {
	sortPosition: number;
};

export const sortByPosition = <T extends SortablePosition>(
	elements: T[] = []
) =>
	[...elements].sort(
		({ sortPosition }, { sortPosition: sortByPositionB }) =>
			sortPosition - sortByPositionB
	);

export const filterNullItems = <T>(item: T | null | undefined) => item !== null;
