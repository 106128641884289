import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { OperatingLocationFormType } from 'modules/Shared/Components/OperatingLocation/OperatingLocationForm';

import { submitProductInOperation } from './Thunk';
import {
	ProductInOperationState,
	ProductInOperationType,
	ProductInOperationWarrantyErrors,
} from './Types';

export const initialState: ProductInOperationState = {
	unitId: 0,
	operatingLocation: null,
	productInOperation: null,
	warrantyErrors: null,
};

export const productInOperationSlice = createSlice({
	name: 'productInOperation',
	initialState,
	reducers: {
		initializeState: (state, { payload }: PayloadAction<number>) => {
			if (!isNaN(payload) && payload >= 0) {
				// reset state
				return { ...initialState, unitId: payload };
			}

			return state;
		},
		operatingLocationChanged: (
			state,
			{ payload }: PayloadAction<OperatingLocationFormType>
		) => {
			state.operatingLocation = payload;
		},
		productInOperationChanged: (
			state,
			{ payload }: PayloadAction<ProductInOperationType>
		) => {
			state.productInOperation = payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(
			submitProductInOperation.fulfilled,
			(
				state,
				{ payload }: PayloadAction<ProductInOperationWarrantyErrors>
			) => {
				state.warrantyErrors = payload;
			}
		);
	},
});

export const {
	initializeState,
	operatingLocationChanged,
	productInOperationChanged,
} = productInOperationSlice.actions;

export default productInOperationSlice.reducer;
