import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { unitKeys } from 'api/queries/UnitQueries';
import { AreaName } from 'domain/constants';
import { ProductIndividualType } from 'domain/unit/enums';
import { IconAnchor } from 'library/components';
import { ActionDialog } from 'library/components/controls';
import { OperationType } from 'library/models/enums/OperationType';
import { createArea } from 'library/utils/helpers/AreaHelper';
import Authorize from 'main/auth/Authorize';

import { ReplaceIndividualAccordionResolver } from './ReplaceIndividualAccordionResolver';

export const ReplaceIndividualDialog = ({
	unitId,
	chassisId,
	serialNumber,
	productCategory,
	matchingIndividualsToSwap,
}: {
	serialNumber: string;
	chassisId: string;
	productCategory: ProductIndividualType;
	matchingIndividualsToSwap?: string[];
	unitId: number;
}): JSX.Element => {
	const queryClient = useQueryClient();

	const onConfirm = useCallback(() => {
		queryClient.invalidateQueries({ queryKey: unitKeys.single(unitId) });
	}, [queryClient, unitId]);

	return (
		<Authorize
			area={createArea(AreaName.Unit, OperationType.Write)}
			displayIntermediatePages={false}
			redirectWhenNotAuthorized={false}>
			<>
				{'ControlSystem' !== productCategory && (
					<ActionDialog
						Anchor={
							<IconAnchor
								dataTestId={`ReplaceIndividualDialog-${chassisId}-${serialNumber}`}
							/>
						}
						titleKey="unitOverview:replacement-options"
						onConfirm={onConfirm}
						renderItem={(props) => (
							<ReplaceIndividualAccordionResolver
								unitId={unitId}
								serialNumber={serialNumber}
								chassisId={chassisId}
								productCategory={productCategory}
								matchingIndividualsToSwap={
									matchingIndividualsToSwap
								}
								{...props}
							/>
						)}
					/>
				)}
			</>
		</Authorize>
	);
};
