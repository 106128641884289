import { Box, BoxProps } from '@mui/material';

export const FloatingButtonBox = ({
	photoUrl,
	children,
	sx = [],
	...props
}: React.PropsWithChildren<
	{ photoUrl: string | null | undefined } & BoxProps
>): JSX.Element => (
	<Box
		sx={[
			{
				display: 'flex',
				minHeight: 128,
			},
			photoUrl
				? {
						justifyContent: 'end',
						alignItems: 'end',
				  }
				: (theme) => ({
						bgcolor: { sm: 'background.default' },
						justifyContent: 'center',
						alignItems: 'center',
						[theme.breakpoints.only('xs')]: {
							minHeight: 0,
						},
				  }),
			...(Array.isArray(sx) ? sx : [sx]),
		]}
		{...props}
	>
		{children}
	</Box>
);
