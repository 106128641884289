import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError, AxiosResponse } from 'axios';

import { IBaseResponse } from 'api/axios/responses/BaseResponse';
import { SubmitErrorResponse } from 'library/exceptions/ValidationErrors';
import { useWizard } from 'library/hooks';
import { hideLoader, showSavingLoader } from 'library/redux/application';
import { useCheckConsoleDebuggingReadAccess } from 'main/auth/checkUserAccess';
import { RootState } from 'store';

import { useValidationErrorsSnackbar } from '../useValidationErrorsSnackbar';

export const createWizardSubmitThunk = (
	thunkName: string,
	submitAction: (
		rootState: RootState
	) => Promise<IBaseResponse<any> | AxiosResponse<any>>
) =>
	createAsyncThunk(
		thunkName,
		async (_, { dispatch, rejectWithValue, getState }) => {
			dispatch(showSavingLoader());

			try {
				return (await submitAction(getState() as RootState)).data;
			} catch (error) {
				const ae = error as AxiosError;
				if (ae) {
					return rejectWithValue({
						errorCode: ae.response?.status,
						axiosCode: ae.code,
						errorMessage: ae.message,
						data: ae.response?.data,
					} as SubmitErrorResponse);
				}
				return rejectWithValue({
					errorMessage: error,
				} as SubmitErrorResponse);
			} finally {
				dispatch(hideLoader());
			}
		}
	);

export interface UseWizardSubmitProps<T = unknown> {
	/**
	 * Submit function.
	 */
	thunkAction: () => Promise<T>;
	/**
	 * Use to execute any action after successful submit.
	 */
	fulfilledAction?: (thunkActionResult: T) => Promise<unknown>;
}

export const useWizardSubmit = <T = unknown>({
	thunkAction,
	fulfilledAction,
}: UseWizardSubmitProps<T>) => {
	const { goToNextStep } = useWizard();
	const { enqueueValidationSnackbar } = useValidationErrorsSnackbar();
	const { checkConsoleDebuggingReadAccess } =
		useCheckConsoleDebuggingReadAccess();

	return async () => {
		try {
			const result = await thunkAction();

			goToNextStep();

			typeof fulfilledAction === 'function' &&
				(await fulfilledAction(result));
		} catch (postError) {
			checkConsoleDebuggingReadAccess() && console.error(postError);

			enqueueValidationSnackbar(postError as Error);
		}
	};
};
