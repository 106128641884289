import { Box, Paper, Typography } from '@mui/material';
import { forwardRef } from 'react';

import {
	CustomInputWithButtonHandle,
	InputWithButton,
	InputWithButtonProps,
} from '../InputWithButton/InputWithButton';

export interface SearchBoxProps extends InputWithButtonProps {
	title: string;
	subtitle: string;
	alternative?: boolean;
	orientation?: 'vertical' | 'horizontal';
}

export const SearchBox = forwardRef<
	CustomInputWithButtonHandle,
	SearchBoxProps
>(
	(
		{
			title,
			subtitle,
			alternative = false,
			orientation = 'vertical',
			sx = [],
			...buttonProps
		},
		ref
	): JSX.Element => (
		<Paper
			variant={alternative ? 'flatGrey' : 'outlined'}
			sx={[
				{
					p: 2,
				},
				orientation === 'horizontal'
					? {
							display: 'flex',
							alignItems: 'center',
							'& > .SearchBoxTitle-root': {
								flex: 2,
							},
							'& > .input-with-button__root': {
								flex: 5,
							},
					  }
					: null,
				...(Array.isArray(sx) ? sx : [sx]),
			]}
		>
			<Box className="SearchBoxTitle-root">
				<Typography variant={alternative ? 'subtitle1' : 'h5'} pt={0.5}>
					{title}
				</Typography>
				<Typography variant="body2" pt={1} pb={2}>
					{subtitle}
				</Typography>
			</Box>
			<InputWithButton ref={ref} {...buttonProps} />
		</Paper>
	)
);
