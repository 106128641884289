import { useTranslation } from 'react-i18next';

import { ErrorContent } from 'main/errors/ErrorContent';

const ErrorUnauthorizedPage = (): JSX.Element => {
	const { t } = useTranslation('error');

	return (
		<ErrorContent
			description={t('contact-support')}
			subtitle={t('unauthorized-subtitle')}
			title={t('unauthorized-title')}
		/>
	);
};

export default ErrorUnauthorizedPage;
