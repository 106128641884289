import React from 'react';

import { isDevEnv } from 'library/constants';

import { WizardValues } from './types';

const WizardContext = React.createContext<WizardValues | null>(null);

if (isDevEnv) {
	WizardContext.displayName = 'WizardContext';
}

export default WizardContext;
