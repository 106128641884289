import { Draft, PayloadAction, createSlice } from '@reduxjs/toolkit';

import { DrivelineId, ProductId } from 'domain/unit';
import { Installation } from 'modules/Shared/State/unit';

import { originInstallationsEntity } from './Entity';
import { EditUnitState } from './Types';

export const initialState: EditUnitState = {
	originInstallations: originInstallationsEntity.getInitialState(),
	removedDrivelines: {},
};

export const unitEditSlice = createSlice({
	name: 'unitEdit',
	initialState,
	reducers: {
		addRemovedDriveline: (
			state: Draft<EditUnitState>,
			{ payload }: PayloadAction<string>
		) => {
			// to check if the removed driveline has been part of origin configuration
			if (
				originInstallationsEntity
					.getSelectors()
					.selectAll(state.originInstallations)
					.find(({ drivelines }) =>
						drivelines.find(
							({ chassisId }) => chassisId === payload
						)
					)
			) {
				addRemovedDrivelineInternal(state, payload);
			}
		},
		addRemovedProduct: (
			state: Draft<EditUnitState>,
			{
				payload: { drivelineId, productId },
			}: PayloadAction<DrivelineId & Pick<ProductId, 'productId'>>
		) => {
			if (productId) {
				if (!state.removedDrivelines[drivelineId]) {
					addRemovedDrivelineInternal(state, drivelineId);
				}

				state.removedDrivelines[drivelineId].push(productId);
			}
		},
		reset: () => initialState,
		setOriginInstallation: (
			{ originInstallations: removedInstallations }: Draft<EditUnitState>,
			{ payload }: PayloadAction<Installation[]>
		) => {
			originInstallationsEntity.setAll(removedInstallations, payload);
		},
	},
});

export const {
	actions: {
		addRemovedDriveline,
		addRemovedProduct,
		reset,
		setOriginInstallation,
	},
} = unitEditSlice;

export default unitEditSlice.reducer;

const addRemovedDrivelineInternal = (
	{ removedDrivelines }: Draft<EditUnitState>,
	drivelineId: string
) => {
	removedDrivelines[drivelineId] = [];
};
