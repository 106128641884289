import { AxiosRequestConfig, Method, ResponseType } from 'axios';

import { IBaseHeader } from '../headers/BaseHeader';

export interface IRequest {
	url: string;
	method: Method;
	responseType?: ResponseType;
	headers?: IBaseHeader;
	config?: AxiosRequestConfig;
}

interface IBaseRequest {
	method: Method;
	responseType: ResponseType;
	headers: IBaseHeader;
}

export abstract class BaseRequest implements IBaseRequest, IRequest {
	url: string;
	method: Method;
	responseType: ResponseType;
	headers: IBaseHeader;
	config?: AxiosRequestConfig;

	constructor(request: IRequest) {
		this.method = request.method;
		this.url = request.url;
		this.responseType = request.responseType ?? 'json';
		this.headers =
			request.headers ??
			({
				contentType: 'application/json',
			} as IBaseHeader);
		this.config = request.config;
	}
}
