// [1e7] + -1e3 + -4e3 + -8e3 + -1e11) -> created UUID template format
function uuidv4() {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	return (([1e7] as any) + -1e3 + -4e3 + -8e3 + -1e11).replace(
		/[018]/g,
		(c: number) =>
			(
				c ^
				(crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
			).toString(16)
	);
}

export const generateUUID = (): string => {
	return typeof crypto.randomUUID === 'function'
		? crypto.randomUUID()
		: uuidv4();
};
