import { useTranslation } from 'react-i18next';

import Loader from 'library/components/loaders/Loader';

const AuthenticationLoader = (): JSX.Element => {
	const { t } = useTranslation('common');

	return <Loader translatedText={t('loading-placeholder')} />;
};

export default AuthenticationLoader;
