import { SvgIcon, SvgIconProps } from '@mui/material';

export const CheckmarkCircle = (props: SvgIconProps) => (
	<SvgIcon {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12ZM12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM17.5497 8.73503C17.7386 8.53358 17.7284 8.21716 17.5269 8.0283C17.3255 7.83943 17.0091 7.84964 16.8202 8.05109L10.4519 14.844L7.20546 10.7859C7.03296 10.5703 6.71831 10.5353 6.50268 10.7078C6.28705 10.8803 6.25209 11.195 6.4246 11.4106L10.0315 15.9193C10.1221 16.0325 10.2574 16.1009 10.4024 16.1066C10.5473 16.1122 10.6875 16.0547 10.7867 15.9489L17.5497 8.73503Z"
		/>
	</SvgIcon>
);
