import { GridView, TableRows } from '@mui/icons-material';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';

import { Sxable } from 'library/types';

export type DisplayAlignment = 'card' | 'grid';

interface GridOrCardToggleButtonProps extends Sxable {
	id?: string;
	value: DisplayAlignment;
	onChange: (value: DisplayAlignment) => void;
}
export const GridOrCardToggleButton = ({
	id,
	value,
	onChange,
	sx = [],
}: GridOrCardToggleButtonProps): JSX.Element => (
	<ToggleButtonGroup
		id={id}
		sx={[...(Array.isArray(sx) ? sx : [sx])]}
		value={value}
		exclusive
		onChange={(_, changedValue) => onChange(changedValue)}
		size="small"
		aria-label="view">
		<ToggleButton value="grid" aria-label="table view">
			<TableRows />
		</ToggleButton>
		<ToggleButton value="card" aria-label="grid view">
			<GridView />
		</ToggleButton>
	</ToggleButtonGroup>
);
