export type DrivelinePosition =
	| 'port'
	| 'port-center'
	| 'center'
	| 'starboard-center'
	| 'starboard';

export function generateDrivelinePositionsOriginal(
	total: number
): DrivelinePosition[] {
	if (total < 2) {
		return ['center'];
	} else if (total === 2) {
		return ['port', 'starboard'];
	} else if (total === 3) {
		return ['port', 'center', 'starboard'];
	} else if (total === 4) {
		return ['port', 'port-center', 'starboard-center', 'starboard'];
	}

	return [
		'port',
		'port-center',
		...Array(total - 4).fill('center'),
		'starboard-center',
		'starboard',
	];
}

export function generateDrivelinePositions(
	total: number,
	isIPS40: boolean
): DrivelinePosition[] {
	if (!isIPS40) {
		return generateDrivelinePositionsOriginal(total);
	}

	if (total < 4) {
		return Array(3).fill('center');
	} else if (total < 7) {
		return [...Array(3).fill('port'), ...Array(3).fill('starboard')];
	} else if (total < 10) {
		return [
			...Array(3).fill('port'),
			...Array(3).fill('center'),
			...Array(3).fill('starboard'),
		];
	} else if (total < 13) {
		return [
			...Array(3).fill('port'),
			...Array(3).fill('port-center'),
			...Array(3).fill('starboard-center'),
			...Array(3).fill('starboard'),
		];
	}

	return [
		...Array(3).fill('port'),
		...Array(3).fill('port-center'),
		...Array(total - 12).fill('center'),
		...Array(3).fill('starboard-center'),
		...Array(3).fill('starboard'),
	];
}
