import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

import { AuthProviderStorage } from 'main/auth/provider/Storage';
import { VppnProvider } from 'main/auth/provider/Vppn';

import { baseConfig } from './config';
import { IBaseHeader, getAuthorizationHeader } from './headers/BaseHeader';
import {
	mapDeleteRequestToConfig,
	mapGetRequestToConfig,
	mapHeadRequestToConfig,
	mapPostRequestToConfig,
	mapPutRequestToConfig,
	mapUploadRequestToConfig,
} from './helpers/RequestToConfigMapper';
import { IRequest } from './requests/BaseRequest';
import { DeleteRequest, IDeleteRequest } from './requests/DeleteRequest';
import { GetRequest, IGetRequest } from './requests/GetRequest';
import { HeadRequest, IHeadRequest } from './requests/HeadRequest';
import { IPostRequest, PostRequest } from './requests/PostRequest';
import { IPutRequest, PutRequest } from './requests/PutRequest';
import { IUploadRequest, UploadRequest } from './requests/UploadRequest';
import { authRequestInterceptor } from './requests/interceptors/authRequestInterceptor';
import { IBaseResponse } from './responses/BaseResponse';
import { FileResponse } from './responses/FileResponse';
import { authErrorResponseInterceptor } from './responses/interceptors/AuthErrorResponseInterceptor';
import { successResponseInterceptor } from './responses/interceptors/SuccessResponseInterceptor';

export abstract class ApiClient {
	private readonly client: AxiosInstance;

	constructor() {
		this.client = axios.create(baseConfig);
		this.client.interceptors.request.use(authRequestInterceptor);
		this.client.interceptors.response.use(
			successResponseInterceptor,
			authErrorResponseInterceptor
		);
	}

	protected addAuthorizationHeader(
		accessToken: string,
		headers?: IBaseHeader
	): IBaseHeader {
		const auth = getAuthorizationHeader(accessToken);

		return headers ? { ...headers, ...auth } : auth;
	}

	protected async requestAsync<TRequest, TResponse>(
		request: IRequest,
		requestMapper: (request: IRequest) => AxiosRequestConfig
	): Promise<IBaseResponse<TResponse>> {
		const accessToken = await AuthProviderStorage.getInstance()
			.getProvider()
			.getTokenAsync();
		if (!accessToken) {
			throw new Error('Access token is empty.');
		}

		request.headers = this.addAuthorizationHeader(
			accessToken,
			request.headers
		);

		return this.client.request<TRequest, IBaseResponse<TResponse>>({
			...requestMapper(request),
			...request.config,
		});
	}

	protected async getAsync<TResponse>(
		getRequest: IGetRequest
	): Promise<IBaseResponse<TResponse>> {
		const baseGetRequest = new GetRequest(getRequest);

		return this.requestAsync<IGetRequest, TResponse>(
			baseGetRequest,
			mapGetRequestToConfig
		);
	}

	protected headAsync<TResponse>(
		headRequest: IHeadRequest
	): Promise<IBaseResponse<TResponse>> {
		const baseHeadRequest = new HeadRequest(headRequest);

		return this.requestAsync<IHeadRequest, TResponse>(
			baseHeadRequest,
			mapHeadRequestToConfig
		);
	}

	protected async postAsync<TResponse>(
		postRequest: IPostRequest
	): Promise<IBaseResponse<TResponse>> {
		const basePostRequest = new PostRequest(postRequest);

		return this.requestAsync<IPostRequest, TResponse>(
			basePostRequest,
			mapPostRequestToConfig
		);
	}

	protected async putAsync(
		putRequest: IPutRequest
	): Promise<IBaseResponse<void>> {
		const basePutRequest = new PutRequest(putRequest);

		return this.requestAsync<IPutRequest, void>(
			basePutRequest,
			mapPutRequestToConfig
		);
	}

	protected async deleteAsync<TResponse>(
		deleteRequest: IDeleteRequest
	): Promise<IBaseResponse<TResponse>> {
		const baseDeleteRequest = new DeleteRequest(deleteRequest);

		return this.requestAsync<IDeleteRequest, TResponse>(
			baseDeleteRequest,
			mapDeleteRequestToConfig
		);
	}

	protected async downloadAsync(
		downloadRequest: IGetRequest
	): Promise<FileResponse> {
		const baseGetRequest = new GetRequest(downloadRequest);

		return this.requestAsync<IGetRequest, Blob>(
			baseGetRequest,
			mapGetRequestToConfig
		);
	}

	protected async uploadAsync<TResponse>(
		uploadRequest: IUploadRequest
	): Promise<IBaseResponse<TResponse>> {
		const baseUploadRequest = new UploadRequest(uploadRequest);

		return this.requestAsync<IUploadRequest, TResponse>(
			baseUploadRequest,
			mapUploadRequestToConfig
		);
	}

	protected isVppn(): boolean {
		return (
			AuthProviderStorage.getInstance().getProvider() instanceof
			VppnProvider
		);
	}
}
