import { FC, PropsWithChildren } from 'react';

import { Spinner, SpinnerProps } from './Spinner';

export interface LoadingWrapperProps extends PropsWithChildren, SpinnerProps {
	loading: boolean;
}

export const LoadingWrapper: FC<LoadingWrapperProps> = ({
	loading,
	children,
	...rest
}: PropsWithChildren<{ loading: boolean }>): JSX.Element =>
	loading ? <Spinner {...rest} /> : <>{children}</>;
