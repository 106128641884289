import { SxProps } from '@mui/material';
import { HTMLAttributeAnchorTarget, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';

import { Link } from 'library/components/link';
import { Removable, Sxable } from 'library/types';
import { PCTheme, textOverflowSx } from 'library/utils/themes';
import { drivelineviewPageUrl } from 'main/routes/urls/RouteUrls';

const {
	palette: {
		grey,
		secondary: { main },
	},
} = PCTheme;

interface DrivelineTitleLinkProps extends Removable, Sxable {
	chassisId: string;
	drivelineId: number | null;
	isActive?: boolean;
	target?: HTMLAttributeAnchorTarget;
	withTitle?: boolean;
}

export const DrivelineLink = ({
	children,
	id,
	target = '_self',
	...rest
}: PropsWithChildren<
	{
		className?: string;
		id: number;
		target?: HTMLAttributeAnchorTarget;
	} & Sxable
>) => (
	<Link
		target={target}
		to={generatePath(drivelineviewPageUrl, {
			id: id.toString(),
		})}
		{...rest}>
		{children}
	</Link>
);

export const defaultDrivelineTitleLinkSx: SxProps = {
	textDecoration: 'none',
	'&:hover': { textDecoration: 'underline' },
	...textOverflowSx,
};

export const DrivelineTitleLink = ({
	chassisId,
	drivelineId,
	isActive = true,
	isRemoved,
	sx,
	target,
	withTitle = true,
}: DrivelineTitleLinkProps): JSX.Element => {
	const { t } = useTranslation('common');

	return (
		<>
			{withTitle && `${t('driveline')} `}
			{drivelineId && isActive ? (
				<DrivelineLink
					id={drivelineId}
					sx={{ color: isRemoved ? grey[700] : main, ...sx }}
					target={target}>
					{chassisId}
				</DrivelineLink>
			) : (
				<span>{chassisId}</span>
			)}
		</>
	);
};
