import { Box, styled } from '@mui/material';

export const PageContent = styled(Box)({
	flex: '1 1 auto',
	overflowX: 'auto',
});

export const PageContentTab = styled(Box)({
	flex: '1 1 auto',
	overflowX: 'auto',
	minHeight: 300,
});
