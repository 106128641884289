import { Box, Paper, PaperProps, Typography } from '@mui/material';
import { PropsWithChildren, ReactNode } from 'react';

export const IdentifiersContainer = ({
	title,
	subtitle,
	isValidSection,
	showAsterisk,
	variant = 'flatGrey',
	children,
}: PropsWithChildren<{
	title: string;
	subtitle?: ReactNode;
	isValidSection?: boolean;
	showAsterisk?: boolean;
	variant?: PaperProps['variant'];
}>): JSX.Element => (
	<Paper
		variant={variant}
		sx={[
			{
				display: 'grid',
				gridTemplateColumns: {
					xs: 'repeat(1, 1fr)',
					sm: 'repeat(2, 1fr)',
					md: 'repeat(4, 1fr)',
				},
				gap: 2,
				p: 2,
			},
			isValidSection != null
				? {
						border: '1px solid',
						borderColor: isValidSection
							? 'background.default'
							: 'error.main',
				  }
				: null,
		]}
	>
		<Box gridColumn={{ sm: 'span 2', md: '1' }} gridRow="1/3">
			<Typography variant="h5" pb={1}>
				{title}
				{showAsterisk && (
					<Typography component="span" color={'red'}>
						&nbsp;{'*'}
					</Typography>
				)}
			</Typography>

			{subtitle}
		</Box>
		{children}
	</Paper>
);
