import { SvgIcon, SvgIconProps } from '@mui/material';

export const TxtIcon = (props: SvgIconProps) => (
	<SvgIcon {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M4 2.5C4 2.22386 4.22386 2 4.5 2H12.5C12.6326 2 12.7598 2.05268 12.8536 2.14645L19.8536 9.14645C19.9473 9.24021 20 9.36739 20 9.5V21.5C20 21.7761 19.7761 22 19.5 22H4.5C4.22386 22 4 21.7761 4 21.5V2.5ZM5 3V21H19V10H12.5C12.2239 10 12 9.77614 12 9.5V3H5ZM13 3.70711L18.2929 9H13V3.70711ZM8.5 12.0071C8.22386 12.0071 8 12.2309 8 12.5071C8 12.7832 8.22386 13.0071 8.5 13.0071H15.5C15.7761 13.0071 16 12.7832 16 12.5071C16 12.2309 15.7761 12.0071 15.5 12.0071H8.5ZM8.5 15.0046C8.22386 15.0046 8 15.2284 8 15.5046C8 15.7807 8.22386 16.0046 8.5 16.0046H15.5C15.7761 16.0046 16 15.7807 16 15.5046C16 15.2284 15.7761 15.0046 15.5 15.0046H8.5ZM8 18.5025C8 18.2263 8.22386 18.0025 8.5 18.0025H15.5C15.7761 18.0025 16 18.2263 16 18.5025C16 18.7786 15.7761 19.0025 15.5 19.0025H8.5C8.22386 19.0025 8 18.7786 8 18.5025Z"
		/>
	</SvgIcon>
);
