import { Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Outlet, useLocation } from 'react-router-dom';

import { redirectToErrorPage } from 'api/axios/responses/interceptors/AuthErrorResponseInterceptor';
import { Spinner } from 'library/components/controls';
import { getUserData, setHistory } from 'library/redux/application';
import { useAppDispatch } from 'library/redux/hooks';
import { SnackbarWrapper } from 'library/snackbar';
import {
	AuthProviderStorage,
	AuthenticationProvider,
	initializeAuthProviderStorage,
} from 'main/auth/provider';
import { ErrorFallback } from 'main/errors/ErrorFallback';
import Layout from 'main/layout/Layout';
import { errors_AuthenticationUrl } from 'main/routes/urls/RouteUrls';

const handleError = (
	message?: string,
	redirectPage = errors_AuthenticationUrl
) => {
	if (message) {
		console.error(message);
	}
	redirectToErrorPage(redirectPage);
};

const RootAuthentication = () => {
	const dispatch = useAppDispatch();
	const location = useLocation();
	const [authProvider, setAuthProvider] = useState<AuthenticationProvider>();
	const [isAuthenticated, setIsAuthenticated] = useState(false);

	const onAuthenticated = useCallback(
		(isAuth: boolean, message?: string) => {
			setIsAuthenticated(isAuth);
			isAuth
				? (async () => {
						try {
							await dispatch(getUserData()).unwrap();
						} catch (ex) {
							console.error(ex);
						}
					})()
				: handleError(message);
		},
		[dispatch]
	);

	useEffect(() => {
		(async () => {
			await initializeAuthProviderStorage();
			const p = AuthProviderStorage.getInstance().getProvider();
			p.onAuthenticated(onAuthenticated);
			setAuthProvider(p);
		})();
	}, [onAuthenticated]);

	useEffect(() => {
		dispatch(setHistory(location));
	}, [dispatch, location]);

	const AuthProvider = useMemo(
		() =>
			authProvider
				? authProvider.getComponent()
				: AuthProviderStorage.getDefaultProviderComponent(),
		[authProvider]
	);

	return (
		<Layout isMenu={isAuthenticated}>
			<ErrorBoundary FallbackComponent={ErrorFallback}>
				<AuthProvider>
					{isAuthenticated && (
						<SnackbarWrapper>
							<Suspense fallback={<Spinner />}>
								<Outlet />
							</Suspense>
						</SnackbarWrapper>
					)}
				</AuthProvider>
			</ErrorBoundary>
		</Layout>
	);
};

export default RootAuthentication;
