import { FileUploadState } from 'library/redux/fileUpload';
import { OperatingLocationFormType } from 'modules/Shared/Components/OperatingLocation/OperatingLocationForm';
import { RootState } from 'store';

import { CommissioningDataStateType } from './Types';

export const selectUnitId = (state: RootState): number =>
	state.commissioning.unitId;

export const selectOperatingLocation = (
	state: RootState
): OperatingLocationFormType | null => state.commissioning.operatingLocation;

export const selectCommissioningFormData = (
	state: RootState
): CommissioningDataStateType | null =>
	state.commissioning.commissioningFormData;

export const selectCommissioningFiles = (state: RootState): FileUploadState =>
	state.commissioningFileState;
