import { Card } from '@mui/material';
import { styled } from '@mui/material/styles';

export const UnitCreationCard = styled(Card)(({ theme }) => ({
	minWidth: 310,
	padding: theme.spacing(1),
	boxSizing: 'border-box',

	'& .MuiCardHeader-root': {
		padding: 0,
	},
	'& .MuiCardContent-root': {
		padding: 0,
	},
	'& .MuiCardContent-root:last-child': {
		paddingBottom: theme.spacing(0.5),
	},
	'& .MuiCardHeader-action': {
		marginRight: theme.spacing(-0.5),
	},
}));
