import { TextField, TextFieldProps } from '@mui/material';
import { FocusEvent } from 'react';
import {
	FieldPath,
	FieldValues,
	UseControllerProps,
	useController,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { displayError, getHelperText } from './functions';

export type TextFieldElementProps<
	TFieldValues extends FieldValues = FieldValues,
	TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = Pick<UseControllerProps<TFieldValues, TName>, 'name' | 'control'> &
	Omit<TextFieldProps, 'name' | 'value' | 'onChange' | 'ref' | 'error'> & {
		validation?: UseControllerProps<TFieldValues, TName>['rules'];
	};

export const ControlledTextField = <
	TFieldValues extends FieldValues = FieldValues,
	TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
	control,
	name,
	validation = {},
	required,
	disabled,
	type,
	onBlur: onTextFieldBlur,
	helperText,
	...rest
}: TextFieldElementProps<TFieldValues, TName>): JSX.Element => {
	const { t } = useTranslation();

	if (Boolean(validation.required) && !required) {
		required = true;
	}

	const {
		field: { onChange, onBlur, value, ref },
		fieldState: { error },
	} = useController<TFieldValues, TName>({
		name,
		control,
		rules: validation,
	});

	if (type === 'number' && typeof value !== 'undefined') {
		rest.InputLabelProps = rest.InputLabelProps ?? {};
		rest.InputLabelProps.shrink = true;
	}

	const handleBlur = (
		event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		onBlur();
		typeof onTextFieldBlur === 'function' && onTextFieldBlur(event);
	};

	return (
		<TextField
			name={name}
			value={value ?? ''}
			onChange={onChange}
			onBlur={handleBlur}
			inputRef={ref}
			error={displayError(disabled, error)}
			helperText={getHelperText(t, disabled, error, helperText)}
			required={required}
			disabled={disabled}
			type={type}
			{...rest}
		/>
	);
};
