import { ApplicationApi } from 'api';
import { infinityCacheTime } from 'api/ReactQueryOptions';
import { mapToAreas } from 'domain/user/User';
import { Area } from 'library/models/Area';

export const securityMatrixQuery = (api: ApplicationApi) => ({
	queryKey: userKeys.securityMatrix(),
	queryFn: async () => {
		const response = await api.getSecurityMatrix();
		return mapToAreas(response.data);
	},
	...infinityCacheTime<Area[]>(),
});

export const getSupportedLanguagesQuery = (api: ApplicationApi) => ({
	queryKey: userKeys.languages(),
	queryFn: async () => {
		const response = await api.getSupportedLanguageList();
		return response.data;
	},
	...infinityCacheTime<Record<string, string>>(),
});

export const userKeys = {
	all: ['user'] as const,
	data: () => [...userKeys.all, 'data'] as const,
	securityMatrix: () => [...userKeys.all, 'securityMatrix'] as const,
	menu: () => [...userKeys.all, 'menu'] as const,
	languages: () => [...userKeys.all, 'languages'] as const,
};
