import { baseConfig } from './axios/config';
import { authRequestInterceptor } from './axios/requests/interceptors/authRequestInterceptor';
import { authErrorResponseInterceptor } from './axios/responses/interceptors/AuthErrorResponseInterceptor';
import { successResponseInterceptor } from './axios/responses/interceptors/SuccessResponseInterceptor';
import { V1 } from './v1/V1';
import { V2 } from './v2/V2';

// obsolete class, could be deleted, but the type is used in many places
export class ApplicationApi extends V1 {
	constructor() {
		super(baseConfig);
	}
}

/**
 * Instance of product center api class. Please use only outside of React, for example in Redux. For React use, look at @type {useProductCenterApi}
 * @returns {ApplicationApi}
 */
export const productCenterApi = new ApplicationApi();

productCenterApi.instance.interceptors.request.use(authRequestInterceptor);
productCenterApi.instance.interceptors.response.use(
	successResponseInterceptor,
	authErrorResponseInterceptor
);

/**
 * Instance of product center api class. Please use only outside of React, for example in Redux. For React use, look at @type {useProductCenterApi}
 * @returns {ApplicationApi}
 */
export const productCenterApiV2 = new V2(baseConfig);

productCenterApiV2.instance.interceptors.request.use(authRequestInterceptor);
productCenterApiV2.instance.interceptors.response.use(
	successResponseInterceptor,
	authErrorResponseInterceptor
);

type UseProductCenterApiReturnType = {
	api: V1;
	apiV2: V2;
};

/**
 * Hook that returns instance of the ProductCenter api
 * @returns {UseProductCenterApiReturnType}
 */
export const useProductCenterApi = (): UseProductCenterApiReturnType => ({
	api: productCenterApi,
	apiV2: productCenterApiV2,
});
