import { AuthenticationProvider } from './Provider';

export class AuthProviderStorage {
	private static instance: AuthProviderStorage;

	protected provider: AuthenticationProvider;

	constructor(defaultProvider: AuthenticationProvider) {
		this.provider = defaultProvider;
	}

	renew(provider: AuthenticationProvider) {
		this.provider = provider;
	}

	getProvider() {
		return this.provider;
	}

	static getDefaultProviderComponent() {
		return AuthenticationProvider.getDefaultComponent();
	}

	static getInstance() {
		return AuthProviderStorage.instance;
	}

	static initialize(provider: AuthenticationProvider) {
		if (!this.instance) {
			this.instance = new AuthProviderStorage(provider);
		}
	}
}
