import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { IconButton, Tooltip } from '@mui/material';
import { GridColDef, GridRowParams } from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';
import { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { DocumentDto, DocumentVisibility, useProductCenterApi } from 'api';
import { getUrlDocumentDownloadLink } from 'api/ApiUrls';
import { useProductIndividualDocumentsQuery } from 'api/queries';
import {
	FileIcon,
	SectionTitle,
	getFileType,
} from 'library/components/controls';
import { DataGridToolbar } from 'library/components/controls/datagrid/DataGridToolbar';
import { VolvoDataGrid } from 'library/components/controls/datagrid/VolvoDataGrid';
import { getLocalizedDateFormat } from 'library/formatters';
import { useAppSelector } from 'library/redux/hooks';
import { onDownloadLinkClick } from 'library/utils/helpers/FileHelper';
import {
	AuthProviderStorage,
	AuthenticationProvider,
} from 'main/auth/provider';
import { reactPlugin } from 'main/azureInsights/config';
import { selectProductIndividualSerialNumber } from 'modules/ProductIndividualview/State';

const DocumentsSectionPage = () => {
	const { t } = useTranslation(['productIndividual', 'commonButton']);
	const provider = useRef<AuthenticationProvider>(
		AuthProviderStorage.getInstance().getProvider()
	);

	const serialNumber = useAppSelector(selectProductIndividualSerialNumber);

	const { api } = useProductCenterApi();
	const { data, isLoading } = useQuery({
		...useProductIndividualDocumentsQuery(api, serialNumber),
		enabled: !!serialNumber,
	});

	const visibilityMap = useMemo(
		(): { value: DocumentVisibility; label: string }[] => [
			{
				value: 'Public',
				label: t('productIndividual:document-visibility-label-public'),
			},
			{
				value: 'Internal',
				label: t(
					'productIndividual:document-visibility-label-internal'
				),
			},
		],
		[t]
	);

	const columns = useMemo(
		(): GridColDef<DocumentDto>[] => [
			{
				field: 'documentId',
				headerName: t('productIndividual:documents-header-id'),
				minWidth: 160,
				flex: 3,
			},
			{
				field: 'documentIssueDate',
				type: 'date',
				headerName: t('productIndividual:documents-header-issue-date'),
				minWidth: 120,
				flex: 2,
				valueFormatter: (value) => getLocalizedDateFormat(value),
			},
			{
				field: 'documentTypeText',
				headerName: t('productIndividual:documents-header-type'),
				minWidth: 120,
				flex: 3,
			},
			{
				field: 'userFullName',
				headerName: t(
					'productIndividual:documents-header-submitted-by'
				),
				minWidth: 150,
				flex: 3,
			},
			{
				field: 'timestamp',
				type: 'date',
				headerName: t(
					'productIndividual:documents-header-uploaded-time'
				),
				minWidth: 160,
				flex: 2,
				valueFormatter: (value) => getLocalizedDateFormat(value),
			},
			{
				field: 'visibility',
				headerName: t('productIndividual:documents-header-visibility'),
				minWidth: 100,
				flex: 2,
				valueGetter: (value) =>
					visibilityMap.find((v) => v.value === value)?.label ??
					value,
			},
			{
				field: 'actions',
				type: 'actions',
				align: 'right',
				width: 60,
				cellClassName: 'service-protocol-grid__actions',
				getActions: ({ row }: GridRowParams<DocumentDto>) => [
					<Tooltip
						key="download"
						title={t('commonButton:download-label')}>
						<IconButton
							color="primary"
							onClick={onDownloadLinkClick(
								provider.current,
								getUrlDocumentDownloadLink(row.id),
								row.downloadFileName ?? 'filename'
							)}>
							<FileIcon
								mimeType={
									row.downloadFileName
										? getFileType(row.downloadFileName)
										: ''
								}
							/>
						</IconButton>
					</Tooltip>,
				],
			},
		],
		[t]
	);

	return (
		<>
			<SectionTitle title={t('productIndividual:documents-title')} />
			<VolvoDataGrid
				columns={columns}
				rows={data}
				isLoading={isLoading}
				slots={{
					toolbar: DataGridToolbar,
				}}
			/>
		</>
	);
};

export default withAITracking(
	reactPlugin,
	DocumentsSectionPage,
	'ProductIndividualDocumentsSectionPage',
	'application-insight-container'
);
