import { GetServiceProtocolPdfQuery } from 'api';

export interface PrintProtocolFormType {
	additionalText?: string;
	printQr: boolean;
	printEstimatedHours: boolean;
	printUsedHours: boolean;
	printConfiguration: boolean;
	printEndCustomer: boolean;
	printCampaigns: boolean;
	printCoverage: boolean;
	printProposals: boolean;
	printServiceActivitiesPhotos: boolean;
	printAdditionalRepairs: boolean;
	printAdditionalPhotos: boolean;
	printSignatures: boolean;
}

export const mapFormToRequest = (
	protocolId: number,
	form: PrintProtocolFormType,
	isVppn: boolean,
	isOffline: boolean,
	isDealer: boolean
): GetServiceProtocolPdfQuery => ({
	id: protocolId,
	isVppnRequest: isVppn,
	additionalText: form.additionalText,
	protocolGenerationClientDate: new Date(),
	printQr: isDealer ? form.printQr : false,
	printEstimatedHours: isOffline ? false : form.printEstimatedHours,
	printUsedHours: isOffline ? false : form.printUsedHours,
	printConfiguration: form.printConfiguration,
	printEndCustomer: form.printEndCustomer,
	printCampaigns: form.printCampaigns,
	printCoverage: form.printCoverage,
	pritntProposals: isOffline ? false : form.printProposals,
	printActivitiesPhotos: form.printServiceActivitiesPhotos,
	printAdditionalRepairs: form.printAdditionalRepairs,
	printAdditionalRepairPhotos: form.printAdditionalPhotos,
	printSignaures: form.printSignatures,
});
