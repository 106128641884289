import Alert from '@mui/material/Alert';

interface DeployAlertNotificationAlertProps {
	notification: string;
}

export const DeployAlertNotificationAlert = ({
	notification,
}: DeployAlertNotificationAlertProps) =>
	notification ? (
		<Alert
			severity="error"
			sx={{ alignItems: 'center', pt: 1, pb: 1, pl: 2, pr: 2 }}>
			{notification}
		</Alert>
	) : (
		<></>
	);
