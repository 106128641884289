import { Add } from '@mui/icons-material';
import {
	Box,
	Paper,
	Radio,
	RadioProps,
	Typography,
	styled,
	useTheme,
} from '@mui/material';
import { noop } from 'lodash';
import { useSnackbar } from 'notistack';
import {
	forwardRef,
	useCallback,
	useImperativeHandle,
	useRef,
	useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { UnitOtherPartDto } from 'api';
import {
	CustomInputWithButtonHandle,
	Input,
	SearchBox,
} from 'library/components/controls';
import { useAppDispatch } from 'library/redux/hooks';
import { useCheckConsoleDebuggingReadAccess } from 'main/auth/checkUserAccess';
import { addOtherProduct } from 'modules/UnitCreation/State';

type OtherPartsSearchBoxProps = {
	isValid?: (partNumber: string, serialNumber?: string) => boolean;
	onAddOtherProduct?: (data: UnitOtherPartDto | null) => void;
};

export type OtherPartsSearchBoxHandle = {
	resetError: () => void;
};

const StyledRadio = styled(Radio)<RadioProps>(
	({
		theme: {
			palette: {
				primary: { main: primMain },
				secondary: { main },
			},
		},
	}) => ({
		color: primMain,
		'&.Mui-checked': {
			color: main,
		},
	})
);

export const OtherPartsSearchBox = forwardRef<
	OtherPartsSearchBoxHandle,
	OtherPartsSearchBoxProps
>(({ isValid = () => true, onAddOtherProduct = noop }, ref): JSX.Element => {
	const { t } = useTranslation(['common', 'unitCreation']);
	const dispatch = useAppDispatch();
	const serial = useRef('');
	const isSerialRef = useRef(false);
	const inputWithButtonRef = useRef<CustomInputWithButtonHandle>(null);
	const { enqueueSnackbar } = useSnackbar();
	const [isSerial, setIsSerial] = useState(false);
	const [isSerialError, setIsSerialError] = useState(false);
	const {
		palette: {
			error: { main },
		},
		spacing,
	} = useTheme();

	useImperativeHandle(
		ref,
		() => ({
			resetError: inputWithButtonRef.current?.resetError ?? noop,
		}),
		[inputWithButtonRef]
	);

	const showErrorSnackbar = (message: string) =>
		enqueueSnackbar(message, {
			variant: 'error',
		});
	const { checkConsoleDebuggingReadAccess } =
		useCheckConsoleDebuggingReadAccess();

	const handleAddOtherProduct = async (partNumber: string) => {
		if (isSerialRef.current && !serial.current) {
			setIsSerialError(true);
			return Promise.reject();
		}

		const trimmedUpperCasePartNumber = partNumber
			.trim()
			.toLocaleUpperCase();
		if (!isValid(trimmedUpperCasePartNumber, serial.current)) {
			return false;
		}

		const data = await dispatch(
			addOtherProduct({
				partNumber: trimmedUpperCasePartNumber,
				serialNumber: serial.current,
			})
		)
			.unwrap()
			.then((data) => {
				setIsSerial(false);
				isSerialRef.current = false;
				serial.current = '';

				return data;
			})
			.catch(({ response }) => {
				if (!response) {
					return;
				}

				if (typeof response === 'string' || response.status === 404) {
					showErrorSnackbar(
						t('unitCreation:other-parts-product-not-found-message')
					);
				} else if (checkConsoleDebuggingReadAccess()) {
					console.error(response);
				}
			});

		onAddOtherProduct(data);
		return !!data;
	};

	const handleSerialRadioOnChange = useCallback(() => {
		setIsSerial((current) => {
			isSerialRef.current = !current;
			if (current) {
				serial.current = '';
				setIsSerialError(false);
			}
			return !current;
		});

		inputWithButtonRef.current?.resetError();
	}, []);

	return (
		<Box
			gridArea="addProducts"
			component={Paper}
			variant="flatGrey"
			height="min-content">
			<SearchBox
				icon={<Add />}
				alternative
				title={t('unitCreation:other-parts-input-title')}
				subtitle={t('unitCreation:other-parts-input-subtitle')}
				label={t('unitCreation:other-parts-part-number')}
				onButtonPressed={handleAddOtherProduct}
				displayLoadingIndicatorDuringActionExecution
				ref={inputWithButtonRef}
			/>
			<Box display="flex" alignItems="center" pl={spacing(2)}>
				<StyledRadio
					onClick={handleSerialRadioOnChange}
					checked={isSerial}
					value={isSerial}
				/>
				<Typography>
					{t('unitCreation:other-parts-serial-number-checkbox')}
				</Typography>
			</Box>
			{isSerial && (
				<Box p={2}>
					<Input
						isError={isSerialError}
						label={t('common:serial-number')}
						onChange={(value) => {
							if (isSerialError && value) {
								setIsSerialError(false);
							}
							inputWithButtonRef.current?.resetError();
							serial.current = value;
						}}
					/>
					{isSerialError && (
						<Typography
							pl={spacing(2)}
							variant="caption"
							color={main}>
							{t(
								'unitCreation:other-parts-serial-number-checkbox-error'
							)}
						</Typography>
					)}
				</Box>
			)}
		</Box>
	);
});
