import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Theme,
	useMediaQuery,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { EndCustomerDto } from 'api/responses/models/AddEndCustomer';
import { PageButtons } from 'library/components/controls';

import { Customer } from '../State';
import { NewCustomerForm } from './NewCustomerForm';
import { NewCustomerFormLegacy } from './NewCustomerFormLegacy';
import { useCustomerFormSubmit } from './useCustomerFormSubmit';

export const EditCustomerDialog = ({
	customerToEdit,
	onClose,
}: {
	customerToEdit: EndCustomerDto;
	onClose: (customer: EndCustomerDto | null) => void;
}): JSX.Element => {
	const { t } = useTranslation(['customer', 'commonButton']);
	const { enqueueSnackbar } = useSnackbar();

	const fullScreen = useMediaQuery((theme: Theme) =>
		theme.breakpoints.only('xs')
	);

	const { handleSubmit, setValue, setError, watch, control } =
		useForm<Customer>({
			mode: 'onBlur',
			defaultValues: customerToEdit ?? undefined,
		});

	const { handleFormSubmit, handleFormInvalid, customerMutation } =
		useCustomerFormSubmit({
			onSuccess: (data) => {
				enqueueSnackbar(
					t('customer:customer-edit-successful-message'),
					{ variant: 'success' }
				);
				onClose(data);
			},
			setError,
		});

	const handleClose = () => {
		onClose(null);
	};

	return (
		<Dialog
			fullScreen={fullScreen}
			fullWidth
			maxWidth={false}
			open={customerToEdit != null}
			onClose={handleClose}>
			<form
				onSubmit={handleSubmit(handleFormSubmit, handleFormInvalid)}
				noValidate>
				<DialogTitle>{t('customer:edit-customer')}</DialogTitle>
				<DialogContent>
					{import.meta.env.VITE_LEGACY_END_CUSTOMER === 'true' ? (
						<NewCustomerFormLegacy
							control={control}
							setValue={setValue}
							watch={watch}
						/>
					) : (
						<NewCustomerForm
							control={control}
							setValue={setValue}
							watch={watch}
						/>
					)}
				</DialogContent>
				<DialogActions>
					<PageButtons
						primaryButtonLabel={t('commonButton:save')}
						secondaryButtonLabel={t('commonButton:cancel')}
						secondaryButtonAction={handleClose}
						isPrimaryButtonSubmitType={true}
						displayLoadingIndicator={customerMutation.isPending}
					/>
				</DialogActions>
			</form>
		</Dialog>
	);
};
