import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { ProductIndividualviewState } from './Types';

const initialState: ProductIndividualviewState = {
	id: 0,
	serialNumber: '',
};

export const productindividualviewSlice = createSlice({
	name: 'productindividualview',
	initialState,
	reducers: {
		setProductIndividualId: (state, { payload }: PayloadAction<number>) => {
			if (!isNaN(payload) && payload >= 0) {
				state.id = payload;
			}
		},
		setProductIndividualSerialNumber: (
			state,
			{ payload }: PayloadAction<string>
		) => {
			if (payload) {
				state.serialNumber = payload;
			}
		},
	},
});

export const { setProductIndividualId, setProductIndividualSerialNumber } =
	productindividualviewSlice.actions;

export default productindividualviewSlice.reducer;
