import { useContext } from 'react';

import { isDevEnv } from 'library/constants';

import AccordionContext from './AccordionContext';
import { AccordionContextProps } from './types';

export const useAccordion = (): AccordionContextProps => {
	const context = useContext(AccordionContext);

	if (!context && isDevEnv) {
		throw Error('use only in wizard context');
	} else {
		return context as AccordionContextProps;
	}
};
