import {
	Route,
	createBrowserRouter,
	createRoutesFromElements,
} from 'react-router-dom';

import RootAuthentication from 'main/auth/RootAuthentication';
import HomePage from 'pages/home/HomePage';
import { NotFoundPage } from 'pages/notfound/NotFoundPage';

import { getDevelopersRoutes } from './getDevelopersRoutes';
import { getDrivelineviewRoutes } from './getDrivelineviewRoutes';
import { getErrorRoutes } from './getErrorRoutes';
import { getMaintenancePlannerRoutes } from './getMaintenancePlannerRoutes';
import { getOrderInfoRoutes } from './getOrderInfoRoutes';
import { getProductIndividualRoutes } from './getProductIndividualRoutes';
import { getRegisterRoutes } from './getRegisterRoutes';
import { getSearchRoutes } from './getSearchRoutes';
import { getServiceProtocolRoutes } from './getServiceProtocolRoutes';
import { getUnitOverviewRoutes } from './getUnitOverviewRoutes';
import { getUnitRoutes } from './getUnitRoutes';
import {
	drivelineviewParam,
	orderInfoPageUrl,
	productIndividualviewAreaUrl,
	serviceProtocolArea,
	unitRelativeUrl,
} from './urls/RouteUrls';

export const getRouter = () =>
	createBrowserRouter(
		createRoutesFromElements([
			<Route key="root" element={<RootAuthentication />}>
				<Route index element={<HomePage />} />
				<Route path="search">{getSearchRoutes()}</Route>
				<Route path="register">{getRegisterRoutes()}</Route>
				<Route path="developers">{getDevelopersRoutes()}</Route>
				<Route path={`unitoverview/${unitRelativeUrl}`}>
					{getUnitOverviewRoutes()}
				</Route>
				<Route path={`drivelineview/${drivelineviewParam}`}>
					{getDrivelineviewRoutes()}
				</Route>
				<Route path={productIndividualviewAreaUrl}>
					{getProductIndividualRoutes()}
				</Route>
				<Route path="unit">{getUnitRoutes()}</Route>
				<Route path="MaintenancePlanner">
					{getMaintenancePlannerRoutes()}
				</Route>
				<Route path={orderInfoPageUrl}>{getOrderInfoRoutes()}</Route>
				<Route path={serviceProtocolArea}>
					{getServiceProtocolRoutes()}
				</Route>
				<Route path="errors">{getErrorRoutes()}</Route>
				<Route path="notfound" element={<NotFoundPage />} />
				<Route path="*" element={<NotFoundPage />} />
			</Route>,
		]),
		{
			basename: import.meta.env.BASE_URL,
		}
	);
