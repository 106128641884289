import React from 'react';

import { isDevEnv } from 'library/constants';

import { AccordionContextProps } from './types';

const AccordionContext = React.createContext<AccordionContextProps | null>(
	null
);

if (isDevEnv) {
	AccordionContext.displayName = 'AccordionContext';
}

export default AccordionContext;
