import { useTheme } from '@mui/material';

import Variables from 'library/utils/themes/Variables';

export const useLayoutStyle = () => {
	const {
		custom: {
			bodyContainerPaddingY,
			footerHeight,
			headerHeight,
			navigationHeight,
		},
	} = useTheme<Variables>();

	return {
		bodyContainerPaddingY,
		minHeight: {
			xs: `calc(100vh - ${headerHeight} - 2*${bodyContainerPaddingY})`,
			md: `calc(100vh - ${headerHeight} - ${navigationHeight} - 2*${bodyContainerPaddingY} - ${footerHeight})`,
		},
	};
};
