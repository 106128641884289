import { Box, Typography } from '@mui/material';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { CircularWithValueLabel } from 'library/components';
import { home_HomeUrl } from 'main/routes/urls/RouteUrls';

const REDIRECT_TIMEOUT = 30;
const enableRedirect = import.meta.env.VITE_DISABLE_ERROR_REDIRECT != 'true';

export const RedirectCounter = () => {
	const { t } = useTranslation(['common', 'error']);

	const handleOnElapsed = useCallback(() => {
		if (enableRedirect) {
			window.location.href = home_HomeUrl;
		}
	}, []);

	return (
		enableRedirect && (
			<Box display="flex" justifyContent="center" marginTop={1}>
				<Typography variant="body1" marginRight={1}>
					{t('error:redirect-message')}
				</Typography>

				<CircularWithValueLabel
					end={0}
					onElapsed={handleOnElapsed}
					start={REDIRECT_TIMEOUT}
					unit={t('common:second-shortcut')}
				/>
			</Box>
		)
	);
};
