import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { COMMISSIONING_NAME } from 'domain/constants';
import { OperatingLocationFormType } from 'modules/Shared/Components/OperatingLocation/OperatingLocationForm';

import { TravelExpensesFormType } from '../Components/TravelExpensesForm';
import { CommissioningDataStateType, CommissioningState } from './Types';

const initialState: CommissioningState = {
	unitId: 0,
	travelExpenses: null,
	operatingLocation: null,
	commissioningFormData: null,
};

export const productInOperationSlice = createSlice({
	name: COMMISSIONING_NAME,
	initialState,
	reducers: {
		initializeState: (state, { payload }: PayloadAction<number>) => {
			if (!isNaN(payload) && payload >= 0) {
				// reset state
				return { ...initialState, unitId: payload };
			}

			return state;
		},
		operatingLocationChanged: (
			state,
			{ payload }: PayloadAction<OperatingLocationFormType>
		) => {
			state.operatingLocation = payload;
		},
		commissioningFormDataChanged: (
			state,
			{ payload }: PayloadAction<CommissioningDataStateType>
		) => {
			state.commissioningFormData = payload;
		},
		travelExpensesChanged: (
			state,
			{ payload }: PayloadAction<TravelExpensesFormType>
		) => {
			state.travelExpenses = payload;
		},
	},
});

export const {
	initializeState,
	operatingLocationChanged,
	commissioningFormDataChanged,
	travelExpensesChanged,
} = productInOperationSlice.actions;

export default productInOperationSlice.reducer;
