import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { ControlledTextField } from 'library/components/controls';
import { IdentifiersContainer } from 'modules/UnitOverview/UnitInformation/Components/IdentifiersContainer';

import { FormSectionProps } from './Types';

export const IdentifiersIndustrialSection = memo(
	({ control }: FormSectionProps) => {
		const { t } = useTranslation(['unitCreation', 'commonUnit']);

		return (
			<IdentifiersContainer
				title={t('unitCreation:unit-information-identifiers-title')}>
				<ControlledTextField
					id="text-field-machine-id"
					label={t('commonUnit:machine-id')}
					control={control}
					name="machineId"
					size="small"
					validation={{
						required: 'common:validation-message-valid-identifier',
					}}
				/>
			</IdentifiersContainer>
		);
	}
);
