import { SvgIcon, SvgIconProps } from '@mui/material';

const UserIcon = (props: SvgIconProps) => (
	<SvgIcon {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M8.04883 8C8.04883 5.79086 9.83969 4 12.0488 4C14.258 4 16.0488 5.79086 16.0488 8C16.0488 10.2091 14.258 12 12.0488 12C9.83969 12 8.04883 10.2091 8.04883 8ZM12.0488 3C9.2874 3 7.04883 5.23858 7.04883 8C7.04883 9.75185 7.94978 11.2933 9.31361 12.1862C6.72459 12.5891 5.3166 13.6203 4.61191 15.0318C4.16935 15.9183 4.03702 16.8982 4.00722 17.8336C3.99226 18.3032 4.00304 18.7716 4.017 19.2188L4.02526 19.4746C4.03701 19.8322 4.04818 20.1725 4.04818 20.5C4.04818 20.7761 4.27204 21 4.54818 21C4.82433 21 5.04818 20.7761 5.04818 20.5C5.04818 20.1544 5.03631 19.7935 5.02451 19.4348L5.01651 19.1876C5.00265 18.7434 4.99282 18.3015 5.00671 17.8655C5.03461 16.9897 5.15782 16.1771 5.5066 15.4785C6.17149 14.1468 7.78657 13 12.0001 13C16.2137 13 17.8287 14.1468 18.4935 15.4785C18.8423 16.1771 18.9654 16.9897 18.9933 17.8655C19.0072 18.3015 18.9973 18.7434 18.9835 19.1876L18.9755 19.4345C18.9637 19.7934 18.9518 20.1543 18.9518 20.5C18.9518 20.7761 19.1756 21 19.4518 21C19.7279 21 19.9518 20.7761 19.9518 20.5C19.9518 20.1724 19.963 19.8321 19.9747 19.4745L19.9747 19.4743L19.983 19.2188C19.9969 18.7716 20.0077 18.3032 19.9928 17.8336C19.963 16.8982 19.8307 15.9183 19.3882 15.0318C18.6907 13.6346 17.3041 12.61 14.765 12.1986C16.1395 11.3076 17.0488 9.76001 17.0488 8C17.0488 5.23858 14.8103 3 12.0488 3Z"
		/>
	</SvgIcon>
);

export default UserIcon;
