import {
	Autocomplete,
	AutocompleteRenderInputParams,
	TextField,
	UseAutocompleteProps,
} from '@mui/material';
import { GridFilterInputValueProps } from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';
import { useImperativeHandle, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useProductCenterApi } from 'api';
import { countryInfosQuery } from 'api/queries';
import { IdLabelDto } from 'api/responses/models/Common/IdLabelDto';
import { mapCountryInfoToIdLabelCountry } from 'api/responses/models/Configuration/CountryInfoDto';
import {
	defaultGetOptionId,
	defaultGetOptionLabel,
	defaultIsOptionEqualToValue,
} from 'library/components/controls';
import CountryFlag from 'library/components/controls/display/CountryFlag';
import { selectUser } from 'library/redux/application';
import { useAppSelector } from 'library/redux/hooks';

export function CountryAutoCompleteInput({
	item,
	applyValue,
	focusElementRef,
}: GridFilterInputValueProps) {
	const { t } = useTranslation(['common']);
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const autoCompleteRef: React.Ref<any> = useRef(null);

	useImperativeHandle(focusElementRef, () => ({
		focus: () => {
			autoCompleteRef.current
				.querySelector(`input[value="${Number(item.value) || ''}"]`)
				.focus();
		},
	}));

	const handleFilterChange: UseAutocompleteProps<
		IdLabelDto,
		false,
		false,
		false
	>['onChange'] = (_, newValue) => {
		applyValue({ ...item, value: newValue?.id });
	};

	const languageCode = useAppSelector(selectUser).language;

	const { api } = useProductCenterApi();
	const countriesQuery = useQuery(countryInfosQuery(api, languageCode));

	const countryOptions = countriesQuery.data?.map(
		mapCountryInfoToIdLabelCountry
	);

	const selectedItem = countryOptions?.find(
		(countryItem) => countryItem.id === item.value
	);

	return (
		<Autocomplete
			ref={autoCompleteRef}
			options={countryOptions || []}
			value={selectedItem || null}
			onChange={handleFilterChange}
			isOptionEqualToValue={defaultIsOptionEqualToValue}
			renderOption={(innerProps, option) => (
				<li {...innerProps} key={defaultGetOptionId(option)}>
					<CountryFlag countryCode={defaultGetOptionId(option)} />
					{defaultGetOptionLabel(option)}
				</li>
			)}
			renderInput={(params: AutocompleteRenderInputParams) => (
				<TextField
					variant="standard"
					label={t('common:country')}
					{...params}
				/>
			)}
		/>
	);
}
