import { useContext } from 'react';

import { isDevEnv } from 'library/constants';

import WizardContext from './WizardContext';
import { WizardValues } from './types';

export const useWizard = (): WizardValues => {
	const context = useContext(WizardContext);

	if (!context && isDevEnv) {
		throw Error('use only in wizard context');
	} else {
		return context as WizardValues;
	}
};
