import { UnitOtherPartDto } from 'api';
import { SubmitErrorResponse } from 'library/exceptions/ValidationErrors';
import { RootState } from 'store';

import { otherPartsListSelectors } from './Entity';
import { UnitCreationFileUploadState } from './FilesSlice';
import { FileData, UnitDataFormTypes } from './Types';

export const selectIsCompleted = (state: RootState): boolean =>
	state.unitCreation.createdUnitId != null &&
	state.unitCreation.createdUnitId > 0;

export const selectSubmitErrors = (
	state: RootState
): SubmitErrorResponse | undefined => state.unitCreation.submitErrors;

export const selectOtherPartsList = (state: RootState): UnitOtherPartDto[] =>
	otherPartsListSelectors.selectAll(state.unitCreation);

export const selectOtherPartsProductIndividualSerialNumbers = ({
	unitCreation: {
		otherPartsProductIndividualSerialNumbers:
			otherPartsInstallationSerialNumbers,
	},
}: RootState): string[] => otherPartsInstallationSerialNumbers;

export const selectProfilePicturePath = (state: RootState): FileData | null =>
	state.unitCreationFiles.profilePicturePath;

export const selectCreatedUnitId = (state: RootState): number | undefined =>
	state.unitCreation.createdUnitId;

export const selectUnitData = (state: RootState): UnitDataFormTypes =>
	state.unitCreation.unitData;

export const selectUnitCreationFiles = (
	state: RootState
): UnitCreationFileUploadState => state.unitCreationFiles;
