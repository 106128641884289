import { MenuDropdownFactory } from '../components/MenuDropdownFactory';
import { IMenuItemsContainer } from '../components/menuFactory/MenuItems';
import { getMenuByLabel } from '../menu/MenuGenerator';
import IMenu from '../models/IMenu';
import ISubMenu from '../models/ISubMenu';
import { renderMenuItems } from './MobileMenuItems';

export interface IMobileMenuFactory {
	menu: IMenu;
	onMenuSelect: (menuLabel: string) => void;
	selectedMenu: IMenu | ISubMenu | null;
	onMenuLinkClick: () => void;
}

export const MobileMenuFactory = ({
	menu,
	selectedMenu,
	onMenuSelect,
	onMenuLinkClick,
}: IMobileMenuFactory): JSX.Element => {
	const renderMenuItemsWrapper = (
		menuItemsContainer: IMenuItemsContainer
	): JSX.Element[] | null => {
		return renderMenuItems(
			menuItemsContainer,
			onMenuSelect,
			onMenuLinkClick,
			selectedMenu
		);
	};

	const getMenuClassNames = (): string | undefined => {
		if (
			selectedMenu !== null &&
			getMenuByLabel(selectedMenu.label, [menu]) !== null
		) {
			return 'szh-menu--open menu__dropdown__list force-always-open';
		}

		return undefined;
	};

	const getMenuContainerClassNames = (): string | undefined => {
		if (
			selectedMenu !== null &&
			getMenuByLabel(selectedMenu.label, [menu]) === null
		) {
			return 'menu__dropdown--hidden';
		}

		return undefined;
	};

	return (
		<MenuDropdownFactory
			menu={menu}
			menuClassNames={getMenuClassNames()}
			menuContainerClassNames={getMenuContainerClassNames()}
			menuAnimation={false}
			menuBtn={{
				menuLabel: menu.label,
				hideBtn: selectedMenu !== null,
				onMenuBtnClick: onMenuSelect,
				onMenuLinkClick: onMenuLinkClick,
			}}
			menuItems={{ renderMenuItems: renderMenuItemsWrapper }}
		/>
	);
};
