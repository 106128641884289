import { lazy } from 'react';
import { Outlet, Route } from 'react-router-dom';

import { AreaName } from 'domain/constants';
import { OperationType } from 'library/models/enums/OperationType';
import { createArea } from 'library/utils/helpers/AreaHelper';
import Authorize from 'main/auth/Authorize';
import DocumentsSectionPage from 'pages/productindividualview/sectionPages/DocumentsSectionPage';

import { PageRedirect } from './PageRedirect';
import {
	productIndividualCampaigns,
	productIndividualClaims,
	productIndividualConfiguration,
	productIndividualDocuments,
	productIndividualEdit,
	productIndividualEventLog,
	productIndividualLogisticTrail,
	productIndividualParts,
	productIndividualPolicy,
	productIndividualRepairs,
	productIndividualTechnical,
	productIndividualVstCodes,
	productIndividualviewSNParam,
} from './urls/RouteUrls';

const ProductIndividualviewPage = lazy(
	() => import('pages/productindividualview/ProductIndividualviewPage')
);

const ProductIndividualviewEditPage = lazy(
	() => import('pages/productindividualview/ProductIndividualviewEditPage')
);

const TechnicalSectionPage = lazy(
	() =>
		import('pages/productindividualview/sectionPages/TechnicalSectionPage')
);

const CampaignsSectionPage = lazy(
	() =>
		import('pages/productindividualview/sectionPages/CampaignsSectionPage')
);
const ClaimsSectionPage = lazy(
	() => import('pages/productindividualview/sectionPages/ClaimsSectionPage')
);
const PartsSectionPage = lazy(
	() => import('pages/productindividualview/sectionPages/PartsSectionPage')
);
const ConfigurationSectionPage = lazy(
	() =>
		import(
			'pages/productindividualview/sectionPages/ConfigurationSectionPage'
		)
);
const EventLogSectionPage = lazy(
	() => import('pages/productindividualview/sectionPages/EventLogSectionPage')
);
const LogisticTrailSectionPage = lazy(
	() =>
		import(
			'pages/productindividualview/sectionPages/LogisticTrailSectionPage'
		)
);

const VstCodesSectionPage = lazy(
	() => import('pages/productindividualview/sectionPages/VstCodesSectionPage')
);

const RepairsSectionPage = lazy(
	() => import('pages/productindividualview/sectionPages/RepairsSectionPage')
);

const PolicySectionPage = lazy(
	() => import('pages/productindividualview/sectionPages/PolicySectionPage')
);

export const getProductIndividualRoutes = (): JSX.Element[] => [
	<Route
		key="productindividualview"
		element={
			<Authorize
				area={createArea(AreaName.Individual, OperationType.Read)}>
				<Authorize area={createArea(AreaName.Unit, OperationType.Read)}>
					<Outlet />
				</Authorize>
			</Authorize>
		}>
		<Route
			path={`${productIndividualviewSNParam}`}
			element={<ProductIndividualviewPage />}>
			<Route
				index
				element={<PageRedirect url={productIndividualCampaigns} />}
			/>
			<Route
				element={<TechnicalSectionPage />}
				path={productIndividualTechnical}
			/>
			<Route
				element={<CampaignsSectionPage />}
				path={productIndividualCampaigns}
			/>
			<Route
				element={
					<Authorize
						area={createArea(
							AreaName.IndividualViewClaim,
							OperationType.Read
						)}>
						<ClaimsSectionPage />
					</Authorize>
				}
				path={productIndividualClaims}
			/>
			<Route
				element={<PartsSectionPage />}
				path={productIndividualParts}
			/>
			<Route
				element={<ConfigurationSectionPage />}
				path={productIndividualConfiguration}
			/>
			<Route
				element={
					<Authorize
						area={createArea(
							AreaName.ProductIndividualEventLog,
							OperationType.Read
						)}>
						<EventLogSectionPage />
					</Authorize>
				}
				path={productIndividualEventLog}
			/>
			<Route
				element={
					<Authorize
						area={createArea(
							AreaName.LogisticTrail,
							OperationType.Read
						)}>
						<LogisticTrailSectionPage />
					</Authorize>
				}
				path={productIndividualLogisticTrail}
			/>
			<Route
				element={<VstCodesSectionPage />}
				path={productIndividualVstCodes}
			/>
			<Route
				element={
					<Authorize
						area={createArea(
							AreaName.UploadProductionAndClassificationDocuments,
							OperationType.Read
						)}>
						<DocumentsSectionPage />
					</Authorize>
				}
				path={productIndividualDocuments}
			/>
			<Route
				element={
					<Authorize
						area={createArea(AreaName.Repair, OperationType.Read)}>
						<RepairsSectionPage />
					</Authorize>
				}
				path={productIndividualRepairs}
			/>
			<Route
				element={<PolicySectionPage />}
				path={productIndividualPolicy}
			/>
		</Route>
	</Route>,
	<Route
		key="productindividualeditview"
		element={
			<Authorize
				area={createArea(AreaName.Individual, OperationType.Write)}>
				<ProductIndividualviewEditPage />
			</Authorize>
		}
		path={productIndividualEdit}
	/>,
];
