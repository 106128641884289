import { QueryClient, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { userKeys } from 'api/queries';
import { AreaName } from 'domain/constants';
import { Area } from 'library/models/Area';
import { OperationType } from 'library/models/enums/OperationType';
import { createArea } from 'library/utils/helpers/AreaHelper';

import { hasUserAccess } from './Authorization';

export interface UseCheckUserAccessProps {
	areaName: AreaName;
	permission: OperationType;
}

const consoleDebuggingArea: UseCheckUserAccessProps = {
	areaName: AreaName.ConsoleDebugging,
	permission: OperationType.Read,
};

export const useCheckConsoleDebuggingReadAccess = () => {
	const queryClient = useQueryClient();

	const checkConsoleDebuggingReadAccess = useCallback(() => {
		return checkUserAccess(queryClient, consoleDebuggingArea);
	}, [queryClient]);

	return { checkConsoleDebuggingReadAccess };
};

export const checkUserAccess = (
	queryClient: QueryClient,
	{ areaName, permission }: UseCheckUserAccessProps
) => {
	const availableAreas = queryClient.getQueryData<Area[]>(
		userKeys.securityMatrix()
	);

	return hasUserAccess(
		createArea(areaName, permission),
		availableAreas ?? []
	);
};
