import { GridFilterModel, GridSortModel } from '@mui/x-data-grid-pro';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { BusinessPartnerDto } from 'api';

import { MaintenancePlannerState } from './Types';

const initialState: MaintenancePlannerState = {
	pageIndex: 0,
	sortModel: [{ field: 'NextServiceDueIn', sort: 'asc' }],
};

export const maintenancePlannerSlice = createSlice({
	name: 'maintenancePlanner',
	initialState,
	reducers: {
		resetBusinessPartner: (state) => {
			state.businessPartner = undefined;

			return state;
		},
		setBusinessPartner: (
			state,
			{ payload }: PayloadAction<BusinessPartnerDto>
		) => {
			state.businessPartner = payload;

			return state;
		},
		setFilter: (state, { payload }: PayloadAction<GridFilterModel>) => {
			state.filterModel = payload;

			return state;
		},
		setIsActiveCampaign: (state, { payload }: PayloadAction<boolean>) => {
			state.isActiveCampaign = payload;

			return state;
		},
		setSort: (state, { payload }: PayloadAction<GridSortModel>) => {
			state.sortModel = payload;

			return state;
		},
		setNextPage: (state) => {
			state.pageIndex += 1;

			return state;
		},
		setPreviousPage: (state) => {
			if (state.pageIndex > 0) {
				state.pageIndex -= 1;
			}

			return state;
		},
	},
});

export const {
	resetBusinessPartner,
	setBusinessPartner,
	setIsActiveCampaign,
	setFilter,
	setNextPage,
	setPreviousPage,
	setSort,
} = maintenancePlannerSlice.actions;

export default maintenancePlannerSlice.reducer;
