import { LoadingButton } from '@mui/lab';
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	Theme,
	useMediaQuery,
} from '@mui/material';
import { AsyncThunk } from '@reduxjs/toolkit';
import { noop } from 'lodash';
import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ADDITIONAL_FILES_ACTIONS } from 'domain/unit/AdditionalFilesCommand/AdditionalFilesCommand';
import { ControlledCommentBox } from 'library/components/controls';
import { COMMENT_BOX_MAX_LENGTH } from 'library/constants/Validations';
import { useAppDispatch, useAppSelector } from 'library/redux/hooks';
import {
	IFileUploadStateHandlers,
	UploadFileSection,
} from 'modules/Shared/Components/FileUpload/UploadFileSection';
import { submitAdditionalFiles } from 'modules/UnitOverview/State/Thunk';

const { COMMISSIONING, PRODUCT_IN_OPERATION, UNIT_CREATION } =
	ADDITIONAL_FILES_ACTIONS;
interface AdditionalFilesFormType {
	comment?: string;
}

export interface IAdditionalFileUploadStateHandlers
	extends IFileUploadStateHandlers {
	reset?: AsyncThunk<boolean, void, any>;
}

export interface AdditionalFileUploadProps {
	handler: IAdditionalFileUploadStateHandlers;
	onClose?: (isSubmitting: boolean) => void;
}

export const UploadAdditionalFilesDialog = ({
	handler,
	onClose = noop,
}: AdditionalFileUploadProps): JSX.Element => {
	const [isLoading, setIsLoading] = useState(false);
	const { fileStateSelector, attachmentFolderCreated, reset } = handler;
	const { filesUploaded } = useAppSelector(fileStateSelector);
	const dispatch = useAppDispatch();
	const { enqueueSnackbar } = useSnackbar();
	const { t } = useTranslation([
		'common',
		'layout',
		'language',
		'commonButton',
		'commonWizard',
	]);
	const fullScreen = useMediaQuery((theme: Theme) =>
		theme.breakpoints.only('xs')
	);
	const { control, getValues } = useForm<AdditionalFilesFormType>();

	const handleOnClose = useCallback(
		(isSubmitting: boolean) => {
			if (reset) {
				dispatch(reset());
			}
			onClose(isSubmitting);
		},
		[dispatch, onClose, reset]
	);

	const establishActionBasedOnType = () => {
		if (attachmentFolderCreated?.type.includes(PRODUCT_IN_OPERATION)) {
			return PRODUCT_IN_OPERATION;
		} else if (attachmentFolderCreated?.type.includes(COMMISSIONING)) {
			return COMMISSIONING;
		} else {
			return UNIT_CREATION;
		}
	};

	const handleUploadFiles = () => {
		setIsLoading(true);
		dispatch(
			submitAdditionalFiles(
				establishActionBasedOnType(),
				fileStateSelector,
				getValues().comment
			)()
		)
			.unwrap()
			.then(() => {
				handleOnClose(true);
			})
			.catch(() => {
				enqueueSnackbar(t('common:error-unexpected'), {
					variant: 'error',
				});
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	return (
		<Dialog
			maxWidth={false}
			open
			onClose={() => handleOnClose(false)}
			fullScreen={fullScreen}>
			<DialogContent>
				<Box display="grid" gap={2}>
					<UploadFileSection
						fileUploadStateHandlers={handler}
						validate={noop}
						validation={{
							min: {
								value: 1,
								message: 'common:validation-one-file-required',
							},
						}}
					/>
					<ControlledCommentBox
						control={control}
						name={'comment'}
						label={t('commonWizard:additional-comment-label')}
						validation={{
							maxLength: {
								value: COMMENT_BOX_MAX_LENGTH,
								message:
									'commonWizard:validation-comment-box-max-length',
							},
						}}
					/>
				</Box>
			</DialogContent>
			<DialogActions>
				<Button variant="outlined" onClick={() => handleOnClose(false)}>
					{t('commonButton:cancel')}
				</Button>
				<LoadingButton
					loading={isLoading}
					color="secondary"
					disabled={!filesUploaded.length}
					variant="contained"
					onClick={handleUploadFiles}>
					{t('commonButton:save')}
				</LoadingButton>
			</DialogActions>
		</Dialog>
	);
};
