import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ReplaceProductIndividualCommand, useProductCenterApi } from 'api';
import { useValidationErrorsSnackbar } from 'library/hooks/useValidationErrorsSnackbar';
import { useCheckConsoleDebuggingReadAccess } from 'main/auth/checkUserAccess';

import { ReplaceIndividualFormType } from './Types';
import { useReplacementValidation } from './Validation';

export const useReplaceFormSubmit = ({
	onConfirm,
	unitId,
	mapFormToRequest,
	noValidateSerial,
}: {
	onConfirm: () => void;
	unitId: number;
	mapFormToRequest: (
		f: ReplaceIndividualFormType
	) => ReplaceProductIndividualCommand;
	noValidateSerial?: boolean;
}) => {
	const { t } = useTranslation(['unitOverview']);
	const { api } = useProductCenterApi();

	const { enqueueSnackbar } = useSnackbar();
	const { enqueueValidationSnackbar } = useValidationErrorsSnackbar();
	const { checkConsoleDebuggingReadAccess } =
		useCheckConsoleDebuggingReadAccess();
	const { isSerialNumberValid } = useReplacementValidation();

	const { handleSubmit, setError, control } =
		useForm<ReplaceIndividualFormType>({
			mode: 'onBlur',
		});

	const replaceMutation = useMutation<
		boolean,
		unknown,
		ReplaceProductIndividualCommand
	>({
		mutationFn: async (data) => {
			return (await api.replaceIndividual(unitId, data)).data;
		},
	});

	const submitForm = async (
		data: ReplaceIndividualFormType
	): Promise<void> => {
		try {
			await replaceMutation.mutateAsync(mapFormToRequest(data), {
				onSuccess: () => {
					enqueueSnackbar(t(`unitOverview:replace-success`), {
						variant: 'success',
					});

					onConfirm();
				},
			});
		} catch (error) {
			checkConsoleDebuggingReadAccess() && console.error(error);

			enqueueValidationSnackbar(error as Error);
		}
	};

	const onSubmit = handleSubmit(async (data) => {
		if (noValidateSerial) {
			await submitForm(data);
			return;
		}

		const isProductValid = await isSerialNumberValid(data.newSerialNumber);

		if (!isProductValid) {
			setError('newSerialNumber', {
				type: 'validate',
			});
		} else {
			await submitForm(data);
		}
	});

	return { onSubmit, control, disableSubmit: replaceMutation.isPending };
};
