import { Box, BoxProps, Typography, useTheme } from '@mui/material';
import {
	GridColDef,
	GridToolbarProps,
	ToolbarPropsOverrides,
} from '@mui/x-data-grid-pro';
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro';
import {
	ForwardedRef,
	MutableRefObject,
	forwardRef,
	memo,
	useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';

import { UnitOtherPartDto } from 'api';
import { DataGridToolbarWithCsvExport } from 'library/components/controls/datagrid/DataGridToolbar';
import { LocalizedDataGrid } from 'library/components/controls/datagrid/LocalizedDataGrid';
import { toPrecision } from 'library/formatters';
import { ADD_ROW_ANIMATION_CLASS_NAME } from 'library/utils/themes/PCTheme';
import Variables from 'library/utils/themes/Variables';
import {
	InstalledCellEvents,
	MemoizedInstalledCell,
} from 'modules/UnitCreation/WizardSteps/OtherPartsStep/InstalledCell';

export const ProductUnitDataGrid = memo(
	forwardRef(
		(
			{
				otherParts,
				isEditMode,
				onInstalledCellChanged,
				containerProps,
			}: {
				otherParts: UnitOtherPartDto[];
				isEditMode: boolean;
				containerProps?: BoxProps;
			} & InstalledCellEvents,
			ref: ForwardedRef<GridApiPro>
		): JSX.Element => {
			const { t } = useTranslation('unitCreation');
			const theme = useTheme<Variables>();
			const originParts = useMemo(() => otherParts, []);

			const columns: GridColDef<UnitOtherPartDto>[] = useMemo(
				() => [
					{
						field: 'partNumber',
						headerName: t('other-parts-part-number'),
						headerAlign: 'left',
						align: 'left',
						flex: 2,
						minWidth: 130,
					},
					{
						field: 'serialNumber',
						headerName: t('other-parts-serial-number'),
						headerAlign: 'left',
						align: 'left',
						flex: 2,
						minWidth: 130,
					},
					{
						field: 'description',
						headerName: t('other-parts-description'),
						headerAlign: 'left',
						align: 'left',
						flex: 4,
						minWidth: 350,
					},
					{
						field: 'scipNumber',
						headerName: t('other-parts-scip-number'),
						headerAlign: 'left',
						align: 'left',
						flex: 4,
						minWidth: 330,
					},
					{
						field: 'delivered',
						headerName: t('other-parts-delivered'),
						type: 'number',
						valueFormatter: (value) => toPrecision(value),
						headerAlign: 'left',
						align: 'left',
						width: 130,
					},
				],
				[t]
			);

			const editColumn: GridColDef<UnitOtherPartDto> = useMemo(
				() => ({
					field: 'installed',
					headerName: t('other-parts-installed'),
					width: 130,
					type: 'number',
					headerAlign: 'left',
					align: 'left',
					sortable: false,
					valueFormatter: (value) => toPrecision(value),
					renderCell: ({ value, ...cellRest }) => (
						<>
							{isEditMode ? (
								<MemoizedInstalledCell
									{...cellRest}
									value={value}
									onInstalledCellChanged={
										onInstalledCellChanged
									}
								/>
							) : (
								<Typography>{value}</Typography>
							)}
						</>
					),
					display: 'flex',
				}),
				[isEditMode, onInstalledCellChanged, t]
			);

			return (
				<Box
					display="flex"
					minWidth={0}
					height={theme.custom.dataGridHeight}
					{...containerProps}>
					<LocalizedDataGrid
						apiRef={ref as MutableRefObject<GridApiPro>}
						initialState={
							isEditMode
								? { pinnedColumns: { right: ['installed'] } }
								: undefined
						}
						rows={otherParts}
						columns={[...columns, editColumn]}
						slots={{
							toolbar: DataGridToolbarWithCsvExport as Partial<
								GridToolbarProps & ToolbarPropsOverrides
							>['toolbar'],
						}}
						disableColumnResize
						disableColumnReorder
						disableRowSelectionOnClick
						getRowClassName={({ isFirstVisible }) =>
							isEditMode &&
							originParts.length !== otherParts.length &&
							isFirstVisible
								? ADD_ROW_ANIMATION_CLASS_NAME
								: ''
						}
					/>
				</Box>
			);
		}
	)
);
