import { Option } from 'library/models/Option';

export interface ListItem {
	value: string;
	text: string;
}

export const convertListItemToOption = ({
	text,
	value,
}: ListItem): Option<string> => ({ id: value, label: text });
