import { productCenterApi } from 'api';
import { createWizardSubmitThunk } from 'library/hooks/Wizard/WizardSubmitHelpers';
import { createResetStateThunk } from 'library/redux/fileUpload/Thunks';

import { mapFromStateToRequest } from './Mappers';
import { selectAll } from './Selectors';

export const resetState = createResetStateThunk(
	'generateServiceProtocolFiles/resetState',
	(s) => s.generateServiceProtocolFiles
);

export const submitServiceProtocol = createWizardSubmitThunk(
	'generateServiceProtocol/submitServiceProtocol',
	async (root) => {
		const { generateServiceProtocolFiles } = root;

		const generateServiceProtocol = selectAll(root);

		const request = await mapFromStateToRequest(
			generateServiceProtocol,
			generateServiceProtocolFiles
		);

		return await productCenterApi.saveServiceProtocol(request);
	}
);
