import { MenuItem, SubMenu } from '@szhsin/react-menu';
import { map, some } from 'lodash';

import MenuLink from '../MenuLink';
import { IMenuItemsContainer } from '../components/menuFactory/MenuItems';
import { isMenuItem, isSubMenu } from '../menu/MenuGenerator';
import IBaseMenuItem from '../models/IBaseMenuItem';
import IMenuItem from '../models/IMenuItem';
import ISubMenu from '../models/ISubMenu';

export const renderMenuItems = (
	menuItemsContainer: IMenuItemsContainer
): JSX.Element[] | null => {
	const renderChildItems = (
		childItems?: IBaseMenuItem[],
		menuLevel?: number
	) => {
		if (some(childItems)) {
			menuLevel = menuLevel === undefined ? 1 : menuLevel;

			return map(childItems, (nestedItem) => {
				const menuItem = nestedItem as IMenuItem;
				const subMenuItem = nestedItem as ISubMenu;

				if (!isSubMenu(subMenuItem) && isMenuItem(menuItem)) {
					return (
						<MenuItem
							key={menuItem.label}
							className="menu__dropdown__list__item"
						>
							{menuItem.label}
							{menuItem.children}
							<MenuLink url={menuItem.route} />
						</MenuItem>
					);
				} else {
					return (
						<SubMenu
							itemProps={{
								className:
									'menu__dropdown__list__item menu__dropdown__list__item--sub-menu',
							}}
							menuClassName="menu__dropdown__list"
							label={subMenuItem.label}
							key={subMenuItem.label}
						>
							{renderChildItems(
								subMenuItem.subMenuItems,
								menuLevel
							)}
						</SubMenu>
					);
				}
			});
		}

		return null;
	};

	return renderChildItems(menuItemsContainer.menuItems);
};
