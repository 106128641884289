import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import MUISelect, { SelectChangeEvent } from '@mui/material/Select';
import { find } from 'lodash';
import { useState } from 'react';

import { SelectProps } from '../selects/SelectCommon';

export interface SelectGenericProps<T> extends SelectProps<T> {
	getOptionText: (item: T) => React.ReactNode;
	getOptionValue: (item: T) => string | number;
}

const regex = /[^A-Za-z0-9]+/;

export const SelectGeneric = <T,>({
	className,
	getOptionText,
	getOptionValue,
	id,
	label,
	onChange,
	options,
	selectedOption,
}: SelectGenericProps<T>) => {
	const initialValue = selectedOption ? (selectedOption as T) : undefined;
	const [internalValue, setValue] = useState<T | undefined>(initialValue);

	const handleChange = (event: SelectChangeEvent) => {
		const item = find(
			options,
			(item) => getOptionValue(item) === event.target.value
		) as T;

		setValue(item);
		onChange(item);
	};

	const labelId =
		label === undefined || id === undefined
			? ''
			: `select-label-${id}-${label.replace(regex, '')}`;

	return (
		<span id={id} className={className}>
			<FormControl sx={{ width: '100%' }}>
				<InputLabel id={labelId}>{label}</InputLabel>
				<MUISelect
					labelId={labelId}
					value={
						internalValue === undefined
							? undefined
							: getOptionValue(internalValue as T).toString()
					}
					onChange={handleChange}
					input={<OutlinedInput label={label} />}>
					{options.map((item: T, index: number) => (
						<MenuItem key={index} value={getOptionValue(item)}>
							{getOptionText(item)}
						</MenuItem>
					))}
				</MUISelect>
			</FormControl>
		</span>
	);
};
