import {
	FormControl,
	FormControlLabel,
	Radio,
	RadioGroup,
	RadioGroupProps,
} from '@mui/material';

import { Sxable } from 'library/types';

interface RadioGroupDisplayProps extends Sxable {
	value: RadioGroupProps['value'];
	row?: RadioGroupProps['row'];
	radioLabelsList: { id: unknown; label: React.ReactNode }[];
}

export const RadioGroupDisplay = ({
	value,
	radioLabelsList,
	row,
	sx = [],
}: RadioGroupDisplayProps): JSX.Element => {
	return (
		<FormControl sx={sx}>
			<RadioGroup row={row} value={value}>
				{radioLabelsList.map((type) => (
					<FormControlLabel
						key={type.id as string}
						value={type.id}
						control={
							<Radio size="small" color="secondary" readOnly />
						}
						label={type.label}
					/>
				))}
			</RadioGroup>
		</FormControl>
	);
};
