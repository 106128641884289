import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { ThemeProvider } from '@mui/material/styles';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { StrictMode } from 'react';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';

import 'i18n/config';
import 'library/MuiProLicense';
import { PCTheme } from 'library/utils/themes/PCTheme';
import { reactPlugin } from 'main/azureInsights/config';
import { getRouter } from 'main/routes/RoutesAggregator';
import { store } from 'store/configureStore';

// Create a client
const queryClient = new QueryClient();
const router = getRouter();

const App = () => (
	<StrictMode>
		<Provider store={store}>
			<QueryClientProvider client={queryClient}>
				<AppInsightsContext.Provider value={reactPlugin}>
					<ThemeProvider theme={PCTheme}>
						<RouterProvider router={router} />
					</ThemeProvider>
				</AppInsightsContext.Provider>
			</QueryClientProvider>
		</Provider>
	</StrictMode>
);

export default App;
