import { SvgIcon, SvgIconProps } from '@mui/material';

export const GeneralIcon = (props: SvgIconProps) => (
	<SvgIcon {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M4.5 2C4.22386 2 4 2.22386 4 2.5V21.5C4 21.7761 4.22386 22 4.5 22H19.5C19.7761 22 20 21.7761 20 21.5V9.5C20 9.36739 19.9473 9.24021 19.8536 9.14645L12.8536 2.14645C12.7598 2.05268 12.6326 2 12.5 2H4.5ZM5 21V3H12V9.5C12 9.77614 12.2239 10 12.5 10H19V21H5ZM18.2929 9L13 3.70711V9H18.2929Z"
		/>
	</SvgIcon>
);
