import { MenuItem } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { FieldPath, FieldValues } from 'react-hook-form';

import { Option } from 'library/models/Option';

import {
	ControlledTextField,
	TextFieldElementProps,
} from './ControlledTextField';

export interface ControlledSelectProps<
	TFieldValues extends FieldValues = FieldValues,
	TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends TextFieldElementProps<TFieldValues, TName> {
	options: Option<string>[];
}

export const ControlledSelect = <
	TFieldValues extends FieldValues = FieldValues,
	TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
	options,
	...rest
}: ControlledSelectProps<TFieldValues, TName>): JSX.Element => {
	const ChildComponent = isMobile ? 'option' : MenuItem;

	return (
		<ControlledTextField
			select
			SelectProps={{
				native: isMobile,
			}}
			{...rest}
		>
			{isMobile && <option />}
			{options.map((item: Option<string>) => (
				<ChildComponent id={item.id} key={item.id} value={item.id}>
					{item.label}
				</ChildComponent>
			))}
		</ControlledTextField>
	);
};
