import { SvgIcon, SvgIconProps } from '@mui/material';

const StackedPentaLogo = (props: SvgIconProps) => (
	<SvgIcon
		xmlns="http://www.w3.org/2000/svg"
		x="0px"
		y="0px"
		viewBox="0 0 850.39 177.29"
		{...props}
	>
		<g>
			<g>
				<g>
					<polygon
						points="76.08,11.04 88.4,11.04 67.81,49.14 46.93,11.04 61.91,11.04 61.9,1.54 0,1.54 0,11.05 14.16,11.05 42.94,63.28 
				76.34,63.28 104.57,11.04 118.74,11.04 118.75,1.54 76.08,1.54 			"
					/>
				</g>
				<g>
					<polygon
						points="625.13,11.04 637.45,11.04 616.85,49.14 595.98,11.04 610.95,11.04 610.95,1.54 549.05,1.54 549.05,11.05 
				563.21,11.05 591.99,63.28 625.39,63.28 653.61,11.04 667.79,11.04 667.79,1.54 625.13,1.54 			"
					/>
				</g>
				<g>
					<path
						d="M394.79,53.7c-0.66,0-14.4,0-14.4,0l0,9.59l85.67-0.01l0-28.58c-2.09,0-6.71,0-9.41,0c0,4.61-1.95,10.04-6.01,13.9
				c-1.4,1.3-2.95,2.5-4.71,3.36c-2.28,1.14-4.43,1.73-9.51,1.73c-3.42,0-10.72,0.01-13.16,0.01V11.05h14.21l0-9.51
				c-0.68,0-55.54,0-57.06,0l0,9.51h14.38L394.79,53.7z"
					/>
				</g>
				<path
					d="M242.41,55.1c-10.5,0-15.29-11.48-15.3-22.97c0-11.9,5.51-22.39,15.32-22.39c9.8,0,15.15,10.49,15.15,22.39
			c0,11.23-4.43,22.97-15.23,22.97H242.41z M242.47,64.93c29.39,0,43.87-14.51,43.87-32.67C286.33,14.31,270.96,0,242.3,0
			c-28.65,0-43.88,14.31-43.88,32.25C198.42,50.42,213.08,64.93,242.47,64.93z"
				/>
				<path
					d="M791.79,55.1c-10.52,0-15.3-11.48-15.3-22.97c0-11.9,5.51-22.39,15.32-22.39c9.81,0,15.16,10.49,15.16,22.39
			c0,11.23-4.42,22.97-15.25,22.97H791.79z M791.84,64.93c29.36,0,43.87-14.51,43.87-32.67c0-17.94-15.4-32.25-44.03-32.25
			C763,0,747.8,14.31,747.8,32.25C747.8,50.42,762.44,64.93,791.84,64.93z"
				/>
			</g>
			<path
				d="M284.84,139.26v-23.71h-85.36v9.47h13.82v42.69h-13.82v9.57h85.36v-28.3h-9.44v2.27c0,9.15-6.23,16.41-14.27,16.41h-19.35
		v-18.69h19.35v-9.09h-19.35v-14.88h19.9c6.86,0,12.39,5.1,13.72,14.24H284.84z"
			/>
			<path
				d="M629.02,125.02c7.97,0,14.41,6.28,14.41,14.24h9.45v-23.71h-85.83v23.71h9.44c0-7.96,6.47-14.19,14.44-14.24h4.79v42.69
		h-13.88v9.58h56.26v-9.58h-13.9v-42.69H629.02z"
			/>
			<polygon
				points="411.11,177.29 411.11,167.71 397.17,167.71 397.17,139.26 440.12,177.29 463.9,177.29 463.9,125.02 478.01,125.02 
		478.01,115.55 435.39,115.55 435.39,125.02 449.45,125.02 449.45,146.23 413.94,115.55 368.67,115.55 368.67,125.02 382.71,125.02 
		382.71,167.71 368.67,167.71 368.67,177.29 	"
			/>
			<path
				d="M59.08,144.2v-19.18h8.43c3.89,0,7.38,1.39,9.55,3.64c1.51,1.51,2.45,3.69,2.45,6.08c0,2.55-1.08,4.81-2.73,6.36
		c-2.17,2.01-5.53,3.1-9.27,3.1L59.08,144.2z M108.16,134.84c0-4.74-1.89-9.03-4.9-12.07c-4.31-4.44-11.2-7.23-18.94-7.23h-6.34
		H16.44v9.47h14.15v42.69H16.44v9.58h57.08v-9.58H59.08v-14.11h18.79h6.45c7.43,0,14.06-2.15,18.39-6.16
		C106,144.39,108.16,139.88,108.16,134.84z"
			/>
			<path
				d="M745.92,167.71h-14.18c0,0,0,7.16,0,9.58c1.69,0.01,40.29,0.01,42.65,0.01c0.01-2.19,0-9.58,0-9.58l-12.3,0.03
		c0.28-0.55,2.28-4.25,4.95-9.16h31.43l5.03,9.13l-14.9,0v9.58h61.79v-9.58h-14.11l-28.77-52.16h-33.33L745.92,167.71z
		 M782.67,129.73l10.46,19.06h-20.78L782.67,129.73z"
			/>
		</g>
	</SvgIcon>
);

export default StackedPentaLogo;
