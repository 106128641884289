import { RootState } from 'store';

export const selectActiveStep = ({
	generateServiceProtocol: { activeStep },
}: RootState) => activeStep;

export const selectAll = ({ generateServiceProtocol }: RootState) =>
	generateServiceProtocol;

export const selectDrivelineId = ({ generateServiceProtocol }: RootState) =>
	generateServiceProtocol.drivelineId;

export const selectServiceProtocolId = ({
	generateServiceProtocol,
}: RootState) => generateServiceProtocol.serviceProtocolId;

export const selectId = ({
	generateServiceProtocol: { serviceProtocolId },
}: RootState) => serviceProtocolId;

export const selectIsOnline = ({
	generateServiceProtocol: { isOnline },
}: RootState) => isOnline;

export const selectLocation = ({
	generateServiceProtocol: { location },
}: RootState) => location;

export const selectOfflineActivities = ({
	generateServiceProtocol: { offlineActivities },
}: RootState) => offlineActivities;

export const selectFiles = ({ generateServiceProtocolFiles }: RootState) =>
	generateServiceProtocolFiles;

export const selectServiceInfo = ({
	generateServiceProtocol: { serviceInfo },
}: RootState) => serviceInfo;

export const selectStatus = ({
	generateServiceProtocol: { status },
}: RootState) => status;
