import { lazy } from 'react';
import { Route } from 'react-router-dom';

import { AreaName } from 'domain/constants';
import { OperationType } from 'library/models/enums/OperationType';
import { createArea } from 'library/utils/helpers/AreaHelper';

import Authorize from '../auth/Authorize';

const SearchCustomerPage = lazy(
	() => import('pages/search/SearchCustomerPage')
);

export const getSearchRoutes = (): JSX.Element[] => [
	<Route
		key={'consumer'}
		path={'consumer'}
		element={
			<Authorize area={createArea(AreaName.Consumer, OperationType.Read)}>
				<SearchCustomerPage />
			</Authorize>
		}
	/>,
];
