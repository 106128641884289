import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { GetServiceProtocolPdfQuery, useProductCenterApi } from 'api';
import { useCheckConsoleDebuggingReadAccess } from 'main/auth/checkUserAccess';

import { PrintProtocolFormType } from './Types';

export const usePdfFormSubmit = ({
	protocolId,
	mapFormToRequest,
}: {
	protocolId: number;
	mapFormToRequest: (
		protocolId: number,
		form: PrintProtocolFormType
	) => GetServiceProtocolPdfQuery;
}) => {
	const { api } = useProductCenterApi();
	const { t } = useTranslation('common');
	const { enqueueSnackbar } = useSnackbar();
	const { checkConsoleDebuggingReadAccess } =
		useCheckConsoleDebuggingReadAccess();
	const { handleSubmit, control } = useForm<PrintProtocolFormType>({
		mode: 'onBlur',
		defaultValues: {
			printQr: true,
			printEstimatedHours: true,
			printUsedHours: true,
			printConfiguration: true,
			printEndCustomer: true,
			printCampaigns: true,
			printCoverage: true,
			printProposals: true,
			printServiceActivitiesPhotos: true,
			printAdditionalRepairs: true,
			printAdditionalPhotos: true,
			printSignatures: true,
		},
	});
	const [isPending, setIsPending] = useState(false);

	const submitForm = async (
		data: PrintProtocolFormType
	): Promise<File | null> => {
		setIsPending(true);

		try {
			const file = (
				await api.downloadServiceProtocolPdf(
					mapFormToRequest(protocolId, data),
					{
						format: 'blob',
					}
				)
			).data;

			setIsPending(false);
			return file;
		} catch (error) {
			setIsPending(false);
			checkConsoleDebuggingReadAccess() && console.error(error);

			enqueueSnackbar(t('error-unexpected'), {
				variant: 'error',
				style: { whiteSpace: 'pre-line' },
			});

			return null;
		}
	};

	const onSubmit = handleSubmit(async (data) => {
		const file = await submitForm(data);
		const today = new Date();

		if (file) {
			if (file instanceof Blob) {
				const url = window.URL.createObjectURL(file);
				const a = document.createElement('a');
				document.body.appendChild(a);
				a.href = url;
				a.download = `Service Protocol ${protocolId} ${today.toISOString().substring(0, 10)}.pdf`;
				// TODO replace protocolId in filename with Chassis ID
				a.click();
				window.URL.revokeObjectURL(url);
				document.body.removeChild(a);
			}
		} else {
			checkConsoleDebuggingReadAccess() &&
				console.error('Returned file is not a Blob or File:', file);
			enqueueSnackbar(t('error-unexpected'), {
				variant: 'error',
				style: { whiteSpace: 'pre-line' },
			});
		}

		return;
	});

	return { onSubmit, control, isPending };
};
