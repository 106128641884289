import { Namespace, TFunction } from 'i18next';

import { IdLabelDto } from '../Common/IdLabelDto';

export type DimensionsType = 'Estimated' | 'Actual';

const KILOGRAM_UNIT = 'kg';
const TONNE_UNIT = 't';

export function convertWeightToKilograms(weight: number, unitOfWeight: string) {
	if (unitOfWeight === TONNE_UNIT) {
		weight *= 1000;
		unitOfWeight = KILOGRAM_UNIT;
	}
	return { unitOfWeight, weight };
}

export function convertUnitWeight(unitOfWeight: string | undefined | null) {
	if (unitOfWeight === KILOGRAM_UNIT) {
		return TONNE_UNIT;
	}

	return unitOfWeight;
}

export function convertWeightToTonnes(
	weight: number | null | undefined,
	unitOfWeight: string | null | undefined
) {
	if (weight != null && unitOfWeight === KILOGRAM_UNIT) {
		weight /= 1000;
		unitOfWeight = TONNE_UNIT;
	}
	return { unitOfWeight, weight };
}

export function convertWeightUnits(
	t: TFunction<Namespace>
): ((data: IdLabelDto[]) => { id: string; label: string }[]) | undefined {
	return (data) => [
		...data.filter((val) => val.id !== KILOGRAM_UNIT),
		{ id: TONNE_UNIT, label: t('commonUnit:metric-tons-label') },
	];
}
