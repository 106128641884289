import { CardContent, CardHeader, Typography } from '@mui/material';
import { ReactNode, memo } from 'react';
import { useTranslation } from 'react-i18next';

import { ProductIndividualType } from 'domain/unit/enums';
import { IControl, UnitCreationCard } from 'library/components';
import { Removable } from 'library/types';
import { PCTheme, textOverflowSx } from 'library/utils/themes/PCTheme';

const {
	palette: { grey },
} = PCTheme;

export interface IProductIndividualCardProps extends IControl, Removable {
	actions?: ReactNode;
	description?: string | null;
	generatedUuid: string;
	productNumber?: string | null;
	productType: ProductIndividualType;
	serialNumber: string;
}

export const ForeignProductIndividualCard = memo(({ actions = <>

		</>, description, generatedUuid, isRemoved, productNumber, productType, serialNumber }: IProductIndividualCardProps): JSX.Element => {
	const { t } = useTranslation(['common', 'productType']);

	return (
		<UnitCreationCard
			variant="outlined"
			color="default"
			sx={[
				isRemoved ? { pointerEvents: 'none' } : null,
				{
					backgroundColor: isRemoved ? grey[50] : 'default',
					maxWidth: 20,
				},
			]}
			data-testid={`FPI-${serialNumber || generatedUuid}`}>
			<CardHeader
				action={actions}
				title={
					<Typography variant="h6" fontSize={16}>
						{t(`productType:${productType?.toString()}` as never)}
					</Typography>
				}
			/>
			<CardContent>
				<Typography
					component="p"
					variant="subtitle2"
					fontWeight={400}
					sx={textOverflowSx}>
					{t('common:serial-number')}
					{': '}
					<strong>{serialNumber}</strong>
				</Typography>
				<Typography
					component="p"
					variant="subtitle2"
					fontWeight={400}
					sx={textOverflowSx}>
					{t('common:product-number')}
					{': '}
					<strong>{productNumber}</strong>
				</Typography>
				<Typography
					component="p"
					variant="subtitle2"
					fontWeight={400}
					sx={textOverflowSx}>
					{t('common:description')}
					{': '}
					<strong>{description}</strong>
				</Typography>
			</CardContent>
		</UnitCreationCard>
	);
});
