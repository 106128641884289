import { AccountInfo } from '@azure/msal-common';
import { map } from 'lodash';

import { SecurityMatrixDto } from 'api';
import { ADUser } from 'library/models/ADUser';
import { Area } from 'library/models/Area';
import { TokenClaims } from 'library/models/TokenClaims';
import { User } from 'library/models/User';

export const isExternalUser = ({ isExternal }: User) => isExternal;

export const mapToADUser = (account: AccountInfo | null): ADUser => {
	if (account !== null) {
		const idTokenClaims = account.idTokenClaims;

		if (idTokenClaims !== undefined) {
			const tokenClaims = idTokenClaims as unknown as TokenClaims;

			return {
				id: tokenClaims.claimuserid,
				mail: tokenClaims.claimusermail,
				name: tokenClaims.name,
			} as ADUser;
		}
	}

	throw new Error(`Unable to map active directory user from ${account}.`);
};

const mapToArea = (areaDto: SecurityMatrixDto): Area =>
	({
		name: areaDto.name,
		permissions: areaDto.permissions,
	}) as Area;

export const mapToAreas = (areaDtos: Array<SecurityMatrixDto>): Array<Area> =>
	map(areaDtos, (areaDto) => mapToArea(areaDto));

export const mapToUploaderName = ({ customer, userId }: User) =>
	mapToUploaderNameSpecific(
		userId,
		customer?.customerName,
		customer?.customerNumber
	);

export const mapToUploaderNameSpecific = (
	userId: string,
	customerName: string | null | undefined,
	customerNumber: number | null | undefined
) =>
	customerNumber && customerName
		? `${customerName} (${customerNumber}), ${userId}`
		: userId;
