import { ClickEvent, MenuItem } from '@szhsin/react-menu';
import { map, some } from 'lodash';

import MenuLink from '../MenuLink';
import { IMenuItemsContainer } from '../components/menuFactory/MenuItems';
import { isMenuItem, isSubMenu } from '../menu/MenuGenerator';
import IBaseMenuItem from '../models/IBaseMenuItem';
import IMenu from '../models/IMenu';
import IMenuItem from '../models/IMenuItem';
import ISubMenu from '../models/ISubMenu';

export const renderMenuItems = (
	menuItemsContainer: IMenuItemsContainer,
	onMenuSelect: (label: string) => void,
	onMenuLinkClick: () => void,
	selectedMenu: IMenu | ISubMenu | null
): JSX.Element[] | null => {
	const handleOnSubMenuClick = (e: ClickEvent) => {
		const label: string = e.value;
		onMenuSelect(label);
	};

	const renderChildItems = (childItems?: IBaseMenuItem[]) => {
		if (some(childItems)) {
			return map(childItems, (nestedItem) => {
				const menuItem = nestedItem as IMenuItem;
				const subMenuItem = nestedItem as ISubMenu;

				if (!isSubMenu(subMenuItem) && isMenuItem(menuItem)) {
					return (
						<MenuItem
							key={menuItem.label}
							onClick={onMenuLinkClick}
							className="menu__dropdown__list__item"
						>
							{menuItem.label}
							{menuItem.children}
							<MenuLink url={menuItem.route} />
						</MenuItem>
					);
				} else {
					return (
						<MenuItem
							value={menuItem.label}
							onClick={handleOnSubMenuClick}
							key={menuItem.label}
							className="menu__dropdown__list__item menu__dropdown__list__item--sub-menu"
						>
							{menuItem.label}
							<a></a>
						</MenuItem>
					);
				}
			});
		}

		return null;
	};

	const renderItems = (): JSX.Element[] | null => {
		if (selectedMenu === null) {
			return renderChildItems(menuItemsContainer.menuItems);
		} else if ((selectedMenu as IMenu).menuItems !== undefined) {
			return renderChildItems((selectedMenu as IMenu).menuItems);
		} else {
			return renderChildItems((selectedMenu as ISubMenu).subMenuItems);
		}
	};

	return renderItems();
};
