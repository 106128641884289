import { AttachmentFileSet } from 'api';

import { FileUploadState } from './Types';

export const mapFileUploadStateToAttachmentFileSet = (
	fileState: FileUploadState
): AttachmentFileSet => ({
	profilePicturePath: null, // only used in unit registration
	folderName: fileState.attachmentFolderName,
	uploadedFiles: fileState.filesUploaded.map(
		(fileData) => fileData.serverName
	),
});
