import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ChangedByInfoDto } from 'api';
import { Namespace, ParseKeys } from 'i18next';
import { TextDisplay } from 'library/components';
import {
	formatValueWithSecondInBracket,
	getLocalizedDateFormat,
} from 'library/formatters';
import { Sxable } from 'library/types';

export interface ChangedByInfoDisplayProps extends Sxable {
	label?: ParseKeys<Namespace>;
	changedByInfo?: ChangedByInfoDto | null;
}

export const ChangedByInfoDisplay = ({
	label = 'common:updated-by',
	changedByInfo,
	sx,
}: ChangedByInfoDisplayProps): JSX.Element => {
	const { t } = useTranslation(['common']);

	if (!changedByInfo || !changedByInfo.changedByUserId) {
		return <Box />;
	}

	return (
		<TextDisplay
			label={t(label as ParseKeys<'common'>)}
			value={
				<>
					{changedByInfo.changedByUserId}
					<p>
						{formatValueWithSecondInBracket(
							changedByInfo.changedByCustomerOrgName,
							changedByInfo.changedByCustomerNumber
						)}
					</p>
				</>
			}
			helperText={getLocalizedDateFormat(changedByInfo.changedOnDate)}
			sx={sx}
		/>
	);
};
