import { Close } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { isEmpty } from 'lodash';

import { AlertType } from 'library/models/enums/AlertType';

export interface AlertProps {
	type: AlertType;
	message: string | null;
	customHeader?: string;
	onCloseClick: () => void;
}

export const Alert = ({
	type,
	message,
	customHeader,
	onCloseClick,
}: AlertProps): JSX.Element => {
	const getHeaderText = (): string => {
		if (customHeader !== undefined && !isEmpty(customHeader)) {
			return customHeader;
		} else {
			switch (type) {
				case AlertType.Info:
					return 'Info';
				case AlertType.Success:
					return 'Success!';
				case AlertType.Error:
					return 'Error';
				case AlertType.Warning:
					return 'Warning!';
			}
		}
	};

	const getAlertCSSClassNames = (): string => {
		return `alert alert-${getBootstrapCSSClassName()} screen__alert__component`;
	};

	const getBootstrapCSSClassName = (): string => {
		switch (type) {
			case AlertType.Info:
				return 'info';
			case AlertType.Success:
				return 'success';
			case AlertType.Error:
				return 'danger';
			case AlertType.Warning:
				return 'warning';
		}
	};

	return (
		<div className="screen__alert container">
			<div className={getAlertCSSClassNames()} role="alert">
				<IconButton
					aria-label="Close"
					className="btn-close screen__alert__component__button"
					onClick={onCloseClick}>
					<Close />
				</IconButton>
				<h4 className="alert-heading">{getHeaderText()}</h4>
				<p>{message}</p>
			</div>
		</div>
	);
};
