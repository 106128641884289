import { SvgIconProps } from '@mui/material';

import { GeneralIcon } from 'assets/images/documents';

import { fileTypeToIconMap } from './functions';

export function FileIcon({
	mimeType,
	...props
}: {
	mimeType: string;
} & SvgIconProps): JSX.Element {
	const FileTypeIcon = fileTypeToIconMap[mimeType] || GeneralIcon;
	return <FileTypeIcon {...props} />;
}
