import i18n from 'i18next';
import HttpBackend, { HttpBackendOptions } from 'i18next-http-backend';
import resourcesToBackend from 'i18next-resources-to-backend';
import { initReactI18next } from 'react-i18next';

import { namespaces } from '../i18n/configLanguages';

const base = import.meta.env.BASE_URL;
const hash = import.meta.env.VITE_TRANSLATION_RESOURCE_HASH;
const defaultNS = 'common';
i18n.use(
	resourcesToBackend(
		(language: string, namespace: string) =>
			import(
				`./${
					base.startsWith('/') ? base.slice(1) : base
				}locales/${language}/${namespace}-${hash}.json`
			)
	)
)
	.use(HttpBackend)
	.use(initReactI18next)
	.init<HttpBackendOptions>({
		backend: {
			loadPath: `${base}locales/{{lng}}/{{ns}}-${hash}.json`,
		},
		lng: 'enUS',
		fallbackLng: 'enUS',
		returnNull: false,
		ns: namespaces,
		defaultNS,
		resources: {},
		partialBundledLanguages: true, // load resources using backend connector
	});

export default i18n;
