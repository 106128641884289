/* eslint-disable @typescript-eslint/no-explicit-any */
import { BaseRequest, IRequest } from './BaseRequest';

export interface IGetRequest extends IRequest {
	params?: any;
	paramsSerializer?: any;
}

export class GetRequest extends BaseRequest implements IGetRequest {
	params?: any;
	paramsSerializer?: any;

	constructor(getRequest: IGetRequest) {
		super(getRequest);

		this.params = getRequest.params;
		this.paramsSerializer = getRequest.paramsSerializer;
	}
}
