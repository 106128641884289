import { lazy } from 'react';
import { Outlet, Route } from 'react-router-dom';

import { AreaName } from 'domain/constants';
import { OperationType } from 'library/models/enums/OperationType';
import { createArea } from 'library/utils/helpers/AreaHelper';
import Authorize from 'main/auth/Authorize';

import { PageRedirect } from './PageRedirect';
import {
	drivelineviewComponentsAndSoftware,
	drivelineviewProtocols,
	drivelineviewSoftwareParameters,
	drivelineviewVariants,
	drivelineviewWarranty,
} from './urls/RouteUrls';

const DrivelineviewPage = lazy(
	() => import('pages/drivelineview/DrivelineviewPage')
);
const WarrantySection = lazy(
	() => import('pages/drivelineview/sectionPages/WarrantySectionPage')
);
const VariantsSection = lazy(
	() => import('pages/drivelineview/sectionPages/VariantsSectionPage')
);
const ComponentsAndSoftwareSection = lazy(
	() =>
		import(
			'pages/drivelineview/sectionPages/ComponentsAndSoftwareSectionPage'
		)
);
const SoftwareParametersSection = lazy(
	() =>
		import('pages/drivelineview/sectionPages/SoftwareParametersSectionPage')
);
const ServiceProtocolsSection = lazy(
	() => import('pages/drivelineview/sectionPages/ServiceProtocolsSectionPage')
);

export const getDrivelineviewRoutes = (): JSX.Element[] => [
	<Route
		key={'drivelineview'}
		element={
			<Authorize area={createArea(AreaName.Unit, OperationType.Read)}>
				<Outlet />
			</Authorize>
		}>
		<Route element={<DrivelineviewPage />}>
			<Route
				index
				element={<PageRedirect url={drivelineviewProtocols} />}
			/>
			<Route element={<WarrantySection />} path={drivelineviewWarranty} />
			<Route element={<VariantsSection />} path={drivelineviewVariants} />
			<Route
				element={<ComponentsAndSoftwareSection />}
				path={drivelineviewComponentsAndSoftware}
			/>
			<Route
				element={<SoftwareParametersSection />}
				path={drivelineviewSoftwareParameters}
			/>
			<Route
				element={<ServiceProtocolsSection />}
				path={drivelineviewProtocols}
			/>
		</Route>
	</Route>,
];
