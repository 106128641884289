import { AreaName } from 'domain/constants';
import { CommentAnchor } from 'library/components/controls/comments';
import { useDialogState } from 'library/hooks';
import { OperationType } from 'library/models/enums/OperationType';
import { useAppSelector } from 'library/redux/hooks';
import { createArea } from 'library/utils/helpers/AreaHelper';
import Authorize from 'main/auth/Authorize';
import { selectProductIndividualCommentsCount } from 'modules/UnitOverview/State/Selectors';

import { ProductIndividualCardActionProps } from '../ProductIndividualCard';
import { ProductIndividualCardCommentsDialog } from './';

export const ProductIndividualComments = ({
	disabled,
	id,
	serialNumber,
	...props
}: ProductIndividualCardActionProps): JSX.Element | null => {
	const { open, handleOpen, handleClose } = useDialogState(false);
	const count = useAppSelector(selectProductIndividualCommentsCount(id));

	return count != null ? (
		<Authorize
			area={createArea(AreaName.UnitComments, OperationType.Read)}
			displayIntermediatePages={false}
			redirectWhenNotAuthorized={false}>
			<CommentAnchor
				count={count}
				dataTestId={`CommentAnchor-${id}`}
				disabled={disabled}
				onClick={handleOpen}
			/>
			{open && (
				<ProductIndividualCardCommentsDialog
					onClose={handleClose}
					open={open}
					id={id}
					serialNumber={serialNumber}
					{...props}
				/>
			)}
		</Authorize>
	) : null;
};
