import { Box } from '@mui/material';
import { noop, slice } from 'lodash';
import { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';

import { InfinityScrollProps, Item, withMemoItem } from './Item';

export const InfinityScroll = withMemoItem(
	<T extends Item, P>({
		data = [],
		Item,
		itemProps = {} as P,
		onClick = noop,
		size = 10,
	}: InfinityScrollProps<T, P>): JSX.Element => {
		const [index, setIndex] = useState<number>(0);
		const [selectedItemId, setSelectedItemId] = useState<
			number | string | null
		>(null);
		const { ref, inView } = useInView();

		useEffect(() => {
			setIndex(data.length < size ? data.length : size);
		}, [data, size]);

		useEffect(() => {
			if (inView && index < data.length) {
				setIndex((current) => {
					const nextIndex = current + size;
					return nextIndex > data.length ? data.length : nextIndex;
				});
			}
		}, [inView]);

		const handleOnClick = (item: T) => {
			onClick(item.id === selectedItemId ? null : item);
			setSelectedItemId((current) =>
				current === item.id ? null : item.id
			);
		};

		return (
			<>
				{slice(data, 0, index).map((item) => (
					<Item
						key={item.id}
						isSelected={item.id === selectedItemId}
						item={item}
						onClick={handleOnClick}
						{...itemProps}
					/>
				))}
				<Box
					ref={ref}
					id="infinity-scroll-loader"
					sx={{
						width: '100%',
						height: '10px',
						visibility: 'hidden',
					}}
				/>
			</>
		);
	}
) as <T extends Item, P>(props: InfinityScrollProps<T, P>) => JSX.Element;
