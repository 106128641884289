import { noop, trim } from 'lodash';
import { UseFormReturn } from 'react-hook-form';

import { t } from 'i18next';
import { ControlledTextField } from 'library/components';
import { Comment } from 'library/models/Comment';
import { Sxable } from 'library/types';

interface ProductIndividualCommentForm extends Sxable {
	control: UseFormReturn<Pick<Comment, 'description'>>['control'];
	onSubmit?: () => void;
}

export const ProductIndividualCommentForm = ({
	control,
	onSubmit = noop,
	sx = {},
}: ProductIndividualCommentForm) => (
	<form noValidate onSubmit={onSubmit}>
		<ControlledTextField
			control={control}
			label={t('unitOverview:product-individual-comment-dialog-label')}
			multiline
			maxRows={4}
			name={'description'}
			sx={{
				marginTop: ({ spacing }) => ({
					sm: spacing(2.5),
				}),
				width: '100%',
				...sx,
			}}
			validation={{
				required: 'common:validation-error-message',
				validate: (value: string) =>
					!trim(value).length
						? 'common:validation-error-message'
						: true,
			}}
		/>
	</form>
);
