import {
	Configuration,
	LogLevel,
	PopupRequest,
	RedirectRequest,
	SilentRequest,
} from '@azure/msal-browser';

import { isDevEnv } from 'library/constants';

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
	auth: {
		clientId: import.meta.env.VITE_MSAL_CLIENT_ID ?? '',
		authority: import.meta.env.VITE_MSAL_AUTHORITY ?? '',
		redirectUri: import.meta.env.VITE_MSAL_REDIRECT_URI ?? '',
		postLogoutRedirectUri:
			import.meta.env.VITE_MSAL_POST_LOGOUT_REDIRECT_URI ?? '',
	},
	system: {
		loggerOptions: {
			loggerCallback: (
				level: LogLevel,
				message: string,
				containsPii: boolean
			): void => {
				if (containsPii || !isDevEnv) {
					return;
				}
				switch (level) {
					case LogLevel.Error:
						console.error(message);
						return;
					case LogLevel.Info:
						console.info(message);
						return;
					case LogLevel.Verbose:
						console.debug(message);
						return;
					case LogLevel.Warning:
						console.warn(message);
						return;
				}
			},
			piiLoggingEnabled: false,
		},
	},
	cache: {
		cacheLocation: 'sessionStorage',
	},
};

const scopes = [
	import.meta.env.VITE_MSAL_SCOPE_1 ?? '',
	import.meta.env.VITE_MSAL_SCOPE_2 ?? '',
];

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: PopupRequest = {
	scopes: scopes,
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const redirectRequest: RedirectRequest = {
	scopes: scopes,
};

export const silentRequest: SilentRequest = {
	scopes: scopes,
};

export const aiKey = import.meta.env.VITE_AI_INSTRUMENTATION_KEY;
