import { Alert, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const EVCStatusError = () => {
	const { t } = useTranslation(['unitOverview']);
	return (
		<Alert severity="error">
			<Typography>
				{t(
					'unitOverview:message-action-not-allowed-control-system-not-registered'
				)}
			</Typography>
		</Alert>
	);
};
