import { DrivelineSubSystem } from 'api/responses/models/Unit/UnitStructure/DrivelineSubSystem';

export const isControlOnDrive = ({
	productIndividualSubSystems,
}: DrivelineSubSystem) =>
	productIndividualSubSystems
		.flatMap((p) => p.productIndividuals)
		.some(({ productCategory }) => productCategory === 'ControlSystem');

export const drivelineWithControlSystemOnTop = (
	driveline1: DrivelineSubSystem,
	driveline2: DrivelineSubSystem
) => {
	const anyControlOnDrive1 = isControlOnDrive(driveline1);
	const anyControlOnDrive2 = isControlOnDrive(driveline2);

	if (!anyControlOnDrive1 && anyControlOnDrive2) {
		return 1;
	}
	if (anyControlOnDrive1 && !anyControlOnDrive2) {
		return -1;
	}
	return 0;
};
