import { SelectOption, SelectProps } from './SelectCommon';
import { SelectGeneric, SelectGenericProps } from './SelectGeneric';

export const Select = (props: SelectProps<SelectOption>) => {
	const extendedProps: SelectGenericProps<SelectOption> = {
		...props,
		getOptionValue: (item: SelectOption) => item.value,
		getOptionText: (item: SelectOption) => item.text,
	};

	return SelectGeneric(extendedProps);
};
