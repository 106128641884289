import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { FieldPath, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useProductCenterApi } from 'api';
import { notificationLanguagesQuery } from 'api/queries';
import { ParseKeys } from 'i18next';
import {
	ControlledSelect,
	ControlledSelectProps,
} from 'library/components/controls';

export type NotificationLanguageSelectProps<
	TFieldValues extends FieldValues = FieldValues,
	TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = Omit<ControlledSelectProps<TFieldValues, TName>, 'options'>;

export const NotificationLanguageSelect = <
	TFieldValues extends FieldValues = FieldValues,
	TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
	props: NotificationLanguageSelectProps<TFieldValues, TName>
): JSX.Element => {
	const { t } = useTranslation(['customer']);

	const { api } = useProductCenterApi();
	const notificationLanguagesQueryResult = useQuery(
		notificationLanguagesQuery(api)
	);

	const notificationLanguagesData = useMemo(
		() =>
			notificationLanguagesQueryResult.data?.map((cc) => ({
				id: cc,
				label: t(
					`customer:NotificationLanguage_${cc.replace(
						'-',
						'_'
					)}` as ParseKeys<'customer'>
				),
			})),
		[notificationLanguagesQueryResult, t]
	)?.sort((a, b) => a.label.localeCompare(b.label));

	return (
		<ControlledSelect
			options={notificationLanguagesData || []}
			{...props}
		/>
	);
};
