import { InfoOutlined } from '@mui/icons-material';
import { IconButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { CustomInfoDialog } from 'library/components/controls';
import { useDialogState } from 'library/hooks/useDialogState';

export const GdprDialog = (): JSX.Element => {
	const { t } = useTranslation('customer');
	const { open, handleOpen, handleClose } = useDialogState(false);

	return (
		<>
			<IconButton onClick={handleOpen}>
				<InfoOutlined color="secondary" />
			</IconButton>
			<CustomInfoDialog
				dialogTitle={t('register-info-to-consumer-title')}
				headerText={
					<Typography sx={{ textDecoration: 'underline' }}>
						{t('register-info-to-consumer-header')}
					</Typography>
				}
				open={open}
				onClose={handleClose}>
				<Typography gutterBottom>
					{t('register-info-to-consumer-line1')}
				</Typography>
				<Typography gutterBottom>
					{t('register-info-to-consumer-line2')}
				</Typography>
				<Typography gutterBottom>
					{t('register-info-to-consumer-line3')}
				</Typography>
				<Typography>{t('register-info-to-consumer-line4')}</Typography>
			</CustomInfoDialog>
		</>
	);
};
