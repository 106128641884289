import { BaseRequest, IRequest } from './BaseRequest';

export interface IDeleteRequest extends IRequest {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	params?: any;
}

export class DeleteRequest extends BaseRequest implements IDeleteRequest {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	params: any;

	constructor(deleteRequest: IDeleteRequest) {
		super(deleteRequest);

		this.method = 'DELETE';
		this.params = deleteRequest.params;
	}
}
