import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { getI18nLanguage } from 'i18n';
import { Alert } from 'library/components/alerts';
import Loader from 'library/components/loaders/Loader';
import {
	hideAlert,
	selectAlertsAndNotifications,
	selectUser,
} from 'library/redux/application';
import { useAppDispatch, useAppSelector } from 'library/redux/hooks';

const Global = () => {
	const { i18n } = useTranslation();
	const { language } = useAppSelector(selectUser);
	const {
		loader: { isVisible: loaderIsVisible, message: loaderMessage },
		alert: {
			customHeader,
			isVisible: alertIsVisible,
			message: alertMessage,
			shouldBeCloseManually,
			type,
			visibilityTime,
		},
	} = useAppSelector(selectAlertsAndNotifications);
	const dispatch = useAppDispatch();

	useEffect(() => {
		i18n.changeLanguage(getI18nLanguage(language));
	}, [i18n, language]);

	useEffect(() => {
		if (alertIsVisible && !shouldBeCloseManually) {
			setTimeout(() => {
				dispatch(hideAlert());
			}, visibilityTime);
		}
	}, [dispatch, alertIsVisible, shouldBeCloseManually, visibilityTime]);

	const renderLoader = useCallback(
		() =>
			loaderIsVisible ? (
				<Loader translatedText={loaderMessage ?? ''} />
			) : (
				<></>
			),
		[loaderIsVisible, loaderMessage]
	);

	const renderAlert = useCallback(
		() =>
			alertIsVisible ? (
				<Alert
					message={alertMessage}
					type={type}
					customHeader={customHeader}
					onCloseClick={hideAlert}
				/>
			) : (
				<></>
			),
		[customHeader, alertIsVisible, alertMessage, type]
	);

	const shouldRenderScreen = useMemo(
		() => alertIsVisible || loaderIsVisible,
		[alertIsVisible, loaderIsVisible]
	);

	return shouldRenderScreen ? (
		<div className="screen">
			<div className="screen__background">
				{renderLoader()}
				{renderAlert()}
			</div>
		</div>
	) : (
		<></>
	);
};

export default Global;
