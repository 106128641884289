import { ApplicationApi } from 'api';
import { mapToModelFromDto } from 'api/responses/models/SoftwareKit/KitDto';

export const swkKeys = {
	all: ['software-kit'] as const,
	getKits: (unitId: number) => [...swkKeys.all, 'kits', unitId] as const,
};

export const unitKitsQuery = (api: ApplicationApi, unitId: number) => ({
	queryKey: swkKeys.getKits(unitId),
	queryFn: async () =>
		(await api.getKits({ unitId })).data.map((dto) =>
			mapToModelFromDto(dto)
		),
});
