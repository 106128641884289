import {
	Button,
	ButtonProps,
	IconButton,
	IconButtonProps,
	Theme,
	Tooltip,
	useMediaQuery,
} from '@mui/material';
import { HTMLAttributeAnchorTarget, useMemo } from 'react';
import { Link, LinkProps } from 'react-router-dom';

interface SectionButtonProps
	extends Pick<ButtonProps, 'onClick' | 'href' | 'disabled' | 'variant'>,
		Pick<IconButtonProps, 'edge'> {
	text?: string;
	icon: React.ReactNode;
	isLink?: boolean;
	to?: LinkProps['to'];
	target?: HTMLAttributeAnchorTarget;
}

export const SectionButton = ({
	text,
	icon,
	isLink = false,
	to = '',
	disabled,
	edge,
	variant = 'outlined',
	...buttonProps
}: SectionButtonProps): JSX.Element => {
	const isXs = useMediaQuery((theme: Theme) => theme.breakpoints.only('xs'));

	const commonProps = isLink
		? {
				...buttonProps,
				component: Link,
				to,
				disabled,
				target: buttonProps.target,
			}
		: { disabled, ...buttonProps };
	const onlyIconSx = useMemo(
		() =>
			!text
				? {
						padding: 1,
						minWidth: 'unset',
						span: {
							margin: 0,
						},
					}
				: {},
		[text]
	);

	return isXs ? (
		<Tooltip title={text}>
			<span>
				<IconButton sx={onlyIconSx} {...commonProps}>
					{icon}
				</IconButton>
			</span>
		</Tooltip>
	) : (
		<Button
			variant={variant}
			startIcon={edge === 'start' ? icon : undefined}
			endIcon={edge !== 'start' ? icon : undefined}
			sx={onlyIconSx}
			{...commonProps}>
			{text}
		</Button>
	);
};
