import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { useProductCenterApi } from 'api';
import { demoUsesQuery } from 'api/queries';
import {
	LoadingWrapper,
	PageContentTab,
	SectionTitle,
} from 'library/components/controls';
import { reactPlugin } from 'main/azureInsights/config';
import { useNumberParam } from 'modules/Shared/Components/Hooks/useNumberParam';
import { RegisterDemoDealerUsage } from 'modules/UnitOverview/DemoDealerUsage/RegisterDemoDealerUsage';
import { UnregisterDemoDealerUsage } from 'modules/UnitOverview/DemoDealerUsage/UnregisterDemoDealerUsage';

const DemoDealerUsageSectionPage = (): JSX.Element => {
	const { t } = useTranslation(['unitOverview']);
	const unitId = useNumberParam();

	const { api } = useProductCenterApi();
	const { data: demoUsesData, isLoading } = useQuery({
		...demoUsesQuery(api, unitId),
	});

	return (
		<PageContentTab>
			<SectionTitle title={t('unitOverview:demo-usage-title')} />
			<LoadingWrapper loading={isLoading}>
				{demoUsesData?.length ? (
					<RegisterDemoDealerUsage demo={demoUsesData[0]} />
				) : (
					<UnregisterDemoDealerUsage id={unitId} />
				)}
			</LoadingWrapper>
		</PageContentTab>
	);
};

export default withAITracking(
	reactPlugin,
	DemoDealerUsageSectionPage,
	'UnitOverviewDemoDealerUsageSectionPage',
	'application-insight-container'
);
