import { ErrorOutline } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import {
	Dialog,
	DialogContent,
	DialogTitle,
	IconButton,
	Theme,
	useMediaQuery,
} from '@mui/material';
import { PropsWithChildren } from 'react';

import { Sxable } from 'library/types';

interface ErrorDialogProps extends Sxable {
	title: string;
	onClose: () => void;
	open?: boolean;
}

export const ErrorDialog = ({
	title,
	onClose,
	children,
	open = true,
	sx = [],
}: PropsWithChildren<ErrorDialogProps>) => {
	const fullScreen = useMediaQuery((theme: Theme) =>
		theme.breakpoints.only('xs')
	);

	return (
		<Dialog open={open} onClose={onClose} fullScreen={fullScreen} sx={sx}>
			<DialogTitle
				sx={{
					display: 'flex',
					alignItems: 'center',
					bgcolor: 'error.main',
					color: 'error.contrastText',
					pr: 7,
				}}>
				<ErrorOutline sx={{ mr: 1 }} />
				{title}
				<IconButton
					aria-label="close"
					onClick={onClose}
					sx={{
						color: 'error.contrastText',
						position: 'absolute',
						right: 8,
					}}>
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent>{children}</DialogContent>
		</Dialog>
	);
};
