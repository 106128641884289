import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { UnitRegisterCommand, productCenterApi } from 'api';
import { createWizardSubmitThunk } from 'library/hooks/Wizard/WizardSubmitHelpers';
import { createResetStateThunk } from 'library/redux/fileUpload/Thunks';
import { selectAllInstallations } from 'modules/Shared/State/unit';

import {
	mapToInstallation,
	mapToOtherProduct,
	mapToOtherProducts,
	mapToRegistrationPartForSale,
	mapUnitCreationFiles,
	mapUnitStateToUnitInfo,
} from './Mappers';
import { selectOtherPartsList, selectUnitCreationFiles } from './Selectors';

export const fetchOtherPartsAsync = createAsyncThunk(
	'unitCreation/fetchOtherParts',
	async (serialNumbers: string[]) => {
		const response = await productCenterApi.getPartsForSales({
			'serialNumbers[]': serialNumbers,
		});

		if (response?.data) {
			return mapToOtherProducts(response.data);
		}

		return [];
	}
);

export const addOtherProduct = createAsyncThunk(
	'unitCreation/addOtherProduct',
	async (
		{
			partNumber,
			serialNumber,
		}: { partNumber: string; serialNumber?: string },
		{ rejectWithValue }
	) => {
		try {
			const { data } = await productCenterApi.getRegistrationPart({
				partNumber,
			});

			if (!data) {
				return rejectWithValue('Part not found');
			}

			return mapToOtherProduct({
				...data,
				serialNumber,
			});
		} catch (error) {
			return rejectWithValue(error as AxiosError);
		}
	}
);

export const submitUnitRegistration = createWizardSubmitThunk(
	'unitCreation/submitUnitRegistration',
	(root) => {
		const { unit, unitCreation } = root;
		const unitFiles = selectUnitCreationFiles(root);
		const installationsToRegister = selectAllInstallations(root);
		const request: UnitRegisterCommand = {
			unitInfo: mapUnitStateToUnitInfo({ ...unit, ...unitCreation }),
			installations: mapToInstallation(installationsToRegister),
			otherProduct: selectOtherPartsList(root).map(
				mapToRegistrationPartForSale
			),
			attachmentInfo: mapUnitCreationFiles(unitFiles),
		};

		return productCenterApi.registerUnit(request);
	}
);

// Thunk created to reset unit creation files state
export const resetUnitCreationState = createResetStateThunk(
	'unitCreation/resetState',
	(s) => s.unitCreationFiles
);
