import { noop } from 'lodash';
import { PropsWithChildren } from 'react';

export abstract class AuthenticationProvider {
	protected onAuthenticatedCallback: (
		isAuth: boolean,
		message?: string
	) => void = noop;

	public abstract getTokenAsync(): Promise<string>;

	public abstract isAuthenticated(): Promise<boolean>;

	public isRefreshing(): boolean {
		return false;
	}

	public getComponent(): (props: PropsWithChildren) => JSX.Element {
		return AuthenticationProvider.getDefaultComponent();
	}

	public static getDefaultComponent(): (
		props: PropsWithChildren
	) => JSX.Element {
		return ({ children }) => <>{children}</>;
	}

	public onAuthenticated(
		fn: (isAuth: boolean, message?: string) => void
	): void {
		this.onAuthenticatedCallback = fn;
	}
}
