import { MenuDropdownFactory } from '../components/MenuDropdownFactory';
import IMenu from '../models/IMenu';
import { renderMenuItems } from './ScreenMenuItems';

export interface IScreenMenuFactory {
	menu: IMenu;
}

export const ScreenMenuFactory = ({
	menu,
}: IScreenMenuFactory): JSX.Element => {
	return (
		<MenuDropdownFactory
			menu={menu}
			menuBtn={{ menuLabel: menu.label }}
			menuItems={{ renderMenuItems }}
		/>
	);
};
