import { each, find } from 'lodash';

import { Area } from 'library/models/Area';

export const hasUserAccess = (
	area: Area,
	userAvailableAreas: Array<Area>
): boolean => {
	const searchedArea = find(
		userAvailableAreas,
		(availableArea) => availableArea.name === area.name
	);

	let found = false;

	if (searchedArea !== undefined) {
		each(area.permissions, (operationType) => {
			const searchedOperationType = find(
				searchedArea.permissions,
				(searchedAreaOperationType) =>
					searchedAreaOperationType === operationType
			);

			if (searchedOperationType !== undefined) {
				found = true;
			}
		});
	}

	return found;
};
