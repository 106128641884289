import {
	Alert,
	AlertColor,
	AlertTitle,
	Button,
	ButtonProps,
	Dialog,
	DialogActions,
	DialogContent,
	Theme,
	useMediaQuery,
} from '@mui/material';
import { noop } from 'lodash';
import { ReactElement, MouseEvent as ReactMouseEvent, useRef } from 'react';
import useDoubleClick from 'use-double-click';

export interface CustomSeverityDialogProps {
	acceptButtonLabel: string;
	content: string | ReactElement;
	declineButtonLabel?: string;
	disabledAcceptButton?: boolean;
	disabledDeclineButton?: boolean;
	headerText: string;
	isOpen: boolean;
	onAccept: () => void;
	onDecline?: () => void;
	severity?: AlertColor;
}

const BlockedDoubleClickButton = ({
	onClick = noop,
	...props
}: ButtonProps) => {
	const buttonRef = useRef<HTMLButtonElement>(null);

	useDoubleClick<HTMLButtonElement>({
		onSingleClick: (e) => {
			onClick(e as ReactMouseEvent<HTMLButtonElement, MouseEvent>);
		},
		onDoubleClick: noop,
		ref: buttonRef,
		latency: 200,
	});

	return <Button {...props} ref={buttonRef} />;
};

export const CustomSeverityDialog = ({
	acceptButtonLabel,
	content,
	declineButtonLabel,
	disabledAcceptButton = false,
	disabledDeclineButton = false,
	headerText,
	isOpen,
	onAccept,
	onDecline = noop,
	severity = 'warning',
}: CustomSeverityDialogProps): JSX.Element => {
	const fullScreen = useMediaQuery((theme: Theme) =>
		theme.breakpoints.only('xs')
	);

	return (
		<Dialog
			open={isOpen}
			fullScreen={fullScreen}
			sx={{ whiteSpace: 'pre-line' }}
			onClose={disabledDeclineButton ? noop : onDecline}>
			<DialogContent>
				<Alert severity={severity}>
					<AlertTitle>{headerText}</AlertTitle>
					{content}
				</Alert>
			</DialogContent>
			<DialogActions
				sx={{
					justifyContent: declineButtonLabel
						? 'space-between'
						: 'center',
				}}>
				{declineButtonLabel && (
					<Button
						variant="outlined"
						color="secondary"
						disableElevation
						disabled={disabledDeclineButton}
						onClick={onDecline}>
						{declineButtonLabel}
					</Button>
				)}
				<BlockedDoubleClickButton
					variant="contained"
					color="secondary"
					disableElevation
					disabled={disabledAcceptButton}
					onClick={onAccept}>
					{acceptButtonLabel}
				</BlockedDoubleClickButton>
			</DialogActions>
		</Dialog>
	);
};

export default CustomSeverityDialog;
