import { url_vppn_get_token, url_vppn_refresh_token } from 'api/ApiUrls';

const VPPN_AUTH_TOKEN_HEADER_NAME =
	import.meta.env.VITE_VPPN_AUTH_SERVER_TOKEN_HEADER_NAME ||
	'X-Vppn-Auth-Token';
const VPPN_AUTH_SERVER_USER_HEADER_NAME =
	import.meta.env.VITE_VPPN_AUTH_SERVER_USER_HEADER_NAME || 'SM_USER';

const getToken = async (url: string, headers?: Record<string, string>) =>
	await new Promise<string | null>((resolve, reject) => {
		const req = new XMLHttpRequest();
		req.onload = () => {
			resolve(
				req.getResponseHeader(VPPN_AUTH_TOKEN_HEADER_NAME.toLowerCase())
			);
		};
		req.addEventListener('error', (error) => {
			reject(error);
		});
		req.open('GET', url);
		if (headers) {
			Object.keys(headers).forEach((key) => {
				req.setRequestHeader(key, headers[key]);
			});
		}
		req.send();
	});

export const getVppnToken = async () => getToken(url_vppn_get_token);

export const refreshVppnToken = async (userId: string) =>
	getToken(url_vppn_refresh_token, {
		[VPPN_AUTH_SERVER_USER_HEADER_NAME]: userId,
	});
