import * as React from 'react';

import { DisplayDevice } from 'library/models/enums/DisplayDevice';

export interface IToggler {
	toggle: () => void;
}

export interface IToggleButton extends IToggler {
	displayDevice: DisplayDevice;
}

const ToggleButton = ({
	toggle,
	displayDevice,
}: IToggleButton): JSX.Element => {
	const handleOnClick = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.preventDefault();
		toggle();
	};

	const baseClassName = 'header__nav__toggle-btn';

	return (
		<button
			id={displayDevice}
			className={`${baseClassName} ${baseClassName}--${displayDevice}`}
			onClick={handleOnClick}>
			<i className={`${baseClassName}__icon`}></i>
		</button>
	);
};

export default React.memo(ToggleButton);
