import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { Button } from '@mui/material';
import {
	GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
	GridColDef,
	GridRowParams,
} from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { unitStructureQuery } from 'api/queries';
import {
	DrivelineSubSystem,
	getChassisId,
} from 'api/responses/models/Unit/UnitStructure/DrivelineSubSystem';
import { getAllDrivelinesSorted } from 'domain/unit/Unit';
import { PageContentTab, SectionTitle } from 'library/components/controls';
import {
	CustomDetailPanelToggle,
	getDetailPanelAutoHeight,
} from 'library/components/controls/datagrid';
import { LocalizedDataGrid } from 'library/components/controls/datagrid/LocalizedDataGrid';
import { PCTheme } from 'library/utils/themes';
import { reactPlugin } from 'main/azureInsights/config';
import { ServiceProtocolsDataGrid } from 'modules/Drivelineview/Components/ServiceProtocol/ServiceProtocolsDataGrid';
import { ServiceButton } from 'modules/MaintenancePlanner/MaintenanceDataGrid/Components/ServiceButton';
import { useNumberParam } from 'modules/Shared/Components/Hooks/useNumberParam';

const {
	palette: { grey },
} = PCTheme;

const getDetailPanelContent = ({
	row: { id },
}: GridRowParams<DrivelineSubSystem>) => (
	<ServiceProtocolsDataGrid
		drivelineId={id}
		isInnerGrid
		sx={{
			'& .MuiDataGrid-columnHeader': {
				backgroundColor: grey[50],
			},
			'& .MuiDataGrid-main': {
				overflow: 'visible',
			},
		}}
	/>
);

export const ServiceProtocolsPage = (): JSX.Element => {
	const { t } = useTranslation([
		'drivelineview',
		'common',
		'maintenancePlanner',
	]);
	const unitId = useNumberParam();

	const { data } = useQuery({
		...unitStructureQuery(unitId),
	});

	const drivelineList = useMemo(
		() =>
			getAllDrivelinesSorted(data).filter(
				({ isServiceProtocolAvailable }) => isServiceProtocolAvailable
			),
		[data?.installations.length, unitId]
	);

	const columns = useMemo((): GridColDef<DrivelineSubSystem>[] => {
		return [
			{
				...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
				renderCell: (params) => (
					<CustomDetailPanelToggle
						id={params.id}
						value={params.value}
					/>
				),
				display: 'flex',
			},
			{
				field: 'chassisId',
				headerName: t('common:driveline'),
				flex: 1,
				minWidth: 130,
				valueGetter: (_value, row) => getChassisId(row),
			},
			{
				field: 'actions',
				type: 'actions',
				align: 'right',
				flex: 0,
				minWidth: 300,
				cellClassName: 'service-protocol-grid__actions',
				getActions: ({ row }: GridRowParams<DrivelineSubSystem>) => [
					<ServiceButton
						key={'generate'}
						ButtonElement={Button}
						buttonProps={{
							variant: 'outlined',
						}}
						buttonText={t(
							'maintenancePlanner:header-action-generate-protocol'
						)}
						chassisId={getChassisId(row)}
						id={row.id}
					/>,
				],
			},
		];
	}, [t]);

	return (
		<PageContentTab>
			<SectionTitle
				title={t('drivelineview:protocolsSection')}
				withDivider={false}
			/>
			<LocalizedDataGrid
				autoHeight
				columns={columns}
				rows={drivelineList}
				rowHeight={68}
				getDetailPanelHeight={getDetailPanelAutoHeight}
				getDetailPanelContent={getDetailPanelContent}
			/>
		</PageContentTab>
	);
};

export default withAITracking(
	reactPlugin,
	ServiceProtocolsPage,
	'UnitOverviewServiceProtocolsPage',
	'application-insight-container'
);
