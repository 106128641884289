import { TFunction } from 'i18next';

import { DimensionsType } from 'api/responses/models/Unit/UnitMeasurement';

export const dimensionTypes = (
	t: TFunction<'unitCreation'>
): { id: DimensionsType; label: string }[] => [
	{ id: 'Estimated', label: t('weight-type-estimated') },
	{ id: 'Actual', label: t('weight-type-actual') },
];
