import { TextFieldProps } from '@mui/material';
import {
	DatePickerProps,
	DatePicker as MuiDatePicker,
} from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTime } from 'luxon';
import { forwardRef } from 'react';

const {
	navigator: { language },
} = window;

export interface CustomDatePickerProps extends DatePickerProps<DateTime> {
	size: TextFieldProps['size'];
}

/**
 * ```ts
 * Date picker will always save date as UTC, since we can disregard time.
 * ```
 */
export const DatePicker = forwardRef<HTMLDivElement, CustomDatePickerProps>(
	({ ...props }, ref) => (
		<LocalizationProvider
			dateAdapter={AdapterLuxon}
			adapterLocale={language}>
			<MuiDatePicker {...props} ref={ref} timezone="UTC" />
		</LocalizationProvider>
	)
);
