import { useQueryClient } from '@tanstack/react-query';
import { noop } from 'lodash';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { AreaName } from 'domain/constants';
import { BaseDialog, LoadingWrapper } from 'library/components';
import { OperationType } from 'library/models/enums/OperationType';
import { checkUserAccess } from 'main/auth/checkUserAccess';
import { useProductIndividualComments } from 'modules/Shared/Components/Hooks/useProductIndividualComments';

import { ProductIndividualCardActionProps } from '../ProductIndividualCard';
import {
	ProductIndividualCommentForm,
	ProductIndividualCommentsList,
} from './';

interface ProductIndividualCommentsDialogProps
	extends ProductIndividualCardActionProps {
	serialNumber: string;
	onClose?: () => void;
	open?: boolean;
}

let dialogContentDomRef: Element | null = null;
const onSuccess = () => {
	if (dialogContentDomRef) {
		dialogContentDomRef.scroll({
			top: 0,
			behavior: 'smooth',
		});
	}
};

export const ProductIndividualCardCommentsDialog: FC<
	Omit<ProductIndividualCommentsDialogProps, 'productId'>
> = ({
	isForeign = false,
	isReadonly = false,
	onClose = noop,
	open = false,
	id,
	serialNumber,
}) => {
	const { t } = useTranslation(['commonButton', 'unitOverview']);
	const queryClient = useQueryClient();

	const {
		isAdding,
		comments,
		control,
		isData,
		isLoading,
		isSubmitting,
		submit,
	} = useProductIndividualComments(id, serialNumber, isForeign, onSuccess);
	const addCommentAccess = checkUserAccess(queryClient, {
		areaName: AreaName.UnitComments,
		permission: OperationType.Write,
	});

	useEffect(() => {
		if (isData) {
			dialogContentDomRef = document.getElementsByClassName(
				'MuiDialogContent-root'
			)[0];
		}
	}, [isData]);

	return (
		<BaseDialog
			closeButtonText={
				addCommentAccess ? t('commonButton:cancel') : undefined
			}
			confirmButtonText={
				addCommentAccess ? t('commonButton:add') : undefined
			}
			disabled={isReadonly}
			isSubmitting={isSubmitting}
			onConfirm={submit}
			onClose={onClose}
			open={open}
			title={`${t(
				'unitOverview:product-individual-comment-dialog-title'
			)} ${id}`}>
			<LoadingWrapper
				loading={isLoading}
				size="2rem"
				sx={{ margin: (theme) => theme.spacing(2, 0, 2, 0) }}>
				<ProductIndividualCommentsList
					comments={comments}
					isAdding={isAdding}
				/>
			</LoadingWrapper>
			{!isReadonly && addCommentAccess && (
				<ProductIndividualCommentForm
					control={control}
					onSubmit={submit}
				/>
			)}
		</BaseDialog>
	);
};
