import { FileUpload } from '@mui/icons-material';
import { Button, styled } from '@mui/material';
import React, { ChangeEventHandler, ReactNode } from 'react';

import { Sxable } from 'library/types';

const Input = styled('input')({
	display: 'none',
});

interface FilesUploadButtonProps extends Sxable {
	disabled?: boolean;
	id: string;
	onChange: ChangeEventHandler<HTMLInputElement>;
	multiple?: boolean;
	filesAccepted?: string;
	icon?: ReactNode;
	variant?: 'text' | 'outlined' | 'contained';
	color?:
		| 'inherit'
		| 'secondary'
		| 'primary'
		| 'success'
		| 'error'
		| 'info'
		| 'warning'
		| 'black';
}

export const FileUploadButton = ({
	disabled = false,
	id,
	onChange,
	multiple = false,
	filesAccepted = undefined,
	children,
	sx = [],
	color = 'secondary',
	icon = <FileUpload />,
	variant = 'contained',
}: React.PropsWithChildren<FilesUploadButtonProps>) => (
	<label htmlFor={id}>
		<Input
			id={id}
			multiple={multiple}
			onChange={(event) => {
				onChange(event);
				event.target.value = '';
			}}
			type="file"
			accept={filesAccepted}
		/>
		<Button
			disabled={disabled}
			startIcon={icon}
			variant={variant}
			color={color}
			size="large"
			component="span"
			sx={[...(Array.isArray(sx) ? sx : [sx])]}>
			{children}
		</Button>
	</label>
);
