import { Box, styled } from '@mui/material';

export const StyledBoxCardContainer = styled(Box)(({ theme }) => ({
	display: 'flex',
	flexWrap: 'wrap',
	alignItems: 'stretch',
	justifyContent: 'flex-start',
	gap: theme.spacing(2),
	marginTop: theme.spacing(1),

	[theme.breakpoints.down('sm')]: {
		justifyContent: 'center',
	},
}));
