import { Box } from '@mui/material';
import '@szhsin/react-menu/dist/index.css';
import { map } from 'lodash';

import { DisplayDevice } from 'library/models/enums/DisplayDevice';
import { selectMenuItems } from 'library/redux/application';
import { useAppSelector } from 'library/redux/hooks';
import ChangeLanguageButton from 'main/layout/footer/ChangeLanguageButton';

import { getAggregateMenus } from './menu/MenuAggregator';
import { MobileMenuFactory } from './mobile/MobileMenuFactory';
import IMenu from './models/IMenu';
import ISubMenu from './models/ISubMenu';

type MobileMenuProps = {
	isVisible: boolean;
	onMenuSelect: (label: string) => void;
	onMenuLinkClick: () => void;
	displayDevice: DisplayDevice;
	selectedMenu: IMenu | ISubMenu | null;
};

const MobileMenu = ({
	isVisible,
	onMenuSelect,
	onMenuLinkClick,
	selectedMenu,
	displayDevice,
}: MobileMenuProps) => {
	const menu = useAppSelector(selectMenuItems);

	return isVisible ? (
		<>
			<ul id={`${displayDevice}-menu`} className="navbar-nav menu">
				{map(getAggregateMenus(menu), (menuItem) => {
					return (
						<MobileMenuFactory
							menu={menuItem}
							key={menuItem.label}
							onMenuLinkClick={onMenuLinkClick}
							onMenuSelect={onMenuSelect}
							selectedMenu={selectedMenu}
						/>
					);
				})}
			</ul>
			<Box id={`${displayDevice}-language-box`}>
				<ChangeLanguageButton />
			</Box>
		</>
	) : (
		<></>
	);
};

export default MobileMenu;
