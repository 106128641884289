import { SvgIcon, SvgIconProps } from '@mui/material';

export const Remove = (props: SvgIconProps) => (
	<SvgIcon {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12ZM12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM7.85355 7.14645C7.65829 6.95118 7.34171 6.95118 7.14645 7.14645C6.95118 7.34171 6.95118 7.65829 7.14645 7.85355L11.2929 12L7.14645 16.1464C6.95118 16.3417 6.95118 16.6583 7.14645 16.8536C7.34171 17.0488 7.65829 17.0488 7.85355 16.8536L12 12.7071L16.1464 16.8536C16.3417 17.0488 16.6583 17.0488 16.8536 16.8536C17.0488 16.6583 17.0488 16.3417 16.8536 16.1464L12.7071 12L16.8536 7.85355C17.0488 7.65829 17.0488 7.34171 16.8536 7.14645C16.6583 6.95118 16.3417 6.95118 16.1464 7.14645L12 11.2929L7.85355 7.14645Z"
		/>
	</SvgIcon>
);
