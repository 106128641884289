import { FileData } from './Types';

export const mapFileToFileData = (
	file: File,
	serverName: string,
	uploader = '',
	isAdditional = false
) => ({
	name: file.name,
	serverName,
	size: file.size,
	type: file.type,
	lastModified: file.lastModified,
	fileUrl: URL.createObjectURL(file),
	uploader,
	isAdditional,
});

export const mapFileDataToFile = ({
	lastModified,
	name,
	size,
	type,
}: FileData) => ({
	name,
	size,
	type,
	lastModified,
});
