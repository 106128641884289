import {
	CreateUpdateProductInOperationCommand,
	CurrentLocationBaseDto,
	productCenterApi,
} from 'api';
import { createWizardSubmitThunk } from 'library/hooks/Wizard/WizardSubmitHelpers';
import { mapFileUploadStateToAttachmentFileSet } from 'library/redux/fileUpload';
import { createResetStateThunk } from 'library/redux/fileUpload/Thunks';

import { selectProductInOperationFiles, selectUnitId } from './Selectors';

export const resetState = createResetStateThunk(
	'productInOperation/resetState',
	(s) => s.productInOperationFiles
);

export const submitProductInOperation = createWizardSubmitThunk(
	'productInOperation/productInOperation',
	(root) => {
		const productInOperationFiles = selectProductInOperationFiles(root);

		if (
			root.productInOperation.productInOperation
				?.productInOperationDate == null
		) {
			throw new Error('Product in operation date cannot be null');
		}

		const request: CreateUpdateProductInOperationCommand = {
			operatingLocation: root.productInOperation
				.operatingLocation as CurrentLocationBaseDto,
			// small hack, as the date is already formatted in the string, we don't need another conversion
			productInOperationDate: root.productInOperation.productInOperation
				.productInOperationDate as unknown as Date,
			productInOperationComment:
				root.productInOperation.productInOperation
					.productInOperationComment ?? null,
			productInOperationAgreement:
				root.productInOperation.productInOperation
					.productInOperationAgreement ?? false,
			attachmentInfo: mapFileUploadStateToAttachmentFileSet(
				productInOperationFiles
			),
		};

		return productCenterApi.performProductInOperation(
			selectUnitId(root),
			request
		);
	}
);
