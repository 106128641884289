import { Box, Typography } from '@mui/material';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { InstallationSubSystem } from 'api/responses/models/Unit/UnitStructure/InstallationSubSystem';
import { ParseKeys } from 'i18next';
import { TextDisplay } from 'library/components';
import { Sxable } from 'library/types';
import {
	MainSegment,
	shouldDisplayOperatingProfile,
} from 'modules/Shared/State/unit';
import { DetailedSegmentDisplay } from 'modules/UnitOverview/UnitInformation/Components/DetailedSegmentDisplay';

const selectSx = {
	flex: '1 1 25%',
} as const;

export const InstallationHeaderView = memo(
	({
		installation: { detailedSegment, purpose, operatingProfileValue },
		mainSegment,
		sx = { flexDirection: { xs: 'column', sm: 'row' } },
	}: {
		installation: InstallationSubSystem;
		mainSegment: MainSegment | null;
	} & Sxable): JSX.Element => {
		const { t } = useTranslation(['unitCreation', 'commonUnit']);

		const purposeStr = purpose
			? t(`InstallationPurposeValue_${purpose}` as const)
			: null;

		const operatingProfileStr = t(
			`unitCreation:OperatingProfileValue_${operatingProfileValue}` as ParseKeys<'unitCreation'>
		);

		const title =
			t('unitCreation:configuration-installation-title') +
			(purposeStr ? ' - ' + purposeStr : '');

		return (
			<>
				<Box display="flex" gap={2} p={2} sx={sx}>
					<Box sx={selectSx}>
						<Typography variant="h6">{title}</Typography>
					</Box>

					<Box sx={selectSx}>
						<DetailedSegmentDisplay
							mainSegment={mainSegment}
							detailedSegment={detailedSegment}
						/>
					</Box>

					<Box sx={selectSx}>
						<TextDisplay
							label={t(
								'unitCreation:configuration-select-installation-purpose'
							)}
							value={purposeStr}
						/>
					</Box>

					{shouldDisplayOperatingProfile(purpose) &&
					operatingProfileValue ? (
						<Box sx={selectSx}>
							<TextDisplay
								label={t(
									'unitCreation:configuration-select-operating-profile'
								)}
								value={operatingProfileStr}
							/>
						</Box>
					) : (
						<Box sx={selectSx} />
					)}
				</Box>
			</>
		);
	}
);
