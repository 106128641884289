import { SvgIconProps } from '@mui/material';

import { ParseKeys, TOptions } from 'i18next';

// Policy is separate tab, does not need to be supported as main campaign
export type CampaignType = 'Recall' | 'Quality' | 'Service' | 'Policy';

type CampaignTypeTranslation = ParseKeys<['campaign'], TOptions, 'campaign'>;

const campaignTypeToColorMap: {
	[campaignType in CampaignType]: SvgIconProps['color'];
} = {
	Recall: 'error',
	Quality: 'warning',
	Service: 'info',
	Policy: 'disabled', // is not supported as main campaign
};

const campaignTypeToTranslation: {
	[campaignType in CampaignType]: CampaignTypeTranslation;
} = {
	Recall: 'campaign:recall-on-unit',
	Quality: 'campaign:quality-on-unit',
	Service: 'campaign:service-on-unit',
	Policy: 'campaign:quality-on-unit', // is not supported as main campaign, todo - just a fallback value, fix
} as const;

export const mapCampaignTypeToColor = (
	campaignType: CampaignType
): SvgIconProps['color'] | undefined => campaignTypeToColorMap[campaignType];

export const mapCampaignTypeToTranslation = (
	campaignType: CampaignType
): CampaignTypeTranslation => campaignTypeToTranslation[campaignType];
