import { useCallback, useMemo } from 'react';

import { DisplayDevice } from 'library/models/enums/DisplayDevice';
import { selectMenuItems } from 'library/redux/application';
import { useAppSelector } from 'library/redux/hooks';

import UserInfo from './UserInfo';
import MobileMenu from './nav/MobileMenu';
import ScreenMenu from './nav/ScreenMenu';
import { getAggregateMenus } from './nav/menu/MenuAggregator';
import { getMenuByLabel, getParentMenuByLabel } from './nav/menu/MenuGenerator';
import MobileInfoWrapper from './nav/mobile/MobileInfoWrapper';
import { NavMenuProps } from './nav/models/NavMenuProps';

const Nav = ({
	isMobileMenuOpen,
	isTabletMenuOpen,
	selectedMenu,
	setIsMobileMenuOpen,
	setIsTabletMenuOpen,
	setSelectedMenu,
}: NavMenuProps) => {
	const menu = useAppSelector(selectMenuItems);
	const menus = useMemo(() => getAggregateMenus(menu), [menu]);

	const handleOnMenuSelect = (label: string) => {
		setSelectedMenu(getMenuByLabel(label, menus));
	};

	const handleOnGoToPreviousMenu = useCallback(() => {
		if (selectedMenu !== null) {
			setSelectedMenu(getParentMenuByLabel(selectedMenu.label, menus));
		}
	}, [menus, selectedMenu, setSelectedMenu]);

	const handleOnMobileMenuLinkClick = useCallback(() => {
		setIsMobileMenuOpen(false);
		setSelectedMenu(null);
	}, [setIsMobileMenuOpen, setSelectedMenu]);

	const handleOnTabletMenuLinkClick = useCallback(() => {
		setIsTabletMenuOpen(false);
		setSelectedMenu(null);
	}, [setIsTabletMenuOpen, setSelectedMenu]);

	return (
		<div id="header__nav" className="row">
			<nav className="text-uppercase navbar navbar-collapse navbar-expand-lg navbar-light">
				{selectedMenu === null ? (
					<>
						<UserInfo
							displayDevice={DisplayDevice.Mobile}
							isOpen={isMobileMenuOpen}
						/>
						<UserInfo
							displayDevice={DisplayDevice.Tablet}
							isOpen={isTabletMenuOpen}
						/>
					</>
				) : (
					<>
						{isMobileMenuOpen ? (
							<MobileInfoWrapper
								label={selectedMenu.label}
								goToPreviousMenu={handleOnGoToPreviousMenu}
								displayDevice={DisplayDevice.Mobile}
							/>
						) : null}
						{isTabletMenuOpen ? (
							<MobileInfoWrapper
								label={selectedMenu.label}
								goToPreviousMenu={handleOnGoToPreviousMenu}
								displayDevice={DisplayDevice.Tablet}
							/>
						) : null}
					</>
				)}
				<ScreenMenu />
				<MobileMenu
					isVisible={isTabletMenuOpen}
					onMenuSelect={handleOnMenuSelect}
					selectedMenu={selectedMenu}
					onMenuLinkClick={handleOnTabletMenuLinkClick}
					displayDevice={DisplayDevice.Tablet}
				/>
				<MobileMenu
					isVisible={isMobileMenuOpen}
					onMenuSelect={handleOnMenuSelect}
					selectedMenu={selectedMenu}
					onMenuLinkClick={handleOnMobileMenuLinkClick}
					displayDevice={DisplayDevice.Mobile}
				/>
			</nav>
		</div>
	);
};

export default Nav;
