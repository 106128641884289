import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { IdentifiersContainer } from '../../../../UnitOverview/UnitInformation/Components/IdentifiersContainer';
import { useMarineIdentifiersSectionValidation } from '../Validations';
import { IdentifiersMarineFields } from './IdentifiersMarineFields';
import { ExtendedFormSectionProps } from './Types';

export const IdentifiersMarineSection = ({
	control,
	dirtyFields,
	trigger,
	resetField,
	setValue,
}: ExtendedFormSectionProps) => {
	const { t } = useTranslation('unitCreation');

	const isValidSection = useMarineIdentifiersSectionValidation({
		control,
		trigger,
	});

	return (
		<IdentifiersContainer
			title={t('unit-information-identifiers-title')}
			subtitle={
				<Typography
					variant="body2"
					color={isValidSection ? 'unset' : 'error.main'}>
					{t('unit-information-identifiers-description')}
				</Typography>
			}
			isValidSection={isValidSection}
			showAsterisk>
			<IdentifiersMarineFields
				control={control}
				resetField={resetField}
				setValue={setValue}
				dirtyFields={dirtyFields}
			/>
		</IdentifiersContainer>
	);
};
