import '@szhsin/react-menu/dist/index.css';
import { map } from 'lodash';

import { selectMenuItems } from 'library/redux/application';
import { useAppSelector } from 'library/redux/hooks';

import { getAggregateMenus } from './menu/MenuAggregator';
import { ScreenMenuFactory } from './screen/ScreenMenuFactory';

const ScreenMenu = () => {
	const menu = useAppSelector(selectMenuItems);

	return (
		<ul id="screen-menu" className="navbar-nav menu">
			{map(getAggregateMenus(menu), (menuItem) => {
				return (
					<ScreenMenuFactory menu={menuItem} key={menuItem.label} />
				);
			})}
		</ul>
	);
};

export default ScreenMenu;
