import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { IInput } from '../common/IInput';
import { Select, SelectOption } from '../selects';
import { BaseDialog } from './BaseDialog';

export interface ChangeLanguageDialogProps extends IInput {
	isOpen: boolean;
	supportedLanguages: Record<string, string>;
	defaultValue: string;
	onClose: () => void;
	onChangeLanguage: (item: string) => void;
}

export const ChangeLanguageDialog = ({
	defaultValue,
	supportedLanguages,
	isOpen,
	onChangeLanguage,
	onClose,
}: ChangeLanguageDialogProps): JSX.Element => {
	const { t } = useTranslation(['layout', 'language', 'commonButton']);

	return (
		<BaseDialog
			closeButtonText={t('commonButton:cancel')}
			confirmButtonText={t('commonButton:save')}
			onConfirm={() => onChangeLanguage(defaultValue)}
			onClose={onClose}
			open={isOpen}
			title={t('layout:changeLanguage-title')}>
			<>
				<Box mb={3}>{t('layout:changeLanguage-description')}</Box>
				<Select
					label={t('layout:changeLanguage-select')}
					selectedOption={{ value: defaultValue, text: '' }}
					options={Object.entries(supportedLanguages).map(
						([key, value]) =>
							({
								value: value,
								text: t(
									`language:changeLanguage-${value.toLowerCase()}`,
									{ defaultValue: key.toLowerCase() }
								),
							}) as SelectOption
					)}
					onChange={(value: SelectOption) =>
						(defaultValue = value.value as string)
					}
					id="selectLanguage"
				/>
			</>
		</BaseDialog>
	);
};
