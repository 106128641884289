import { Box } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { FieldPath, FieldValues } from 'react-hook-form';

import { useProductCenterApi } from 'api';
import { countryInfosQuery } from 'api/queries';
import { mapCountryInfoToIdLabelCountry } from 'api/responses/models/Configuration/CountryInfoDto';
import {
	ControlledAutocomplete,
	ControlledAutocompleteProps,
	defaultGetOptionLabel,
} from 'library/components/controls';
import CountryFlag from 'library/components/controls/display/CountryFlag';
import { Option } from 'library/models/Option';
import { selectUser } from 'library/redux/application';
import { useAppSelector } from 'library/redux/hooks';

const getOptionCountryCode = (option: any) => option.id ?? '';

export type CountryInputProps<
	TFieldValues extends FieldValues = FieldValues,
	TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = Omit<
	ControlledAutocompleteProps<Option<string>, TFieldValues, TName>,
	'renderOption' | 'options'
>;

export const CountryInput = <
	TFieldValues extends FieldValues = FieldValues,
	TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
	getOptionLabel = defaultGetOptionLabel,
	getOptionId,
	...props
}: CountryInputProps<TFieldValues, TName>): JSX.Element => {
	const languageCode = useAppSelector(selectUser).language;
	const { api } = useProductCenterApi();

	const countriesQuery = useQuery(countryInfosQuery(api, languageCode));

	const countryOptions = countriesQuery.data?.map(
		mapCountryInfoToIdLabelCountry
	);

	return (
		<ControlledAutocomplete
			options={countryOptions || []}
			getOptionLabel={getOptionLabel}
			getOptionId={getOptionId}
			{...props}
			renderOption={(innerProps, option) => (
				<Box
					component="li"
					{...innerProps}
					key={(getOptionId ?? getOptionCountryCode)(option)}>
					<CountryFlag countryCode={getOptionCountryCode(option)} />
					{typeof getOptionLabel === 'function' &&
						getOptionLabel(option)}
				</Box>
			)}
		/>
	);
};
