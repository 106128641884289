import {
	createFileUploadSlice,
	initialFileState,
} from 'library/redux/fileUpload';
import { IFileUploadStateHandlers } from 'modules/Shared/Components/FileUpload/UploadFileSection';

import { selectFiles } from './Selectors';
import { resetState } from './Thunk';

const generateServiceProtocolFilesSlice = createFileUploadSlice({
	name: 'generateServiceProtocolFiles',
	initialState: initialFileState,
	reducers: {},
	extraReducers(builder) {
		builder.addCase(resetState.fulfilled, () => initialFileState);
	},
});

export const { fileUploaded, fileDeleted, attachmentFolderCreated } =
	generateServiceProtocolFilesSlice.actions;

export const handler: IFileUploadStateHandlers = {
	attachmentFolderCreated: attachmentFolderCreated,
	fileDeleted: fileDeleted,
	fileUploaded: fileUploaded,
	fileStateSelector: selectFiles,
};

export default generateServiceProtocolFilesSlice.reducer;
