import { useEffect, useState } from 'react';

import { useProductCenterApi } from 'api';

export enum THUMBNAIL_SIZES {
	SMALL = 128,
	MEDIUM = 256,
	BIG = 512,
}

export const useApiDownloadImage = (
	imageId: number | null | undefined,
	size?: THUMBNAIL_SIZES
) => {
	const { api } = useProductCenterApi();
	const [source, setSource] = useState('');

	useEffect(() => {
		let imageSrc: string;

		imageId
			? (async () => {
					const { data } = await api.downloadImage(
						imageId,
						{
							size: size?.toString(),
						},
						{
							format: 'blob',
						}
					);

					if (data) {
						setSource(URL.createObjectURL(data));
					}
				})()
			: setSource('');

		return () => {
			if (imageSrc != null) {
				URL.revokeObjectURL(imageSrc);
			}
		};
	}, [api, imageId, size]);

	return source;
};
