import {
	EvcUnitStatusResponse,
	UnitControlSystemRegistrationStatus,
} from 'api';
import { ParseKeys, TOptions } from 'i18next';

type EVCStatusTranslations = ParseKeys<
	['unitOverview'],
	TOptions,
	'unitOverview'
>;

export type IncorrectEvcStatus = Exclude<
	UnitControlSystemRegistrationStatus,
	'Ok'
>;

const evcStatusToTranslation: {
	[evcStatus in IncorrectEvcStatus]: EVCStatusTranslations;
} = {
	NotRegisterd: 'unitOverview:evc-control-system-status-not-registered',
	Updated: 'unitOverview:evc-control-system-status-update-blocked',
	UpdateBlocked: 'unitOverview:evc-control-system-status-update-blocked',
	RegistrationIncorrect:
		'unitOverview:evc-control-system-status-registration-incorrect',
};

export const mapEvcStatusToTranslation = (
	evcStatus: IncorrectEvcStatus
): EVCStatusTranslations => evcStatusToTranslation[evcStatus];

export const isControlSystemStatusCorrect = (
	unitEvcStatus?: EvcUnitStatusResponse | null
) => unitEvcStatus?.unitControlSystemStatus === 'Ok';
