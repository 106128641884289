import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { useProductCenterApi } from 'api';
import { detailedSegmentQuery } from 'api/queries';
import { MainSegment } from 'api/responses/models/Unit/UnitStructure/MainSegment';
import { DetailedSegment } from 'domain/unit/enums';
import { TextDisplay } from 'library/components';

export const DetailedSegmentDisplay = ({
	mainSegment,
	detailedSegment,
}: {
	mainSegment: MainSegment | null;
	detailedSegment: DetailedSegment | null;
}): JSX.Element => {
	const { t } = useTranslation('commonUnit');

	const { api } = useProductCenterApi();
	const detailedSegmentsQueryResult = useQuery(
		detailedSegmentQuery(api, mainSegment)
	);
	const detailedSegmentStr = detailedSegmentsQueryResult.data?.find(
		(li) => li.value === detailedSegment?.toString()
	)?.text;

	return (
		<TextDisplay label={t('detailed-segment')} value={detailedSegmentStr} />
	);
};
