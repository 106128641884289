import { UseQueryOptions } from '@tanstack/react-query';

import { ApplicationApi, CountryInfoDto, SelectItemDto } from 'api';
import { infinityCacheTime } from 'api/ReactQueryOptions';

export const notificationLanguagesQuery = (api: ApplicationApi) => ({
	queryKey: configurationKeys.notificationLanguages(),
	queryFn: async () => (await api.getNotificationLanguages()).data,
	...infinityCacheTime<string[]>(),
});

type QueryKeyType = ReturnType<typeof configurationKeys.countryInfos>;

export const countryInfosQuery = (
	api: ApplicationApi,
	languageCode: string
): UseQueryOptions<
	CountryInfoDto[],
	Error,
	CountryInfoDto[],
	QueryKeyType
> => ({
	queryKey: configurationKeys.countryInfos(languageCode),
	queryFn: async () =>
		(await api.getCountryList({ languageCountryCode: languageCode })).data,
	select: (data) =>
		data.sort((a, b) =>
			(a.translatedCountryName || '').localeCompare(
				b.translatedCountryName || ''
			)
		),
	...infinityCacheTime<CountryInfoDto[], QueryKeyType>(),
});

export const hullIdStandardQuery = (
	api: ApplicationApi,
	countryCode: string
) => ({
	queryKey: configurationKeys.hullIdStandard(countryCode),
	queryFn: async () => (await api.getHullIdStandards({ countryCode })).data,
	...infinityCacheTime<SelectItemDto[]>(),
});

export const weightUnitsQuery = (api: ApplicationApi, countryCode: string) => ({
	queryKey: configurationKeys.weightUnits(countryCode),
	queryFn: async () => (await api.getWeightUnits({ countryCode })).data,
	...infinityCacheTime<SelectItemDto[]>(),
});

export const lengthUnitsQuery = (api: ApplicationApi, countryCode: string) => ({
	queryKey: configurationKeys.lengthUnits(countryCode),
	queryFn: async () => (await api.getLengthUnits({ countryCode })).data,
	...infinityCacheTime<SelectItemDto[]>(),
});

export const configurationKeys = {
	all: ['configuration'] as const,
	lengthUnits: (countryCode: string) =>
		[...configurationKeys.all, 'length-units', countryCode] as const,
	weightUnits: (countryCode: string) =>
		[...configurationKeys.all, 'weight-units', countryCode] as const,
	hullIdStandard: (language: string) =>
		[...configurationKeys.all, 'hullIdStandard', language] as const,
	countryInfos: (language: string) =>
		[...configurationKeys.all, 'countryInfos', language] as const,
	notificationLanguages: () =>
		[...configurationKeys.all, 'notificationLanguages'] as const,
};
