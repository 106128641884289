import { Location } from 'react-router-dom';

import { selectHistory } from 'library/redux/application/Selectors';
import { useAppSelector } from 'library/redux/hooks';

export const useHistory = () => {
	const { current, previous } = useAppSelector(selectHistory);

	const includes = (text: string, location?: Location) =>
		location?.pathname.includes(text);
	const includesCurrent = (text: string) => includes(text, current);
	const includesPrevious = (text: string) => includes(text, previous);

	return { current, includesCurrent, includesPrevious, previous };
};
