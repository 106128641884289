import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { useTranslation } from 'react-i18next';

import { PageContentTab } from 'library/components/controls';
import { reactPlugin } from 'main/azureInsights/config';
import { useNumberParam } from 'modules/Shared/Components/Hooks/useNumberParam';
import { LegacySection } from 'modules/UnitOverview/Components/LegacySection';

const DiagnosticSectionPage = (): JSX.Element => {
	const { t } = useTranslation(['unitOverview']);
	const unitId = useNumberParam();

	return (
		<PageContentTab>
			<LegacySection
				unitId={unitId}
				mvcAction={'RemoteReadout/Index'}
				sectionTitle={t('unitOverview:diagnostic-title')}
				buttonLabel={t('unitOverview:show-diagnostic-button')}
			/>
		</PageContentTab>
	);
};

export default withAITracking(
	reactPlugin,
	DiagnosticSectionPage,
	'UnitOverviewDiagnosticSectionPage',
	'application-insight-container'
);
