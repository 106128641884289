import { Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Namespace, ParseKeys } from 'i18next';

interface AccordionActionButtonsProps {
	cancelLabel?: ParseKeys<Namespace>;
	disableSubmit?: boolean;
	onClose?: () => void;
	submitLabel?: ParseKeys<Namespace>;
	submit?: () => void;
}

export const AccordionActionButtons = ({
	cancelLabel = 'commonButton:cancel',
	disableSubmit = false,
	onClose,
	submitLabel = 'commonButton:save',
	submit,
}: AccordionActionButtonsProps) => {
	const { t } = useTranslation();

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'row-reverse',
			}}
			gap={2}>
			<Button
				variant="contained"
				color="secondary"
				disableElevation
				disabled={disableSubmit}
				type={submit ? 'button' : 'submit'}
				onClick={submit}>
				{t(submitLabel, { defaultValue: 'error-unexpected' })}
			</Button>
			{onClose && (
				<Button
					variant="outlined"
					color="secondary"
					disableElevation
					type="button"
					onClick={onClose}>
					{t(cancelLabel, { defaultValue: 'error-unexpected' })}
				</Button>
			)}
		</Box>
	);
};
