import { ChatBubbleOutline } from '@mui/icons-material';
import { Badge } from '@mui/material';
import { FC } from 'react';

import { AnchorProps, IconAnchor } from '../buttons/Anchor';

interface CommentAnchorProps extends Omit<AnchorProps, 'Icon'> {
	count: number;
}

export const CommentAnchor: FC<CommentAnchorProps> = ({ count, ...props }) => (
	<Badge
		badgeContent={count}
		overlap="circular"
		color={props.disabled ? 'gray' : 'secondary'}
		sx={{ '& > .MuiBadge-badge': { top: '24%', right: '24%' } }}>
		<IconAnchor Icon={ChatBubbleOutline} {...props} />
	</Badge>
);
