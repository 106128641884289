import { useParams, useSearchParams } from 'react-router-dom';

export const useNumberParam = (paramName = 'unitId'): number => {
	const params = useParams();
	const unitIdParsed = Number(params[paramName]);

	// to check if it's an integer number excluding float
	if (unitIdParsed === 0 || isNaN(unitIdParsed) || unitIdParsed % 1 !== 0) {
		throw Error('Id is not a valid.');
	}

	return unitIdParsed;
};

export const useNumberSearchParam = (paramName = 'unitId'): number => {
	const [params] = useSearchParams();
	const param = params.get(paramName);
	const unitIdParsed = Number(param);

	// to check if it's an integer number excluding float
	if (unitIdParsed === 0 || isNaN(unitIdParsed) || unitIdParsed % 1 !== 0) {
		throw Error('Id is not a valid.');
	}

	return unitIdParsed;
};
