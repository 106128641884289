import {
	ToggleButton,
	ToggleButtonGroup,
	Typography,
	styled,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { FIRST_DRIVELINE_POSITION_NUMBER } from 'api/responses/models/Unit/UnitStructure/DrivelineSubSystem';

import {
	DrivelinePosition,
	generateDrivelinePositions,
} from './Driveline/DrivelinePositionGenerator';

const maxIndicatorColumnsOnMobile = 6;

export interface ToggleDrivelineLocationProps {
	drivelineIndicator: number | null | undefined;
	onChange?: (newValue: number) => void;
	drivelineTotalCount: number;
	isIPS40: boolean;
	disabled?: boolean;
}

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
	backgroundColor: theme.palette.background.paper,

	'&.Mui-disabled': {
		border: '1px solid',
	},
	'&.MuiToggleButtonGroup-grouped:not(:first-of-type)': {
		border: '1px solid',
	},

	'&.position-center': {
		color: theme.palette.primary.main,
		borderColor: theme.palette.primary.main,
		'&.Mui-selected': {
			backgroundColor: theme.palette.primary.main,
			borderColor: theme.palette.primary.main,
			color: theme.palette.primary.contrastText,
		},
		'&.Mui-disabled': {
			borderColor: theme.palette.grey[700],
			backgroundColor: theme.palette.grey[700],
		},
	},

	'&.position-port': {
		color: theme.palette.error.main,
		borderColor: theme.palette.error.main,
		'&.Mui-selected': {
			backgroundColor: theme.palette.error.main,
			borderColor: theme.palette.error.main,
			color: theme.palette.error.contrastText,
		},
	},

	'&.position-starboard': {
		color: theme.palette.success.main,
		borderColor: theme.palette.success.main,
		'&.Mui-selected': {
			backgroundColor: theme.palette.success.main,
			borderColor: theme.palette.success.main,
			color: theme.palette.success.contrastText,
		},
	},
}));

const classNameMapping: { [key in DrivelinePosition]: string } = {
	port: 'position-port',
	'port-center': 'position-port',
	center: 'position-center',
	'starboard-center': 'position-starboard',
	starboard: 'position-starboard',
};

export const ToggleDrivelineLocation = ({
	drivelineIndicator,
	onChange,
	drivelineTotalCount,
	isIPS40,
	disabled = false,
}: ToggleDrivelineLocationProps): JSX.Element | null => {
	const { t } = useTranslation('boat');

	return drivelineTotalCount ? (
		<ToggleButtonGroup
			color="primary"
			size="small"
			value={drivelineIndicator ?? ''}
			exclusive
			onChange={(_, value: number) => {
				if (value !== drivelineIndicator) {
					onChange && onChange(value);
				}
			}}
			aria-label="driveline-position-toggle"
			sx={(theme) =>
				// special case for IPS40 workaround, when changing the column, remember to change the CSS variables also
				drivelineTotalCount > maxIndicatorColumnsOnMobile
					? {
							[theme.breakpoints.only('xs')]: {
								display: 'inline-grid',
								gridTemplateColumns: `repeat(6, 35px)`,
								'&>:nth-of-type(7)': {
									marginLeft: 0,
								},
								'&>:nth-of-type(n + 7)': {
									marginTop: '-1px',
								},
								'& > .MuiToggleButtonGroup-firstButton, & > .MuiToggleButtonGroup-lastButton':
									{
										borderRadius: 0,
									},
							},
						}
					: null
			}>
			{generateDrivelinePositions(drivelineTotalCount, isIPS40).map(
				(position, index) => {
					const scaledPosition =
						index + FIRST_DRIVELINE_POSITION_NUMBER;

					return (
						// use index, as there is no way to generate not duplicated key
						<StyledToggleButton
							disabled={disabled}
							key={index}
							value={scaledPosition}
							className={
								disabled &&
								scaledPosition !== drivelineIndicator
									? undefined
									: classNameMapping[position]
							}
							aria-label={position}
							title={t(`position-${position}`)}>
							<Typography width="1.1rem" height="1.1rem">
								{scaledPosition}
							</Typography>
						</StyledToggleButton>
					);
				}
			)}
		</ToggleButtonGroup>
	) : null;
};
