import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import { UseFormSetError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
	CreateOrUpdateConsumerCommand,
	EndConsumerDto,
	ErrorResponse,
	useProductCenterApi,
} from 'api';
import { EMAIL_ALREADY_USED_EXCEPTION, endCustomerKeys } from 'api/queries';
import { useValidationErrorsSnackbar } from 'library/hooks/useValidationErrorsSnackbar';
import { Customer } from 'modules/EndCustomer/State';

import { mapEndCustomerFormDataToRequestData } from './Mappers';

export const useCustomerFormSubmit = ({
	onSuccess,
	onFailure,
	setError,
}: {
	onSuccess: (customer: Customer) => void;
	onFailure?: () => void;
	setError: UseFormSetError<Customer>;
}) => {
	const { enqueueSnackbar } = useSnackbar();
	const { t } = useTranslation(['customer', 'common']);
	const queryClient = useQueryClient();
	const { enqueueValidationSnackbar } = useValidationErrorsSnackbar();
	const { api } = useProductCenterApi();

	const customerMutation = useMutation<
		EndConsumerDto,
		unknown,
		CreateOrUpdateConsumerCommand
	>({
		mutationFn: async (newValues) => {
			return (await api.createOrUpdateCustomer(newValues)).data;
		},
		onSuccess: async (_data, variables) => {
			// Invalidate and refetch
			if (variables.id) {
				await queryClient.invalidateQueries({
					queryKey: endCustomerKeys.customer(variables.id),
				});
			}
		},
	});

	const handleFormSubmit = async (data: Customer) => {
		try {
			const newValues = mapEndCustomerFormDataToRequestData(data);
			const response = await customerMutation.mutateAsync(newValues);

			onSuccess(response);
		} catch (error) {
			const axiosResponse = (error as AxiosError).response;
			const errorResponse = axiosResponse?.data as ErrorResponse;

			if (
				errorResponse &&
				errorResponse.type === EMAIL_ALREADY_USED_EXCEPTION
			) {
				setError('email', {
					message: 'customer:validation-email-already-taken',
					type: 'validate',
				});
			} else {
				enqueueValidationSnackbar(error as Error);
			}

			typeof onFailure === 'function' && onFailure();
		}
	};

	const handleFormInvalid = () => {
		enqueueSnackbar(t('common:validation-error-message'), {
			variant: 'error',
		});
	};

	return {
		handleFormSubmit,
		handleFormInvalid,
		customerMutation,
	};
};
