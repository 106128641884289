import { BaseRequest, IRequest } from './BaseRequest';

export interface IPostRequest<T = unknown> extends IRequest {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	params?: any;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	data?: T;
}

export class PostRequest extends BaseRequest implements IPostRequest {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	params?: any;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	data?: any;

	constructor(postRequest: IPostRequest) {
		super(postRequest);

		this.method = 'POST';
		this.params = postRequest.params;
		this.data = postRequest.data;
	}
}
