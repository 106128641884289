import { Tooltip } from '@mui/material';
import {
	GridCsvExportMenuItem,
	GridCsvExportOptions,
	GridToolbarColumnsButton,
	GridToolbarContainer,
	GridToolbarDensitySelector,
	GridToolbarExportContainer,
	GridToolbarFilterButton,
	GridToolbarProps,
	ToolbarPropsOverrides,
} from '@mui/x-data-grid-pro';
import { useTranslation } from 'react-i18next';

interface DataGridToolbarProps
	extends GridToolbarProps,
		ToolbarPropsOverrides {}

export const DataGridToolbar = ({ children }: DataGridToolbarProps) => {
	const { t } = useTranslation(['common']);

	return (
		<GridToolbarContainer>
			<GridToolbarColumnsButton />
			<GridToolbarFilterButton />
			<Tooltip title={t('common:change-layout')}>
				<GridToolbarDensitySelector />
			</Tooltip>
			{children}
		</GridToolbarContainer>
	);
};

interface DataGridToolbarWithExport {
	csvOptions?: GridCsvExportOptions;
	fileName?: string;
}

export const DataGridToolbarWithCsvExport = ({
	fileName,
	csvOptions = {
		delimiter: ';',
		includeColumnGroupsHeaders: false,
		allColumns: true,
	},
}: DataGridToolbarWithExport) => (
	<DataGridToolbar>
		<GridToolbarExportContainer>
			<GridCsvExportMenuItem options={{ ...csvOptions, fileName }} />
		</GridToolbarExportContainer>
	</DataGridToolbar>
);
