/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	Autocomplete,
	AutocompleteInputChangeReason,
	AutocompleteRenderOptionState,
	FilterOptionsState,
	TextField,
} from '@mui/material';
import { FieldPath, FieldValues, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TextFieldElementProps } from './ControlledTextField';
import { displayError, getHelperText } from './functions';

export interface ControlledAutocompleteProps<
	T,
	TFieldValues extends FieldValues = FieldValues,
	TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends Omit<TextFieldElementProps<TFieldValues, TName>, 'autoComplete'> {
	options: ReadonlyArray<T>;
	autoComplete?: boolean;
	loading?: boolean;
	filterOptions?: (options: T[], state: FilterOptionsState<T>) => T[];
	getOptionLabel?: (value: T) => string;
	getOptionId?: (value: T) => string | number;
	isOptionEqualToValue?: (option: T, value: T) => boolean;
	renderOption?: (
		props: React.HTMLAttributes<HTMLLIElement>,
		option: T,
		state: AutocompleteRenderOptionState
	) => React.ReactNode;
	onInputChange?: (
		event: React.SyntheticEvent,
		value: string,
		reason: AutocompleteInputChangeReason
	) => void;
}

export const defaultGetOptionLabel = (option: any) => option.label ?? option;
export const defaultGetOptionId = (option: any) => option.id ?? option;
export const defaultIsOptionEqualToValue = (option: any, valueOption: any) =>
	(option.id ?? option) === (valueOption?.id ?? valueOption);

export const ControlledAutocomplete = <
	T,
	TFieldValues extends FieldValues = FieldValues,
	TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
	control,
	name,
	options,
	autoComplete = true,
	loading,
	filterOptions,
	getOptionLabel = defaultGetOptionLabel,
	getOptionId = defaultGetOptionId,
	isOptionEqualToValue = defaultIsOptionEqualToValue,
	renderOption,
	onInputChange,
	sx,
	id,
	validation,
	required,
	disabled,
	helperText,
	...rest
}: ControlledAutocompleteProps<T, TFieldValues, TName>): JSX.Element => {
	const { t } = useTranslation(['common']);

	const {
		field: { onChange, onBlur, value, ref },
		fieldState: { error },
	} = useController<TFieldValues, TName>({
		name,
		control,
		rules: validation,
	});

	const isRequired =
		required === undefined ? Boolean(validation?.required) : required;

	const currentValue =
		options.find((option) => defaultIsOptionEqualToValue(option, value)) ||
		null;

	return (
		<Autocomplete
			id={id}
			options={options}
			autoComplete={autoComplete}
			disabled={disabled}
			filterOptions={filterOptions}
			clearOnBlur
			loading={loading}
			loadingText={t('common:loading-placeholder')}
			value={currentValue}
			multiple={false}
			getOptionLabel={getOptionLabel}
			isOptionEqualToValue={isOptionEqualToValue}
			onChange={(_, data) =>
				onChange(data != null ? getOptionId(data) : data)
			}
			onInputChange={onInputChange}
			onBlur={onBlur}
			ref={ref}
			sx={sx}
			renderOption={renderOption}
			renderInput={(params) => {
				return (
					<TextField
						name={name}
						{...params}
						error={displayError(disabled, error)}
						required={isRequired}
						helperText={getHelperText(
							t,
							disabled,
							error,
							helperText
						)}
						{...rest}
					/>
				);
			}}
		/>
	);
};
