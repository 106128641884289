import { BusinessCenter, Person } from '@mui/icons-material';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ConsumerType } from 'api/responses/models/AddEndCustomer/EndCustomerDto';
import { Sxable } from 'library/types';

interface PersonCompanyButtonsProps extends Sxable {
	selectedCustomer: ConsumerType | null;
	onCustomerTypeChanged: (customerType: ConsumerType) => void;
}

export const PersonCompanyButtons = ({
	selectedCustomer,
	onCustomerTypeChanged,
	sx = [],
}: PersonCompanyButtonsProps): JSX.Element => {
	const { t } = useTranslation('common');

	const getButtonColor = (customerType: ConsumerType) =>
		selectedCustomer === customerType ? 'secondary' : 'gray';

	return (
		<>
			<Button
				variant={'contained'}
				color={getButtonColor('PrivatePerson')}
				startIcon={<Person />}
				sx={[{ mr: 0.5 }, ...(Array.isArray(sx) ? sx : [sx])]}
				onClick={() => onCustomerTypeChanged('PrivatePerson')}
			>
				{t('enum-consumer-type-private-person')}
			</Button>
			<Button
				variant={'contained'}
				color={getButtonColor('Company')}
				startIcon={<BusinessCenter />}
				sx={Array.isArray(sx) ? sx : [sx]}
				onClick={() => onCustomerTypeChanged('Company')}
			>
				{t('enum-consumer-type-company')}
			</Button>
		</>
	);
};
