import { find, some } from 'lodash';

import IBaseMenuItem from '../models/IBaseMenuItem';
import IMenu from '../models/IMenu';
import IMenuItem from '../models/IMenuItem';
import ISubMenu from '../models/ISubMenu';

export const createMenu = (
	label: string,
	route: string,
	menuItems: IBaseMenuItem[]
): IMenu => {
	return {
		label,
		route,
		menuItems,
	} as IMenu;
};

export const createSubMenu = (
	label: string,
	subMenuItems?: IBaseMenuItem[]
): ISubMenu => {
	return {
		label,
		subMenuItems,
	} as ISubMenu;
};

export const createMenuItem = (label: string, route: string): IMenuItem => {
	return {
		label,
		route,
	} as IMenuItem;
};

export const isMenu = (menu: IBaseMenuItem): boolean =>
	some((menu as IMenu).menuItems);

export const isSubMenu = (menu: IBaseMenuItem): boolean =>
	some((menu as ISubMenu).subMenuItems);

export const isMenuItem = (menu: IBaseMenuItem): boolean =>
	some((menu as IMenuItem).route);

export const getMenuByLabel = (
	label: string,
	menus: IMenu[]
): IMenu | ISubMenu | null => {
	const foundMenu = find(menus, (menu) => menu.label === label);

	if (foundMenu !== undefined) {
		return foundMenu;
	} else {
		let foundSubMenu = null as ISubMenu | null;
		some(menus, (menu) => {
			foundSubMenu = findSubMenuInChildElements(menu.menuItems, label);

			if (foundSubMenu !== null) {
				return true;
			}

			return false;
		});

		return foundSubMenu;
	}
};

const findSubMenuInChildElements = (
	menuItems: IBaseMenuItem[] | undefined,
	label: string
): ISubMenu | null => {
	let foundMenu = null as ISubMenu | null;

	some(menuItems, (menuItem) => {
		if (isSubMenu(menuItem)) {
			const subMenu = menuItem as ISubMenu;

			if (subMenu.label === label) {
				foundMenu = subMenu;
				return true;
			} else {
				foundMenu = findSubMenuInChildElements(
					subMenu.subMenuItems,
					label
				);
			}
		}

		return false;
	});

	return foundMenu;
};

export const getParentMenuByLabel = (
	label: string,
	menus: IMenu[]
): IMenu | ISubMenu | null => {
	const foundRootMenu = find(menus, (menu) => menu.label === label);

	if (foundRootMenu !== undefined) {
		return null;
	} else {
		let foundParentMenu = null as IMenu | ISubMenu | null;

		some(menus, (menu) => {
			foundParentMenu = findParentMenuInChildElements(menu, label);

			if (foundParentMenu !== null) {
				return true;
			}

			return false;
		});

		return foundParentMenu;
	}
};

const findParentMenuInChildElements = (
	menu: IBaseMenuItem,
	label: string
): IMenu | ISubMenu | null => {
	let parentMenu = null as IMenu | ISubMenu | null;
	const isRootMenu = isMenu(menu);
	some(
		isRootMenu
			? (menu as IMenu).menuItems
			: (menu as ISubMenu).subMenuItems,
		(menuItem) => {
			if (isSubMenu(menuItem)) {
				const subMenu = menuItem as ISubMenu;

				if (subMenu.label === label) {
					parentMenu = isRootMenu
						? (menu as IMenu)
						: (menu as ISubMenu);
					return true;
				} else {
					parentMenu = findParentMenuInChildElements(subMenu, label);
				}
			}

			return false;
		}
	);

	return parentMenu;
};
