import { IBaseHeader } from '../headers/BaseHeader';
import { IPostRequest, PostRequest } from './PostRequest';

export interface IUploadRequest extends IPostRequest {
	data?: FormData;
}

export class UploadRequest extends PostRequest {
	data?: FormData;

	constructor(uploadRequest: IUploadRequest) {
		super(uploadRequest);

		this.headers =
			uploadRequest.headers ??
			({
				contentType: 'multipart/form-data',
			} as IBaseHeader);

		this.data = uploadRequest.data;
	}
}
