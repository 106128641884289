import {
	Box,
	Divider,
	Card as MuiCard,
	Typography,
	useTheme,
} from '@mui/material';
import { noop } from 'lodash';
import { useCallback } from 'react';

import { PropsWithChildren, Sxable } from 'library/types';
import { PCTheme } from 'library/utils/themes/PCTheme';

import { Item, ItemActionProps, ItemProps } from '../../infinityScroll/Item';

const {
	palette: {
		grey: { '400': grey400 },
	},
	spacing,
	typography: { htmlFontSize, body1 },
} = PCTheme;

interface CardProps<T extends Item> {
	Body: (props: ItemProps<T>) => JSX.Element;
	Footer?: (props: ItemProps<T>) => JSX.Element;
	Header: (props: ItemProps<T>) => JSX.Element;
}

interface CardFieldProps extends Sxable {
	header: string;
	isSingle?: boolean;
	value?: string | JSX.Element | null;
}

interface CardIconFieldProps extends CardFieldProps {
	Icon: JSX.Element;
}

export const CardFieldContainer = ({ children }: PropsWithChildren) => (
	<Box display="grid" gridTemplateColumns="2fr 2fr" columnGap={spacing(2)}>
		{children}
	</Box>
);

export const CardIconField = ({ Icon, ...rest }: CardIconFieldProps) => (
	<Box display="flex" flex={1} alignItems="center" columnGap={spacing(1)}>
		{Icon}
		<CardField {...rest} />
	</Box>
);

export const CardField = ({
	header,
	isSingle,
	sx = {},
	value = '',
}: CardFieldProps) => (
	<Box display="flex" flexDirection="column" sx={sx}>
		<Typography
			color={grey400}
			paddingBottom={spacing(0.5)}
			variant="body2">
			{header}
		</Typography>
		<Typography
			height={
				value
					? 'auto'
					: `calc(${
							parseInt(body1.lineHeight?.toString() || '1') / 100
						} * ${htmlFontSize}px)`
			}
			paddingBottom={isSingle ? 0 : spacing(1.5)}
			variant="body1">
			{value}
		</Typography>
	</Box>
);

export const Card = <T extends Item>({
	isSelected,
	item,
	onClick = noop,
	Body,
	Footer,
	Header,
}: CardProps<T> & ItemProps<T> & ItemActionProps<T>) => {
	const {
		spacing,
		palette: {
			secondary: { main },
			grey: { '300': grey300 },
		},
	} = useTheme();

	const handleOnClick = useCallback(() => {
		onClick(item);
	}, [item, onClick]);

	return (
		<MuiCard
			variant="outlined"
			sx={{
				width: '320px',
				borderColor: isSelected ? main : grey300,
				backgroundColor: 'transparent',
			}}>
			<Box
				onClick={handleOnClick}
				sx={{
					borderBottom: '1px solid',
					borderColor: grey300,
					cursor: onClick === noop ? 'default' : 'pointer',
					padding: 1,
					display: 'flex',
					justifyContent: 'flex-start',
					alignItems: 'center',
					backgroundColor: isSelected ? main : 'transparent',
					columnGap: spacing(1),
				}}>
				<Header item={item} isSelected={isSelected} />
			</Box>
			<Box padding={1}>
				<Body item={item} isSelected={isSelected} />
			</Box>
			{Footer && (
				<>
					<Divider sx={{ borderStyle: 'dashed' }} />
					<Box padding={1}>
						<Footer item={item} isSelected={isSelected} />
					</Box>
				</>
			)}
		</MuiCard>
	);
};
