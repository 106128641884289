export const ISO10087_MASK = '##-###-#####-####';
export const US_COAST_GUARD_MASK = '###-#####-####';
export const IMO_NUMBER_MASK = '0000000';
export const MMSI_NUMBER_MASK = '000000000';
export const CALL_SIGN_MASK = '###[####]';

export const hullIdIso10087Pattern =
	/^[A-Z0-9]{2}-[A-Z0-9]{3}-[A-Z0-9]{5}-[A-Z0-9]{4}$/i;

export const hullIdUsCoastGuardPattern =
	/^[A-Z0-9]{3}-[A-Z0-9]{5}-[A-Z0-9]{4}$/i;

export const mmsiNumberPattern = /^\d{9}$/;

export const imoNumberPattern = /^\d{7}$/;

export const callSignPattern = /^[A-Z0-9]{3,7}$/i;
