import { toLower } from 'lodash';

import {
	AttachmentFileSet,
	Driveline,
	PartForSale,
	PartForSaleDto,
	ProductIndividual,
	Installation as RegistrationInstallation,
	RegistrationItemDto,
	UnitInfo,
	UnitMeasurement,
	UnitOtherPartDto,
} from 'api';
import {
	DimensionsType,
	convertWeightToKilograms,
	convertWeightToTonnes,
} from 'api/responses/models/Unit/UnitMeasurement';
import { Unit } from 'api/responses/models/Unit/UnitStructure/Unit';
import {
	DrivelineDto,
	ProductIndividualDto,
} from 'api/responses/models/UnitCreation/ProductIndividualSearchResultDto';
import {
	customBrandKey,
	customModelKey,
	getBrand,
	getModel,
} from 'domain/unit/brandAndModel';
import { DetailedSegment, InstallationType } from 'domain/unit/enums';
import { isIsoHullId, isUsCoastGuardHullId } from 'domain/unit/hullIdSelector';
import { mapFileUploadStateToAttachmentFileSet } from 'library/redux/fileUpload';
import { Installation, UnitState } from 'modules/Shared/State/unit';

import { DEFAULT_DIMENSIONS_TYPE, UnitCreationFileUploadState } from '.';
import { CreateUnitState, UnitDataFormTypes } from './Types';

export const mapToOtherProducts = (
	input: PartForSaleDto[]
): UnitOtherPartDto[] =>
	input.map((entry) => ({
		partNumber: entry.partNumber || '',
		serialNumber: entry.serialNumber,
		scipNumber: entry.scipNumber,
		description: entry.description || '',
		delivered: entry.quantity ?? 0,
		installed: entry.quantity ?? 0,
	}));

export const mapToOtherProduct = ({
	description,
	partNumber,
	serialNumber,
}: RegistrationItemDto & {
	serialNumber: string | undefined;
}): UnitOtherPartDto => ({
	partNumber,
	serialNumber: serialNumber || null,
	scipNumber: null,
	description: description || '',
	delivered: 0,
	installed: serialNumber ? 1 : 0,
});

export const mapToInstallation = (
	installations: Installation[]
): Array<RegistrationInstallation> => {
	return installations.map(
		(installation): RegistrationInstallation => ({
			id: null, // not needed for registration
			blueprintMasterIdentifier: null, // not needed for registration
			detailedSegment: installation.detailedSegmentKey as DetailedSegment,
			operatingProfile: installation.operatingProfileKey,
			installationPurpose:
				installation.installationPurposeKey as InstallationType,
			drivelines: installation.drivelines.map(mapToRegistrationDriveline),
		})
	);
};

export const mapToRegistrationPartForSale = (
	input: UnitOtherPartDto
): PartForSale => {
	if (input.partNumber == null) {
		throw new Error('Part number should always exists');
	}

	return {
		partNumber: input.partNumber,
		serialNumber: input.serialNumber ?? null,
		scipNumber: input.scipNumber,
		name: input.description,
		installed: input.installed ?? 0,
	};
};

export const mapToRegistrationDriveline = (
	driveline: DrivelineDto
): Driveline => ({
	chassisId: driveline.chassisId,
	position: driveline.drivelinePosition as number,
	productIndividuals: driveline.productIndividuals.map(
		mapToRegistrationProductIndividual
	),
});

export const mapToRegistrationProductIndividual = (
	product: ProductIndividualDto
): ProductIndividual => ({
	id: 0, // nowhere to find this
	serialNumber: product.serialNumber,
	type: product.type,
	partNumber: product.partNumber ?? null, // missing in the type, not needed anyway
	description: product.description ?? null,
	isNonPentaProduct: product.isNonVolvoPenta ?? false,
});

export const mapUnitStateToUnitInfo = (
	unitState: UnitState & CreateUnitState
): UnitInfo => {
	const weight = parseFloat(unitState.unitData.displacement);
	const unitOfWeight = unitState.unitData.displacementUnit;

	return {
		mainSegment: unitState.unitMetadata.mainSegment,
		lineOfBusiness: unitState.unitMetadata.lineOfBusiness ?? '',
		purpose: unitState.unitMetadata.unitPurpose ?? '',
		brand: getBrand(
			unitState.unitData.brand,
			unitState.unitData.customBrand
		),
		model: getModel(
			unitState.unitData.model,
			unitState.unitData.customModel
		),
		name: unitState.unitData.unitName ?? null,
		imoNumber: unitState.unitData.imoNumber ?? null,
		mmsiNumber: unitState.unitData.mmsiNumber ?? null,
		registrationNumber: unitState.unitData.registrationNumber ?? null,
		hullIdCoastGuard:
			(isUsCoastGuardHullId(unitState.unitData.hullIdStandard)
				? unitState.unitData.hullId
				: null) ?? null,
		isoHullId:
			(isIsoHullId(unitState.unitData.hullIdStandard)
				? unitState.unitData.hullId
				: null) ?? null,
		hullMachineId: unitState.unitData.machineId ?? null,
		callSign: unitState.unitData.callSign ?? null,
		yssPinCode: null, // not used in registration anymore
		overallLength: parseFloat(unitState.unitData.length),
		unitOfLength: unitState.unitData.lengthUnit,
		width: parseFloat(unitState.unitData.width),
		unitOfWidth: unitState.unitData.widthUnit,
		weightType: unitState.unitData.dimensionsType,
		...convertWeightToKilograms(weight, unitOfWeight),
	};
};

export const mapUnitDtoToUnitDataFormTypes = (
	unitInfo: Unit,
	unitMeasurement: UnitMeasurement | null | undefined
): UnitDataFormTypes => {
	const { unitOfWeight, weight } = convertWeightToTonnes(
		unitMeasurement?.weight,
		unitMeasurement?.unitOfWeight
	);

	return {
		unitName: unitInfo.identification?.name,
		registrationNumber: unitInfo.identification?.registrationNumber || '',
		machineId: unitInfo.identification?.hullMachineId,
		hullIdStandard: unitInfo.identification?.hullIdStandard,
		hullId: unitInfo.identification?.hullId,
		imoNumber: unitInfo.identification?.imoNumber,
		mmsiNumber: unitInfo.identification?.mmsiNumber,
		callSign: unitInfo.identification?.callSign,
		brand: { id: customBrandKey, label: '' },
		customBrand: unitInfo.brandOrType,
		model: { id: customModelKey, label: '' },
		customModel: unitInfo.model,
		dimensionsType:
			(unitMeasurement?.weightType as DimensionsType) ??
			DEFAULT_DIMENSIONS_TYPE,
		length: unitMeasurement?.overallLength?.toString() ?? '',
		lengthUnit: unitMeasurement?.unitOfLength ?? '',
		width: unitMeasurement?.width?.toString() ?? '',
		widthUnit: unitMeasurement?.unitOfWidth ?? '',
		displacement: weight?.toString() ?? '',
		displacementUnit: unitOfWeight ?? '',
		unitPurpose: unitInfo.consumerUsingPurpose || '',
		lineOfBusiness: toLower(unitInfo.consumerLineOfBusiness || ''),
	};
};

export const mapUnitCreationFiles = (
	unitFiles: UnitCreationFileUploadState
): AttachmentFileSet => {
	const files = mapFileUploadStateToAttachmentFileSet(unitFiles);

	return {
		profilePicturePath: unitFiles.profilePicturePath?.serverName ?? null,
		folderName: files.folderName ?? null,
		uploadedFiles: files.uploadedFiles ?? [],
	};
};
