import { Warning } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { useMemo } from 'react';
import { UseFormReturn, UseFormWatch, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ConsumerType } from 'api/responses/models/AddEndCustomer/EndCustomerDto';
import {
	ControlledFormattedTextField,
	ControlledRadioButtonGroup,
	ControlledTextField,
} from 'library/components/controls';
import { NUMBER_MASK } from 'library/constants';
import { SxProps } from 'library/types';
import { Modify } from 'library/utils/typescript';
import { emailPattern } from 'library/validation';

import { Customer } from '../State';
import { CountryInput } from './CountryInput';
import { GdprDialog } from './GdprDialog';
import { NotificationLanguageSelect } from './NotificationLanguageSelect';
import { PersonCompanyButtons } from './PersonCompanyButtons';
import { PhonePrefixInput } from './PhonePrefixInput';

// all fields must be set to empty, so all fields will be cleared
export const emptyCustomer: Partial<
	Modify<
		Customer,
		{
			isConsumerAgreeForMarketingPurpose: boolean | null;
			isConsumerAgreeForPentaStorageOfUserInformation: boolean | null;
		}
	>
> = {
	consumerType: 'PrivatePerson',
	email: '',
	companyName: '',
	vatNumber: '',
	phonePrefix: '',
	phoneNumber: '',
	firstName: '',
	surNameOrLastName: '',
	countryCode: '',
	county: '',
	city: '',
	zipCode: '',
	address: '',
	isConsumerAgreeForMarketingPurpose: null,
	isConsumerAgreeForPentaStorageOfUserInformation: null,
	notificationLanguage: '',
};

export interface NewCustomerFormPropsLegacy {
	idPrefix?: string;
	control: UseFormReturn<Customer>['control'];
	watch: UseFormWatch<Customer>;
	setValue: UseFormReturn<Customer>['setValue'];
}

export const NewCustomerFormLegacy = ({
	idPrefix = 'modification',
	control,
	watch,
	setValue,
}: NewCustomerFormPropsLegacy): JSX.Element => {
	const { t } = useTranslation(['common', 'commonButton', 'customer']);

	const {
		touchedFields: {
			isConsumerAgreeForPentaStorageOfUserInformation:
				wasStorageConsentFieldTouched,
		},
	} = useFormState({ control });

	const consentOptions = useMemo(
		() => [
			{ id: true, label: t('commonButton:agree') },
			{ id: false, label: t('commonButton:disagree') },
		],
		[t]
	);

	const watchStorageConsent = watch(
		'isConsumerAgreeForPentaStorageOfUserInformation'
	);

	const storageConsentInitialValue =
		watchStorageConsent != null ? watchStorageConsent : true;

	const doesConsumerConsentToStorage = wasStorageConsentFieldTouched
		? watchStorageConsent
		: storageConsentInitialValue;

	const customerType = watch('consumerType');
	const isCustomerCompany = customerType === 'Company';

	const handleCustomerTypeChanged = (newCustomerType: ConsumerType) => {
		setValue('consumerType', newCustomerType);
	};

	const displaySxOnNoConsentToStorage: SxProps = doesConsumerConsentToStorage
		? {}
		: { display: 'none' };

	return (
		<>
			<Box
				sx={{
					mb: 2,
					display: 'grid',
					gridTemplateColumns:
						'auto repeat(2, minmax(min-content, 280px)) auto',
					'&>button:first-of-type': { gridColumn: '2' },
					'&>button:last-of-type': { gridColumn: '3' },
				}}>
				<PersonCompanyButtons
					selectedCustomer={customerType}
					onCustomerTypeChanged={handleCustomerTypeChanged}
					sx={(theme) => ({
						height: theme.spacing(9),
					})}
				/>
			</Box>
			<Box
				display="grid"
				gridTemplateColumns={{
					xs: 'repeat(1, 1fr)',
					sm: 'repeat(3, 1fr)',
				}}
				gap={2}>
				<ControlledTextField
					id={idPrefix + 'email-input'}
					label={t('common:email')}
					name="email"
					type="email"
					size="small"
					control={control}
					sx={displaySxOnNoConsentToStorage}
					validation={{
						required: doesConsumerConsentToStorage,
						pattern: emailPattern,
					}}
				/>
				{isCustomerCompany && doesConsumerConsentToStorage ? (
					<>
						<ControlledTextField
							id={idPrefix + 'company-name-input'}
							label={t('common:company-name')}
							name="companyName"
							size="small"
							control={control}
							validation={{
								required:
									isCustomerCompany &&
									doesConsumerConsentToStorage,
							}}
						/>
						<ControlledTextField
							id={idPrefix + 'vat-number-input'}
							label={t('common:vat-number')}
							name="vatNumber"
							size="small"
							control={control}
						/>
					</>
				) : (
					<>
						<Box display={{ xs: 'none', sm: 'block' }}></Box>
						<Box display={{ xs: 'none', sm: 'block' }}></Box>
					</>
				)}
				<Box display="flex" gap={2}>
					<PhonePrefixInput
						id={idPrefix + 'phone-prefix-input'}
						label={t('prefix')}
						name="phonePrefix"
						size="small"
						control={control}
						sx={{ flex: 2, ...displaySxOnNoConsentToStorage }}
					/>
					<ControlledFormattedTextField
						id={idPrefix + 'phone-number-input'}
						label={t('common:phone-number')}
						name="phoneNumber"
						type="tel"
						size="small"
						control={control}
						imaskProps={{
							mask: NUMBER_MASK,
							type: 'tel',
						}}
						sx={{ flex: 3, ...displaySxOnNoConsentToStorage }}
					/>
				</Box>
				<ControlledTextField
					id={idPrefix + 'first-name-input'}
					label={t('common:first-name')}
					name="firstName"
					size="small"
					control={control}
					sx={displaySxOnNoConsentToStorage}
					validation={{
						required: doesConsumerConsentToStorage,
					}}
				/>
				<ControlledTextField
					id={idPrefix + 'last-name-input'}
					label={t('common:last-name')}
					name="surNameOrLastName"
					size="small"
					control={control}
					sx={displaySxOnNoConsentToStorage}
					validation={{
						required: doesConsumerConsentToStorage,
					}}
				/>
				<CountryInput
					id={idPrefix + 'country-input'}
					label={t('common:country')}
					name="countryCode"
					size="small"
					control={control}
					validation={{ required: true }}
				/>
				<ControlledTextField
					id={idPrefix + 'county-input'}
					label={t('common:county')}
					name="county"
					size="small"
					control={control}
					sx={displaySxOnNoConsentToStorage}
				/>
				<ControlledTextField
					id={idPrefix + 'city-input'}
					label={t('common:city')}
					name="city"
					size="small"
					control={control}
					validation={{ required: true }}
				/>
				<ControlledTextField
					id={idPrefix + 'zip-code-input'}
					label={t('common:zip-code')}
					name="zipCode"
					size="small"
					control={control}
					validation={{ required: true }}
				/>
				<ControlledTextField
					id={idPrefix + 'address-input'}
					label={t('common:address')}
					name="address"
					size="small"
					sx={{
						gridColumn: { sm: 'span 2' },
						...displaySxOnNoConsentToStorage,
					}}
					control={control}
					validation={{ required: doesConsumerConsentToStorage }}
				/>
			</Box>
			<Box
				display="grid"
				gridTemplateColumns={{
					xs: '2fr 1fr',
					sm: '3fr 1fr',
					md: '4fr 1fr',
				}}
				gap={4}
				my={4}>
				<Box position="relative">
					<Box
						display="flex"
						flexWrap="nowrap"
						alignItems="center"
						gap={2}>
						<Typography variant="subtitle2">
							{t(
								'customer:customer-data-storage-consent-question'
							)}
							<Typography
								component="span"
								color="error"
								variant="subtitle2">
								{' *'}
							</Typography>
						</Typography>
						<GdprDialog />
					</Box>
					<Typography variant="body2" ml={8}>
						<Warning
							color="warning"
							sx={{ verticalAlign: 'bottom', mr: 2 }}
						/>
						{t('customer:customer-anonymization-warning')}
					</Typography>
				</Box>
				<ControlledRadioButtonGroup
					options={consentOptions}
					row={true}
					name="isConsumerAgreeForPentaStorageOfUserInformation"
					control={control}
					required={true}
					radioProps={{ color: 'secondary' }}
				/>
				<Typography variant="subtitle2">
					{t('customer:customer-marketing-consent-question')}
					<Typography
						component="span"
						color="error"
						variant="subtitle2">
						{' *'}
					</Typography>
				</Typography>
				<ControlledRadioButtonGroup
					options={consentOptions}
					row={true}
					name="isConsumerAgreeForMarketingPurpose"
					control={control}
					required={doesConsumerConsentToStorage}
					radioProps={{
						disabled: !doesConsumerConsentToStorage,
						color: 'secondary',
					}}
				/>
				<NotificationLanguageSelect
					id={idPrefix + 'notification-language-select'}
					label={t('customer:notification-language-label')}
					name="notificationLanguage"
					size="small"
					control={control}
					validation={{ required: true }}
					sx={{ gridColumn: 2 }}
				/>
			</Box>
		</>
	);
};
