import * as React from 'react';

import { DisplayDevice } from '../../../library/models/enums/DisplayDevice';
import ExitButton from './nav/components/ExitButton';
import ToggleButton from './nav/components/ToggleButton';
import { NavMenuProps } from './nav/models/NavMenuProps';

const MobileMenuButton: React.FC<NavMenuProps> = ({
	isMobileMenuOpen,
	isTabletMenuOpen,
	setIsMobileMenuOpen,
	setIsTabletMenuOpen,
	setSelectedMenu,
}): JSX.Element => {
	const handleOnTabletMenuToggle = () => {
		setIsTabletMenuOpen(!isTabletMenuOpen);
	};

	const handleOnTabletMenuExit = () => {
		setIsTabletMenuOpen(!isTabletMenuOpen);
		setSelectedMenu(null);
	};

	const handleOnMobileMenuToggle = () => {
		setIsMobileMenuOpen(!isMobileMenuOpen);
	};

	const handleOnMobileMenuExit = () => {
		setIsMobileMenuOpen(!isMobileMenuOpen);
		setSelectedMenu(null);
	};

	return (
		<div id="header__nav" className="row">
			<nav className="text-uppercase navbar navbar-collapse navbar-expand-lg navbar-light">
				{!isMobileMenuOpen ? (
					<ToggleButton
						displayDevice={DisplayDevice.Mobile}
						toggle={handleOnMobileMenuToggle}
					/>
				) : (
					<ExitButton
						displayDevice={DisplayDevice.Mobile}
						exit={handleOnMobileMenuExit}
					/>
				)}
				{!isTabletMenuOpen ? (
					<ToggleButton
						displayDevice={DisplayDevice.Tablet}
						toggle={handleOnTabletMenuToggle}
					/>
				) : (
					<ExitButton
						displayDevice={DisplayDevice.Tablet}
						exit={handleOnTabletMenuExit}
					/>
				)}
			</nav>
		</div>
	);
};

export default MobileMenuButton;
