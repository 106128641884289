import { Box, Paper, Typography, useTheme } from '@mui/material';
import { PropsWithChildren, ReactNode } from 'react';

import { Removable, Sxable } from 'library/types';

export const DrivelineWrapper = ({
	children,
	isRemoved,
	sx,
}: PropsWithChildren<Removable & Sxable>) => (
	<Paper
		elevation={0}
		sx={[
			({ palette: { grey } }) => ({
				my: 1,
				p: 2,
				position: 'relative',
				backgroundColor: isRemoved ? grey[100] : 'background.blue',
				pointerEvents: isRemoved ? 'none' : 'all',
			}),
			...(Array.isArray(sx) ? sx : [sx]),
		]}>
		{children}
	</Paper>
);

type DrivelineHeaderProps = {
	actions?: ReactNode;
	title: ReactNode;
};

export const DrivelineHeader = ({
	actions,
	title,
}: DrivelineHeaderProps): JSX.Element => {
	const { spacing } = useTheme();

	return (
		<Box
			display="inline-flex"
			justifyContent="space-between"
			gap={1}
			width={1}
			pb={1}
			flexWrap={'wrap'}
			alignItems={'center'}>
			<Typography
				variant="h6"
				display="flex"
				flexWrap="wrap"
				flex={1}
				overflow="hidden"
				gap={spacing(0.5)}
				minWidth="fit-content">
				{title}
			</Typography>
			{actions}
		</Box>
	);
};

export const DrivelineContent = ({
	children,
	sx = {},
}: PropsWithChildren & Sxable) => (
	<Box
		sx={[
			(theme) => ({
				display: 'flex',
				flexDirection: 'row',
				flexWrap: 'wrap',
				gap: theme.spacing(2),

				[theme.breakpoints.only('xs')]: {
					'& > *': {
						flex: '1 1 auto',
					},
				},
			}),
			...(Array.isArray(sx) ? sx : [sx]),
		]}>
		{children}
	</Box>
);
