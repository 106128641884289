import { InstallationDto } from 'api/responses/models/UnitCreation/ProductIndividualSearchResultDto';
import { InstallationType } from 'domain/unit/enums';

import { Installation, MainSegment } from './Types';

export const doesInstallationsContainProductItem = (
	installations: InstallationDto[],
	serialNumber: string
) => {
	const upperCasedSerialNumber = serialNumber.toUpperCase();
	return installations.find((installation) =>
		installation.drivelines.find((driveline) =>
			driveline.productIndividuals.find(
				(product) =>
					product.serialNumber.toUpperCase() ===
					upperCasedSerialNumber
			)
		)
	);
};

export const isUnitIndustrial = (mainSegment: MainSegment | null | undefined) =>
	mainSegment === 'I';

export const isUnitMarine = (mainSegment: MainSegment | null | undefined) =>
	mainSegment === 'M';

export const shouldDisplayOperatingProfile = (
	purpose: InstallationType | null | undefined
) => purpose === 'MechanicalPropulsion';

export const shouldDisplayDetachOption = (
	installation: Installation
): boolean => installation.drivelines.length > 1;

export const validateProductSerialNumber = (
	installations: Installation[],
	serialNumber: string
) => {
	const isAlreadyAdded = doesInstallationsContainProductItem(
		installations,
		serialNumber
	);

	if (isAlreadyAdded) {
		return 'unitCreation:validation-serial-number-already-added';
	}

	return true;
};
