/* eslint-disable @typescript-eslint/no-explicit-any */
import { BaseRequest, IRequest } from './BaseRequest';

export interface IHeadRequest extends IRequest {
	params?: any;
	paramsSerializer?: (params: any) => string;
}

export class HeadRequest extends BaseRequest implements IHeadRequest {
	params?: any;
	paramsSerializer?: (params: any) => string;

	constructor(headRequest: IHeadRequest) {
		super(headRequest);

		this.method = 'HEAD';
		this.params = headRequest.params;
		this.paramsSerializer = headRequest.paramsSerializer;
	}
}
