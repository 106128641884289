import { compact, concat, findIndex, pullAt, sortBy, toLower } from 'lodash';

import { ListItem } from 'api/responses/models/Common/ListItem';
import { ListItemParent } from 'api/responses/models/Common/ListItemParent';
import { DrivelineSubSystem } from 'api/responses/models/Unit/UnitStructure/DrivelineSubSystem';
import { MainSegment } from 'api/responses/models/Unit/UnitStructure/MainSegment';
import { Unit } from 'api/responses/models/Unit/UnitStructure/Unit';
import {
	filterNullItems,
	sortByPosition,
} from 'library/utils/helpers/Installation';

import {
	COMMISSIONING_ALLOWED_STATUS_LIST,
	COMMISSIONING_AVAILABLE_STATUS_LIST,
	CONSUMER_REGISTER_ALLOWED_STATUS_LIST,
	DEMO_REGISTER_ALLOWED_STATUS_LIST,
	END_CUSTOMER_AVAILABLE_STATUS_LIST,
	PRODUCT_IN_OPERATION_ALLOWED_STATUS_LIST,
	PRODUCT_IN_OPERATION_AVAILABLE_STATUS_LIST,
	RELOCATE_AVAILABLE_STATUS_LIST,
	WARRANTY_STARTED_STATUS_LIST,
} from '../productIndividual/constants';
import { InstallationType } from './enums';

const OTHER_UNIT_PURPOSES_GUUID = '5018c5a3-60b1-4c61-af76-c063d1ea5d81';
const OTHER_LINE_OF_BUSINESS_INDUSTRIAL_GUUID =
	'042f2bc7-0816-4625-a4fe-f5eea892c2f7';

export const isWarrantyStartedStatus = (unit?: Unit) =>
	unit ? WARRANTY_STARTED_STATUS_LIST.includes(unit.status) : false;

export const isConsumerRegisterButtonDisabled = (unit?: Unit) =>
	unit ? !CONSUMER_REGISTER_ALLOWED_STATUS_LIST.includes(unit.status) : true;

export const showEndConsumerSection = (unit?: Unit) =>
	unit ? END_CUSTOMER_AVAILABLE_STATUS_LIST.includes(unit.status) : false;

export const isProductInOperationButtonDisabled = (unit?: Unit) =>
	unit
		? !PRODUCT_IN_OPERATION_ALLOWED_STATUS_LIST.includes(unit.status)
		: true;

export const showProductInOperationSection = (unit?: Unit) =>
	unit
		? PRODUCT_IN_OPERATION_AVAILABLE_STATUS_LIST.includes(unit.status)
		: false;

export const isCommissioningButtonDisabled = (unit?: Unit) =>
	unit ? !COMMISSIONING_ALLOWED_STATUS_LIST.includes(unit.status) : true;

export const showCommissioningSection = (unit?: Unit) =>
	unit ? COMMISSIONING_AVAILABLE_STATUS_LIST.includes(unit.status) : false;

export const isDemoDealerRegisterButtonDisabled = (unit?: Unit) =>
	unit ? !DEMO_REGISTER_ALLOWED_STATUS_LIST.includes(unit.status) : true;

export const isRelocateEnabled = (unit?: Unit) =>
	unit ? RELOCATE_AVAILABLE_STATUS_LIST.includes(unit.status) : false;

const sort = (data: ListItem[]) => sortBy(data, ['text']);

const tail = (data: ListItem[], value: string) => {
	const copy = [...data];
	const pulled = pullAt(
		copy,
		findIndex(copy, {
			value,
		})
	);

	return concat(sort(copy), compact(pulled));
};

export const getAllDrivelinesSorted = (unit?: Unit): DrivelineSubSystem[] =>
	sortByPosition(unit?.installations)
		.flatMap(({ drivelines }) => drivelines)
		.filter(filterNullItems) ?? [];

export const filterAndSortUnitPurpose = (
	data: ListItemParent[],
	lineOfBusiness?: string
) => {
	if (!lineOfBusiness) {
		return [];
	}

	const lowerConsumerLineOfBusiness = toLower(lineOfBusiness);
	const filtered = data.filter(
		({ parentValue }) => parentValue === lowerConsumerLineOfBusiness
	);

	if (!filtered.length) {
		return [];
	}

	// FE solution to set "Other" item to the end of the array
	return tail(filtered, OTHER_UNIT_PURPOSES_GUUID);
};

export const filterAndSortLineOfBusiness = (
	data: ListItem[],
	mainSegment?: MainSegment | null
) => {
	if (!mainSegment || mainSegment === 'M') {
		return sort(data);
	}

	// FE solution to set "Other" item to the end of the array
	return tail(data, OTHER_LINE_OF_BUSINESS_INDUSTRIAL_GUUID);
};

export const hasDrivelinePositions = (
	segment: string | null | undefined,
	purpose: InstallationType | null | undefined
) =>
	segment?.toLocaleUpperCase().startsWith('M') &&
	purpose === 'MechanicalPropulsion';
