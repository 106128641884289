import { AuthenticationProvider } from './Provider';

export class EmptyProvider extends AuthenticationProvider {
	public isAuthenticated(): Promise<boolean> {
		return Promise.resolve(false);
	}
	public onAuthenticated(
		fn: (isAuth: boolean, message?: string) => void
	): void {
		fn(false, 'Fatal error while authentication');
	}

	public getTokenAsync(): Promise<string> {
		throw new Error(
			"Empty authentication provider doesn\\'t allow to perform request"
		);
	}
}
