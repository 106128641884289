import { SvgIcon, SvgIconProps } from '@mui/material';

export const ArrowRight = (props: SvgIconProps) => (
	<SvgIcon {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M13.1462 5.64648C13.3415 5.45122 13.658 5.45122 13.8533 5.64648L19.8536 11.6467C19.9473 11.7405 20 11.8677 20 12.0003C20 12.1329 19.9473 12.2601 19.8536 12.3538L13.8533 18.354C13.658 18.5493 13.3415 18.5493 13.1462 18.354C12.9509 18.1588 12.9509 17.8422 13.1462 17.6469L18.2929 12.5003L4.5 12.5003C4.22386 12.5003 4 12.2764 4 12.0003C4 11.7241 4.22386 11.5003 4.5 11.5003L18.2929 11.5003L13.1462 6.35359C12.9509 6.15833 12.9509 5.84175 13.1462 5.64648Z"
		/>
	</SvgIcon>
);
