import { StyledLink } from './CustomLink';

export const PhoneNumberLink = ({
	phoneNumber,
}: {
	phoneNumber: string | null | undefined;
}): JSX.Element | null =>
	phoneNumber ? (
		<StyledLink href={`tel:${phoneNumber}`} color="secondary">
			{phoneNumber}
		</StyledLink>
	) : null;
