import { Namespace, ParseKeys } from 'i18next';
import { FieldValues, UseControllerProps } from 'react-hook-form';

export const emailPattern = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;

export const fieldValidation = <T extends FieldValues>(
	value: RegExp,
	message: ParseKeys<Namespace> = 'common:validation-error-message'
): UseControllerProps<T>['rules'] => ({
	pattern: {
		value,
		message,
	},
});

export const emailFieldValidation = <
	T extends FieldValues,
>(): UseControllerProps<T>['rules'] =>
	<T>fieldValidation(emailPattern, 'common:validation-invalid-email');
