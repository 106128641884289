export default abstract class StringFormatter {
	static FormatFromArray(text: string, args: string[]): string {
		for (let index = 0; index < args.length; index++) {
			text = text.replace(`{${index}}`, args[index]);
		}

		return text;
	}

	static Format(text: string, ...args: string[]): string {
		return this.FormatFromArray(text, args);
	}
}

export const joinTwoValuesWithSpace = (
	first: string | number | null | undefined,
	second: string | number | null | undefined
) => `${first || ''} ${second || ''}`;

export const joinTwoValuesWithCommaAndSpace = (
	first: string | number | null | undefined,
	second: string | number | null | undefined
) => `${first || ''}${second ? `, ${second}` : ''}`;

export const formatValueWithSecondInBracket = (
	first: string | number | null | undefined,
	second: string | number | null | undefined
) => (first || '') + (second ? ` (${second})` : '');
