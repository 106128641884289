import { Delete } from '@mui/icons-material';
import {
	GridActionsCellItem,
	GridActionsCellItemProps,
} from '@mui/x-data-grid-pro';
import { MouseEventHandler, forwardRef, memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Id } from 'domain/Id';
import { AreaName } from 'domain/constants';
import { UseDialogStateReturnType } from 'library/hooks';
import { OperationType } from 'library/models/enums/OperationType';
import AuthorizeComponent from 'main/auth/AuthorizeComponent';

export type ProductIndividualRepairDeleteActionItemProps = Pick<
	GridActionsCellItemProps,
	'showInMenu'
> &
	Id &
	Pick<UseDialogStateReturnType<Id>, 'handleOpen'>;

export const ProductIndividualRepairDeleteActionItem = memo(
	forwardRef<
		HTMLButtonElement | null,
		ProductIndividualRepairDeleteActionItemProps
	>(({ handleOpen, id, ...restProps }, ref): JSX.Element => {
		const { t } = useTranslation(['commonButton', 'productIndividual']);

		const handleButtonClicked: MouseEventHandler<
			HTMLButtonElement | HTMLLIElement
		> = (event) => {
			handleOpen({ id });
			event.stopPropagation();
		};

		return (
			<AuthorizeComponent
				areaName={AreaName.Repair}
				permission={OperationType.Delete}
				key={`delete-repair-${id}`}>
				<GridActionsCellItem
					icon={<Delete />}
					label={t('commonButton:delete')}
					ref={ref}
					onClick={handleButtonClicked}
					{...restProps}
				/>
			</AuthorizeComponent>
		);
	})
);

ProductIndividualRepairDeleteActionItem.displayName =
	'ProductIndividualRepairDeleteActionItem';
