import { GridActionsCellItemProps } from '@mui/x-data-grid-pro';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import { PdfIcon } from 'assets/images/documents';
import { LinkActionItem } from 'library/components';
import { ButtonActionItem } from 'library/components/controls/datagrid/ButtonActionItem';
import { getServiceProtocolPrintLink } from 'library/components/link';
import { useDialogState } from 'library/hooks';
import { PrintProtocolDialog } from 'modules/ServiceProtocol/Components/ServiceProtocolPdf/PrintProtocolDialog';

const VITE_IS_SERVICE_PROTOCOL_VIEW = import.meta.env
	.VITE_IS_SERVICE_PROTOCOL_VIEW;

export const ServiceProtocolPrintPdfActionItem = forwardRef<
	HTMLLIElement | null,
	Pick<GridActionsCellItemProps, 'showInMenu'> & {
		protocolId: number;
		hasActivityEvents: boolean;
	}
>(({ protocolId, hasActivityEvents }, ref): JSX.Element => {
	const { t } = useTranslation('commonButton');
	const { handleOpen, open, handleClose } = useDialogState();

	return (
		<>
			{VITE_IS_SERVICE_PROTOCOL_VIEW === 'true' ? (
				<>
					<ButtonActionItem
						ref={ref}
						key="print"
						onClick={handleOpen}
						startIcon={<PdfIcon />}
						showInMenu
						label={t('print')}
					/>

					{open && (
						<PrintProtocolDialog
							isOffline={!hasActivityEvents}
							isOpen
							onClose={handleClose}
							protocolId={protocolId}
						/>
					)}
				</>
			) : (
				<LinkActionItem
					key="print"
					href={getServiceProtocolPrintLink(protocolId)}
					startIcon={<PdfIcon />}
					showInMenu
					label={t('print')}
					target="_blank"
				/>
			)}
		</>
	);
});
