import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { useProductCenterApi } from 'api';
import { unitVariantsQuery } from 'api/queries';
import {
	LoadingWrapper,
	NoDataAvailable,
	PageContentTab,
	SectionTitle,
} from 'library/components/controls';
import { reactPlugin } from 'main/azureInsights/config';
import { useNumberParam } from 'modules/Shared/Components/Hooks/useNumberParam';
import { VariantsDataGrid } from 'modules/UnitOverview/Components/VariantsDataGrid';

const VariantsSectionPage = (): JSX.Element => {
	const { t } = useTranslation('unitOverview');
	const { api } = useProductCenterApi();

	const unitId = useNumberParam('unitId');

	const variantsQuery = useQuery({
		...unitVariantsQuery(api, unitId),
		enabled: unitId != null && !isNaN(unitId),
	});

	return (
		<PageContentTab>
			<LoadingWrapper loading={!variantsQuery.isSuccess}>
				{variantsQuery.data?.rows ? (
					<VariantsDataGrid model={variantsQuery.data} />
				) : (
					<>
						<SectionTitle title={t('variants-title')} />
						<NoDataAvailable />
					</>
				)}
			</LoadingWrapper>
		</PageContentTab>
	);
};

export default withAITracking(
	reactPlugin,
	VariantsSectionPage,
	'UnitOverviewVariantsSectionPage',
	'application-insight-container'
);
