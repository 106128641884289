import { Button, ButtonProps } from '@mui/material';
import { noop } from 'lodash';
import { MouseEvent as ReactMouseEvent, useRef } from 'react';
import useDoubleClick from 'use-double-click';

export const BlockedDoubleClickButton = ({
	onClick = noop,
	...props
}: ButtonProps) => {
	const buttonRef = useRef<HTMLButtonElement>(null);

	useDoubleClick<HTMLButtonElement>({
		onSingleClick: (e) => {
			onClick(e as ReactMouseEvent<HTMLButtonElement, MouseEvent>);
		},
		onDoubleClick: noop,
		ref: buttonRef,
		latency: 200,
	});

	return <Button {...props} ref={buttonRef} />;
};
