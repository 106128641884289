import { DrivelineViewDto, ProductIndividualItemPurpose } from 'api';

export const isIPS40 = (productName: string | null) =>
	productName?.startsWith('PD5') ?? false;

export const getEngineFromDriveline = (
	driveline: DrivelineViewDto
): ProductIndividualItemPurpose | null => {
	const enginesInDriveline = driveline.productIndividualSubSystems
		.map((p) =>
			p.productIndividuals.find((p) => p.productCategory === 'Engine')
		)
		.filter((p) => !!p);

	return enginesInDriveline.length > 0 ? enginesInDriveline[0] : null;
};
