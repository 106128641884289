import {
	InteractionRequiredAuthError,
	PublicClientApplication,
} from '@azure/msal-browser';
import { MsalProvider as ReactMsalProvider } from '@azure/msal-react';
import { PropsWithChildren, useCallback } from 'react';

import { isDevEnv } from 'library/constants';
import { msalConfig, redirectRequest, silentRequest } from 'main/auth/config';

import { Authenticate } from '../Authenticate';
import { ClientSideNavigation } from '../ClientSideNavigation';
import { AuthenticationProvider } from './Provider';

export class MsalProvider extends AuthenticationProvider {
	private static msalInstance = new PublicClientApplication(msalConfig);
	// todo - in future try to refactor to use MsalContext,
	// since it has more properties available, but that would require ReactMsalProvider somewhere higher up the tree

	public isAuthenticated(): Promise<boolean> {
		return MsalProvider.msalInstance
			.acquireTokenSilent({
				...silentRequest,
				account:
					MsalProvider.msalInstance.getActiveAccount() || undefined,
			})
			.then(() => true)
			.catch(() => false);
	}

	public async getTokenAsync(): Promise<string> {
		return MsalProvider.msalInstance
			.acquireTokenSilent({
				...silentRequest,
				account:
					MsalProvider.msalInstance.getActiveAccount() || undefined,
			})
			.then(({ accessToken }) => accessToken)
			.catch((error) => {
				if (isDevEnv) {
					const logger = MsalProvider.msalInstance.getLogger();
					logger.error(error);
				}

				if (
					error instanceof InteractionRequiredAuthError &&
					MsalProvider.msalInstance.getActiveAccount()
				) {
					MsalProvider.msalInstance.acquireTokenRedirect(
						redirectRequest
					);
				}
				return '';
			});
	}

	public getComponent(): ({ children }: PropsWithChildren) => JSX.Element {
		return ({ children }: PropsWithChildren) => {
			const handleOnMsalChanged = useCallback(
				(isMsalInitialized: boolean) => {
					this.onAuthenticatedCallback(isMsalInitialized);
				},
				[]
			);

			return (
				<ReactMsalProvider instance={MsalProvider.msalInstance}>
					<ClientSideNavigation instance={MsalProvider.msalInstance}>
						<Authenticate onMsalChanged={handleOnMsalChanged}>
							{children}
						</Authenticate>
					</ClientSideNavigation>
				</ReactMsalProvider>
			);
		};
	}
}
