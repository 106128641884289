import { InfoTwoTone, WarningAmber } from '@mui/icons-material';
import { SxProps } from '@mui/material';
import { useMemo } from 'react';

import { CampaignType } from 'api';
import { mapCampaignTypeToColor } from 'domain/campaign';
import { Sxable } from 'library/types';

interface CampaignWarningProps extends Sxable {
	campaignType: CampaignType;
}

const defaultIconSx: SxProps = {
	fontSize: 'big',
	background: 'white',
	borderRadius: '50%',
};

export const CampaignWarning = ({
	campaignType,
	sx = {},
}: CampaignWarningProps): JSX.Element => {
	const Icon = useMemo(() => {
		const props = {
			color: mapCampaignTypeToColor(campaignType),
			sx: { ...defaultIconSx, ...sx },
		};
		const IconType =
			campaignType === 'Service' ? InfoTwoTone : WarningAmber;

		return <IconType {...props} />;
	}, [campaignType, sx]);

	return Icon;
};
