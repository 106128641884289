import Box from '@mui/material/Box';
import CircularProgress, {
	CircularProgressProps,
} from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { noop } from 'lodash';
import { useEffect, useRef, useState } from 'react';

interface CustomCCircularProgressProps {
	max: number;
	value: number;
	unit?: string;
}

const CustomCircularProgress = ({
	max,
	value,
	unit = '%',
	...props
}: CircularProgressProps & CustomCCircularProgressProps) => (
	<Box sx={{ position: 'relative', display: 'inline-flex' }}>
		<CircularProgress
			variant="determinate"
			value={Math.round((value / max) * 100)}
			{...props}
		/>
		<Box
			sx={{
				top: 0,
				left: 0,
				bottom: 0,
				right: 0,
				position: 'absolute',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
			}}>
			<Typography
				variant="caption"
				component="div"
				color="text.secondary">{`${Math.round(
				value
			)}${unit}`}</Typography>
		</Box>
	</Box>
);

interface CircularWithValueLabelProps {
	end?: number;
	onElapsed?: () => void;
	start?: number;
	step?: number;
	unit?: string;
}

export const CircularWithValueLabel = ({
	end = 100,
	onElapsed = noop,
	start = 0,
	step = 1,
	unit = '%',
}: CircularWithValueLabelProps) => {
	const [progress, setProgress] = useState(start);
	const max = useRef(end > start ? end : start);
	const min = useRef(end < start ? end : start);

	useEffect(() => {
		const progressFn =
			end > start
				? (progress: number) => progress + step
				: (progress: number) => progress - step;

		const timer = setInterval(() => {
			setProgress((prevProgress) => {
				let progress = prevProgress;
				if (prevProgress > min.current) {
					progress = progressFn(progress);
				} else {
					clearInterval(timer);
					onElapsed();
				}

				return progress;
			});
		}, 1000);

		return () => {
			clearInterval(timer);
		};
	}, []);

	return (
		<CustomCircularProgress
			max={max.current}
			value={progress}
			unit={unit}
		/>
	);
};
