import { useTranslation } from 'react-i18next';

import { useProductCenterApi } from 'api';
import { useQueryUnitPurposes } from 'api/queries';
import { TextDisplay } from 'library/components';

export const UnitPurposeDisplay = ({
	unitPurpose,
}: {
	unitPurpose: string | null;
}): JSX.Element => {
	const { t } = useTranslation('unitCreation');
	const { api } = useProductCenterApi();
	const unitPurposesQuery = useQueryUnitPurposes(api);

	const unitPurposeOption = unitPurpose
		? unitPurposesQuery.data?.find(
				(item) =>
					item.value.localeCompare(unitPurpose, undefined, {
						sensitivity: 'accent',
					}) === 0
			)
		: null;

	return (
		<TextDisplay
			label={t('configuration-select-unit-purpose')}
			loading={unitPurposesQuery.isLoading}
			value={unitPurposeOption?.text ?? unitPurpose}
		/>
	);
};
