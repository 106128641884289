import {
	FormControl,
	FormControlLabel,
	FormHelperText,
	FormLabel,
	Radio,
	RadioGroup,
	RadioProps,
} from '@mui/material';
import { isBoolean, isNumber, noop, toInteger } from 'lodash';
import { ChangeEvent, MouseEvent } from 'react';
import {
	FieldPath,
	FieldValues,
	UseControllerProps,
	useController,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Option } from 'library/models/Option';
import { Sxable } from 'library/types';

import { displayError, getHelperText } from './functions';

type RadioValueType = string | number | boolean;

interface ControlledRadioButtonGroupProps<T> extends Sxable {
	options: Option<T>[];
	row?: boolean;
	label?: string;
	helperText?: string;
	radioProps?: RadioProps;
	required?: boolean;
	disabled?: boolean;
	unselectable?: boolean;
}

export type RadioButtonGroupProps<
	TOption,
	TFieldValues extends FieldValues = FieldValues,
	TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = Pick<UseControllerProps<TFieldValues, TName>, 'name' | 'control'> &
	ControlledRadioButtonGroupProps<TOption>;

function mapToOutputType(
	value: string,
	checkValue: RadioValueType
): RadioValueType {
	if (isBoolean(checkValue)) {
		return value === 'true';
	}
	if (isNumber(checkValue)) {
		return toInteger(value);
	}

	return value;
}

export const ControlledRadioButtonGroup = <
	TOption extends RadioValueType,
	TFieldValues extends FieldValues = FieldValues,
	TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
	options,
	row,
	name,
	control,
	label,
	helperText,
	radioProps,
	required,
	disabled,
	sx = [],
	unselectable = false,
}: RadioButtonGroupProps<TOption, TFieldValues, TName>): JSX.Element => {
	const { t } = useTranslation();
	const {
		field: { onChange, onBlur, value, ref },
		fieldState: { error },
	} = useController<TFieldValues, TName>({
		name,
		control,
		rules: {
			validate: required
				? (radioValue) => radioValue != null
				: () => true,
		},
	});

	const onRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
		const radioValue = (event.target as HTMLInputElement).value;
		onChange(mapToOutputType(radioValue, options[0].id));
	};

	const handleOnClick = (event: MouseEvent<HTMLButtonElement>) => {
		if ((event.target as HTMLInputElement).value === value) {
			onChange('');
		}
	};

	const parsedHelperText = getHelperText(t, disabled, error, helperText);
	const isErrored = displayError(disabled, error);
	return (
		<FormControl error={isErrored} disabled={disabled} required={required}>
			{label && (
				<FormLabel
					disabled={disabled}
					required={required}
					error={isErrored}>
					{label}
				</FormLabel>
			)}
			<RadioGroup
				sx={[...(Array.isArray(sx) ? sx : [sx])]}
				row={row}
				ref={ref}
				onChange={onRadioChange}
				onBlur={onBlur}
				name={name}
				value={value ?? ''}>
				{options.map((option) => (
					<FormControlLabel
						key={option.label}
						value={option.id}
						control={
							<Radio
								sx={{
									color: isErrored ? 'error.main' : 'inherit',
								}}
								onClick={unselectable ? handleOnClick : noop}
								{...radioProps}
							/>
						}
						label={option.label}
					/>
				))}
			</RadioGroup>
			{parsedHelperText && (
				<FormHelperText error={isErrored}>
					{parsedHelperText}
				</FormHelperText>
			)}
		</FormControl>
	);
};
