import { IdLabelDto } from 'api/responses/models/Common/IdLabelDto';

export const customModelKey = 'otherModel';

export const customBrandKey = 'otherBrand';

export const isCustomBrand = (brandValue: string | null | undefined) =>
	brandValue === customBrandKey;

export const isCustomModel = (modelValue: string | null | undefined) =>
	modelValue === customModelKey;

export const getBrand = (
	brand: IdLabelDto | null | undefined,
	customBrand: string | null | undefined
) => (isCustomBrand(brand?.id) ? customBrand : brand?.label) ?? null;

export const getModel = (
	brand: IdLabelDto | null | undefined,
	customBrand: string | null | undefined
) => (isCustomModel(brand?.id) ? customBrand : brand?.label) ?? null;
