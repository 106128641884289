import { Box, Typography, useTheme } from '@mui/material';

import { getLocalizedDateFormat } from 'library/formatters';
import { Comment as CommentType } from 'library/models/Comment';
import { Sxable } from 'library/types';
import Variables from 'library/utils/themes/Variables';

interface CommentProps extends Sxable {
	comment: CommentType;
	isAdded?: boolean;
}

export const Comment = ({
	comment: { description, modifiedBy, modificationDate },
	isAdded = false,
	sx,
}: CommentProps) => {
	const {
		custom: { getAnimation },
	} = useTheme<Variables>();

	return (
		<Box
			sx={[
				(theme) => ({
					...(isAdded ? getAnimation(theme) : {}),
					backgroundColor: theme.palette.grey.A400,
					borderRadius: '5px',
					border: `1px solid ${theme.palette.grey.A200}`,
					margin: ({ spacing }) => ({
						xs: spacing(1, 0, 1, 0),
						sm: spacing(2, 0, 2, 0),
					}),
					padding: ({ spacing }) => ({
						xs: spacing(0.5, 1, 0.25, 1),
						sm: spacing(1, 1, 0.25, 1.25),
					}),
					overflowWrap: 'break-word',
				}),
				...(Array.isArray(sx) ? sx : [sx]),
			]}>
			<Typography variant="body1">{description}</Typography>
			<Typography
				variant="body2"
				sx={{
					marginTop: (theme) => theme.spacing(2),
					textAlign: 'right',
				}}>{`${getLocalizedDateFormat(
				modificationDate
			)} ${modifiedBy}`}</Typography>
		</Box>
	);
};
