import { AreaName } from 'domain/constants';
import { Area } from 'library/models/Area';
import { OperationType } from 'library/models/enums/OperationType';

export const createPageArea = (
	areaName: AreaName,
	permissions: OperationType[]
): Area => {
	return {
		name: areaName,
		permissions: permissions,
	} as Area;
};

export const createArea = (
	areaName: AreaName,
	permission: OperationType
): Area => {
	return {
		name: areaName,
		permissions: [permission],
	} as Area;
};
