import { useTranslation } from 'react-i18next';

import { Unit } from 'api/responses/models/Unit/UnitStructure/Unit';
import { UnitIdentificationDisplay } from 'modules/UnitOverview/Components/UnitIdentificationDisplay';

import { IdentifiersContainer } from '../Components/IdentifiersContainer';

export const IdentifiersSection = ({
	unitData,
}: {
	unitData: Unit;
}): JSX.Element => {
	const { t } = useTranslation('unitCreation');
	return (
		<IdentifiersContainer
			variant="bottomBorder"
			title={t('unit-information-identifiers-title')}
		>
			<UnitIdentificationDisplay
				mainSegment={unitData.mainSegment}
				unitIdentification={unitData.identification}
			/>
		</IdentifiersContainer>
	);
};
