import { Skeleton } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { memo, useEffect } from 'react';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useProductCenterApi } from 'api';
import { hullIdStandardQuery } from 'api/queries';
import { isIsoHullId, isUsCoastGuardHullId } from 'domain/unit/hullIdSelector';
import {
	CALL_SIGN_MASK,
	IMO_NUMBER_MASK,
	ISO10087_MASK,
	MMSI_NUMBER_MASK,
	US_COAST_GUARD_MASK,
} from 'domain/unit/validationPatterns';
import {
	ControlledFormattedTextField,
	ControlledSelect,
} from 'library/components/controls';
import { PLACEHOLDER_UNDERSCORE } from 'library/constants/InputMasks';
import { selectUser } from 'library/redux/application';
import { useAppSelector } from 'library/redux/hooks';

import {
	callSignFieldValidation,
	imoNumberFieldValidation,
	iso10087FieldValidation,
	mmsiNumberFieldValidation,
	usCoastGuardFieldValidation,
	validateMarineIdentifiersSection,
} from '../Validations';
import { FormSectionProps } from './Types';

export const IdentifiersMarineFields = memo(
	({ control, resetField }: Required<FormSectionProps>) => {
		const { t } = useTranslation(['unitCreation', 'commonUnit']);
		const { api } = useProductCenterApi();

		const hullIdStandard = useWatch({ control, name: 'hullIdStandard' });

		const countryCode = useAppSelector(selectUser)?.language;
		const { data: hullIdStandardsData } = useQuery(
			hullIdStandardQuery(api, countryCode)
		);

		useEffect(() => {
			if (!hullIdStandard && hullIdStandardsData?.at(0)?.id) {
				resetField('hullIdStandard', {
					defaultValue: hullIdStandardsData[0].id,
				});
			}
		}, [resetField, hullIdStandard, hullIdStandardsData]);

		return (
			<>
				<ControlledSelect
					id="hull-id-standard-select"
					label={t(
						'unitCreation:unit-information-hull-id-standard-select'
					)}
					control={control}
					name="hullIdStandard"
					size="small"
					options={hullIdStandardsData || []}
				/>
				{isIsoHullId(hullIdStandard) && (
					<ControlledFormattedTextField
						id="text-field-iso10087"
						label={t(
							'unitCreation:unit-information-iso10087-label'
						)}
						control={control}
						name="hullId"
						size="small"
						imaskProps={{
							mask: ISO10087_MASK,
							placeholderChar: PLACEHOLDER_UNDERSCORE,
						}}
						validation={validateMarineIdentifiersSection(
							iso10087FieldValidation
						)}
					/>
				)}
				{isUsCoastGuardHullId(hullIdStandard) && (
					<ControlledFormattedTextField
						id="text-field-us-coast-guard"
						label={t(
							'unitCreation:unit-information-us-coast-guard-label'
						)}
						control={control}
						name="hullId"
						size="small"
						imaskProps={{
							mask: US_COAST_GUARD_MASK,
							placeholderChar: PLACEHOLDER_UNDERSCORE,
						}}
						validation={validateMarineIdentifiersSection(
							usCoastGuardFieldValidation
						)}
					/>
				)}
				{!hullIdStandard && (
					<Skeleton variant="rounded" height="unset" />
				)}
				<ControlledFormattedTextField
					id="text-field-imo-number"
					label={t('commonUnit:imo-number')}
					control={control}
					name="imoNumber"
					size="small"
					type="tel"
					imaskProps={{
						mask: IMO_NUMBER_MASK,
						placeholderChar: PLACEHOLDER_UNDERSCORE,
					}}
					validation={validateMarineIdentifiersSection(
						imoNumberFieldValidation
					)}
				/>
				<ControlledFormattedTextField
					id="text-field-mmsi-number"
					label={t('commonUnit:mmsi-number')}
					control={control}
					name="mmsiNumber"
					size="small"
					type="tel"
					imaskProps={{
						mask: MMSI_NUMBER_MASK,
						placeholderChar: PLACEHOLDER_UNDERSCORE,
					}}
					validation={validateMarineIdentifiersSection(
						mmsiNumberFieldValidation
					)}
				/>
				<ControlledFormattedTextField
					id="text-field-call-sign"
					label={t('commonUnit:call-sign')}
					control={control}
					name="callSign"
					size="small"
					imaskProps={{
						mask: CALL_SIGN_MASK,
						placeholderChar: PLACEHOLDER_UNDERSCORE,
					}}
					validation={validateMarineIdentifiersSection(
						callSignFieldValidation
					)}
				/>
			</>
		);
	}
);
