import { Link } from 'react-router-dom';

interface IMenuLink {
	url: string;
}

const MenuLink = ({ url }: IMenuLink): JSX.Element => {
	return url.startsWith('http') ? (
		//This is needed as <Link component adds absolute url one more time
		<a href={url}></a>
	) : (
		<Link to={url} />
	);
};

export default MenuLink;
