import { AreaName } from 'domain/constants';
import { TabConfiguration } from 'library/components/controls';
import { OperationType } from 'library/models/enums/OperationType';
import { createArea } from 'library/utils/helpers/AreaHelper';
import {
	productIndividualCampaigns,
	productIndividualClaims,
	productIndividualConfiguration,
	productIndividualDocuments,
	productIndividualEventLog,
	productIndividualLogisticTrail,
	productIndividualParts,
	productIndividualPolicy,
	productIndividualRepairs,
	productIndividualTechnical,
	productIndividualVstCodes,
} from 'main/routes/urls/RouteUrls';

export const productIndividualviewTabConfiguration: TabConfiguration[] = [
	{
		title: 'productIndividual:technical-info-title',
		path: productIndividualTechnical,
	},
	{
		title: 'productIndividual:campaigns-title',
		path: productIndividualCampaigns,
	},
	{
		title: 'productIndividual:claims-title',
		path: productIndividualClaims,
		areaSecurity: createArea(
			AreaName.IndividualViewClaim,
			OperationType.Read
		),
	},
	{
		title: 'productIndividual:parts-title',
		path: productIndividualParts,
	},
	{
		title: 'productIndividual:event-log-title',
		path: productIndividualEventLog,
		areaSecurity: createArea(
			AreaName.ProductIndividualEventLog,
			OperationType.Read
		),
	},
	{
		title: 'productIndividual:configuration-title',
		path: productIndividualConfiguration,
	},
	{
		title: 'productIndividual:logistic-trail-title',
		path: productIndividualLogisticTrail,
		areaSecurity: createArea(AreaName.LogisticTrail, OperationType.Read),
	},
	{
		title: 'productIndividual:vst-codes-title',
		path: productIndividualVstCodes,
	},
	{
		title: 'productIndividual:documents-title',
		path: productIndividualDocuments,
		areaSecurity: createArea(
			AreaName.UploadProductionAndClassificationDocuments,
			OperationType.Read
		),
	},
	{
		title: 'productIndividual:repairs-title',
		path: productIndividualRepairs,
		areaSecurity: createArea(AreaName.Repair, OperationType.Read),
	},
	{
		title: 'productIndividual:policy-title',
		path: productIndividualPolicy,
	},
];
