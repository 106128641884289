import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import {
	ICustomProperties,
	IExceptionTelemetry,
} from '@microsoft/applicationinsights-web';
import { useEffect } from 'react';

interface ApplicationInsightsTrackerProps {
	metric: IExceptionTelemetry;
	properties?: ICustomProperties;
}

export const ApplicationInsightsTrackerException = ({
	metric,
	properties,
}: ApplicationInsightsTrackerProps) => {
	const appInsights = useAppInsightsContext();

	useEffect(() => {
		appInsights.trackException(metric, properties);
	}, []);

	return <></>;
};
