import * as React from 'react';

import { DisplayDevice } from '../../../../../library/models/enums/DisplayDevice';
import BackButton from '../components/BackButton';

interface IMobileInfoWrapper {
	label: string;
	goToPreviousMenu: () => void;
	displayDevice: DisplayDevice;
}

const MobileInfoWrapper = ({
	label,
	goToPreviousMenu,
	displayDevice,
}: IMobileInfoWrapper): JSX.Element => {
	return (
		<div
			className={'header__nav__mobile-info-wrapper'}
			id={`header__nav__mobile-info-wrapper--${displayDevice}`}
		>
			<BackButton
				goToPreviousMenu={goToPreviousMenu}
				displayDevice={displayDevice}
			/>
			<p>{label}</p>
		</div>
	);
};

export default React.memo(MobileInfoWrapper);
