import { LoadingButton, LoadingButtonProps } from '@mui/lab';
import { noop } from 'lodash';
import { MouseEvent as ReactMouseEvent, useRef } from 'react';
import useDoubleClick from 'use-double-click';

export const BlockedDoubleClickLoadingButton = ({
	onClick = noop,
	...props
}: LoadingButtonProps) => {
	const buttonRef = useRef<HTMLButtonElement>(null);

	useDoubleClick<HTMLButtonElement>({
		onSingleClick: (e) => {
			onClick(e as ReactMouseEvent<HTMLButtonElement, MouseEvent>);
		},
		onDoubleClick: noop,
		ref: buttonRef,
		latency: 200,
	});

	return <LoadingButton {...props} ref={buttonRef} />;
};
