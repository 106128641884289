import { SvgIcon, SvgIconProps } from '@mui/material';

export const Trash = (props: SvgIconProps) => (
	<SvgIcon {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M9.49951 2.5C9.49951 2.22386 9.72337 2 9.99951 2L13.9995 2C14.2757 2 14.4995 2.22386 14.4995 2.5V4L18.4995 4H19.4995C19.7757 4 19.9995 4.22386 19.9995 4.5C19.9995 4.77614 19.7757 5 19.4995 5H18.9441L16.9961 21.5584C16.9665 21.8102 16.7531 22 16.4995 22L7.49951 22C7.24597 22 7.03256 21.8102 7.00294 21.5584L5.05489 5H4.49951C4.22337 5 3.99951 4.77614 3.99951 4.5C3.99951 4.22386 4.22337 4 4.49951 4H5.49951L9.49951 4V2.5ZM13.4995 3V4H10.4995V3L13.4995 3ZM6.06178 5L9.99951 5H13.9995L17.9372 5L16.0549 21L7.94414 21L6.06178 5ZM9.99951 7.5C9.99951 7.22386 9.77565 7 9.49951 7C9.22337 7 8.99951 7.22386 8.99951 7.5L8.99951 11.5C8.99951 11.7761 9.22337 12 9.49951 12C9.77565 12 9.99951 11.7761 9.99951 11.5L9.99951 7.5ZM12.4995 7.5C12.4995 7.22386 12.2757 7 11.9995 7C11.7234 7 11.4995 7.22386 11.4995 7.5L11.4995 11.5C11.4995 11.7761 11.7234 12 11.9995 12C12.2757 12 12.4995 11.7761 12.4995 11.5V7.5ZM14.9995 7.5C14.9995 7.22386 14.7757 7 14.4995 7C14.2234 7 13.9995 7.22386 13.9995 7.5V11.5C13.9995 11.7761 14.2234 12 14.4995 12C14.7757 12 14.9995 11.7761 14.9995 11.5V7.5Z"
		/>
	</SvgIcon>
);
