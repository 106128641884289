import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const NoDataAvailable = () => {
	const { t } = useTranslation(['common']);

	return (
		<Typography my={1} variant="body1">
			{t('common:no-data-available-message')}
		</Typography>
	);
};
