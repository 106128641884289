import {
	AdditionalFilesAddedAction,
	CreateAdditionalFilesCommand,
	productCenterApi,
} from 'api';
import { ADDITIONAL_FILES_ACTIONS } from 'domain/unit/AdditionalFilesCommand/AdditionalFilesCommand';
import { createWizardSubmitThunk } from 'library/hooks/Wizard/WizardSubmitHelpers';
import {
	FileUploadState,
	mapFileUploadStateToAttachmentFileSet,
} from 'library/redux/fileUpload';
import { RootState } from 'store';

import { selectUnitId } from '../State';

const establishAction = (
	option: ADDITIONAL_FILES_ACTIONS
): AdditionalFilesAddedAction => {
	switch (option) {
		case ADDITIONAL_FILES_ACTIONS.COMMISSIONING:
			return 'COMMISSIONING';
		case ADDITIONAL_FILES_ACTIONS.PRODUCT_IN_OPERATION:
			return 'PRODUCT_IN_OPERATION';
		case ADDITIONAL_FILES_ACTIONS.UNIT_CREATION:
			return 'UNIT_CREATION';
	}
};

export const submitAdditionalFiles = (
	action: ADDITIONAL_FILES_ACTIONS,
	fileSelector: (root: RootState) => FileUploadState,
	comment?: string
) =>
	createWizardSubmitThunk('unitOverview/additionalFiles', (root) => {
		const request: CreateAdditionalFilesCommand = {
			action: establishAction(action),
			attachmentInfo: mapFileUploadStateToAttachmentFileSet(
				fileSelector(root)
			),
			comment,
			unitId: selectUnitId(root),
		};

		return productCenterApi.additionalFiles(request);
	});
