import { Box } from '@mui/material';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ControlledTextField } from 'library/components/controls';
import { MAX_TEXT_LENGTH } from 'library/constants/Validations';
import { CountryInput } from 'modules/EndCustomer/Shared/CountryInput';

import { OperatingLocationFormType } from './OperatingLocationForm';

export const emptyOperatingLocation: OperatingLocationFormType = {
	country: null,
	state: null,
	city: null,
	machineVesselLocation: null,
	zipCode: null,
	address: null,
};

export const OperatingLocationFormContent = ({
	control,
}: {
	control: UseFormReturn<OperatingLocationFormType>['control'];
}): JSX.Element => {
	const { t } = useTranslation(['common']);

	const validation = {
		maxLength: {
			value: MAX_TEXT_LENGTH,
			message: t('common:validation-max-length', {
				length: MAX_TEXT_LENGTH,
			}),
		},
	};

	return (
		<Box
			display="grid"
			gridTemplateColumns={{
				xs: 'repeat(1, 1fr)',
				sm: 'repeat(3, 1fr)',
			}}
			gap={2}>
			<CountryInput
				id="country-input"
				label={t('common:country')}
				name="country"
				control={control}
				validation={{ required: t('validation-error-message') }}
			/>
			<ControlledTextField
				id="state-input"
				label={t('common:county')}
				name="state"
				control={control}
				validation={validation}
			/>
			<ControlledTextField
				id="city-input"
				label={t('common:city')}
				name="city"
				control={control}
				validation={validation}
			/>
			<ControlledTextField
				id="machine-location-input"
				label={t('common:machine-location')}
				name="machineVesselLocation"
				sx={{ gridColumn: { sm: 'span 3' } }}
				control={control}
				validation={validation}
			/>
			<ControlledTextField
				id="zip-code-input"
				label={t('common:zip-code')}
				name="zipCode"
				control={control}
				validation={validation}
			/>
			<ControlledTextField
				id="address-input"
				label={t('common:address')}
				name="address"
				sx={{ gridColumn: { sm: 'span 2' } }}
				control={control}
				validation={validation}
			/>
		</Box>
	);
};
