import { lazy } from 'react';
import { Route } from 'react-router-dom';

import { AreaName } from 'domain/constants';
import { OperationType } from 'library/models/enums/OperationType';
import { createArea } from 'library/utils/helpers/AreaHelper';
import Authorize from 'main/auth/Authorize';

const RegisterEndCustomerPage =
	import.meta.env.VITE_LEGACY_END_CUSTOMER === 'true'
		? lazy(() => import('pages/register/RegisterLegacyEndCustomerPage'))
		: lazy(() => import('pages/register/RegisterEndCustomerPage'));

const RegisterUnitPage = lazy(() => import('pages/register/RegisterUnitPage'));

export const getRegisterRoutes = (): JSX.Element[] => [
	<Route key={'consumer'} path="consumer">
		<Route
			index
			element={
				<Authorize
					area={createArea(AreaName.Consumer, OperationType.Create)}>
					<RegisterEndCustomerPage />
				</Authorize>
			}></Route>
	</Route>,
	<Route
		key={'unit'}
		path={'unit'}
		element={
			<Authorize area={createArea(AreaName.Unit, OperationType.Create)}>
				<RegisterUnitPage />
			</Authorize>
		}
	/>,
];
