import { BaseRequest, IRequest } from './BaseRequest';

export interface IPutRequest<T = unknown> extends IRequest {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	params?: any;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	data?: T;
}

export class PutRequest extends BaseRequest implements IPutRequest {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	params?: any;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	data?: any;

	constructor(putRequest: IPutRequest) {
		super(putRequest);

		this.method = 'PUT';
		this.params = putRequest.params;
		this.data = putRequest.data;
	}
}
