import { lazy } from 'react';
import { Outlet, Route } from 'react-router-dom';

import { AreaName } from 'domain/constants';
import { OperationType } from 'library/models/enums/OperationType';
import { createArea } from 'library/utils/helpers/AreaHelper';
import Authorize from 'main/auth/Authorize';

const MaintenancePlannerPage = lazy(
	() => import('pages/maintenanceplanner/MaintenancePlannerPage')
);

const MaintenancePlannerSearchPage = lazy(
	() => import('pages/maintenanceplanner/MaintenancePlannerSearchPage')
);

export const getMaintenancePlannerRoutes = (): JSX.Element[] => [
	<Route
		key={'MaintenancePlanner'}
		path={''}
		element={
			<Authorize
				area={createArea(
					AreaName.MaintenancePlanner,
					OperationType.Read
				)}>
				<Outlet />
			</Authorize>
		}>
		<Route index element={<MaintenancePlannerPage />} />
		<Route path={'SearchPage'} element={<MaintenancePlannerSearchPage />} />
	</Route>,
];
