import { Link as MUILink, SxProps, styled, useTheme } from '@mui/material';
import { HTMLAttributeAnchorTarget, PropsWithChildren } from 'react';
import { generatePath } from 'react-router-dom';

import { Link as InternalLink, PcLinks } from 'library/components/link';
import { Removable, Sxable } from 'library/types';
import { textOverflowSx } from 'library/utils/themes';
import { productIndividualviewPageUrl } from 'main/routes/urls/RouteUrls';

interface ProductIndividualLinkProps extends Removable, Sxable {
	className?: string;
	color?: string;
	isActive?: boolean;
	section?: string;
	serialNumber?: string | null;
	target?: HTMLAttributeAnchorTarget;
}

const IS_PRODUCT_INDIVIDUAL_VIEW = import.meta.env
	.VITE_IS_PRODUCT_INDIVIDUAL_VIEW;

const getNewPiViewUrl = (serialNumber?: string | null, section: string = '') =>
	generatePath(productIndividualviewPageUrl, {
		serialnumber: serialNumber ?? '',
		section,
	});

export const getPiViewUrl = (
	serialNumber?: string | null,
	section: string = ''
) => {
	if (!serialNumber) {
		return '';
	}

	return IS_PRODUCT_INDIVIDUAL_VIEW === 'true'
		? getNewPiViewUrl(serialNumber, section)
		: PcLinks.getProductIndividualLink(serialNumber || '');
};

// Is new feature available - #577519 - TO REMOVE AFTER PROD RELEASE
export const ProductIndividualLink = ({
	children,
	className,
	section,
	serialNumber,
	sx,
	target = '_self',
}: PropsWithChildren<ProductIndividualLinkProps>) => {
	if (!serialNumber) {
		return <></>;
	}

	return (
		<>
			{IS_PRODUCT_INDIVIDUAL_VIEW === 'true' ? (
				<InternalLink
					className={className}
					sx={sx}
					target={target}
					to={getNewPiViewUrl(serialNumber, section)}>
					{children}
				</InternalLink>
			) : (
				<MUILink
					className={className}
					sx={{
						textDecorationColor: 'inherit',
						...sx,
					}}
					href={PcLinks.getProductIndividualLink(serialNumber || '')}>
					{children}
				</MUILink>
			)}
		</>
	);
};

const defaultProductIndividualTitleLinkSx: SxProps = {
	textDecoration: 'none',
	'&:hover': { textDecoration: 'underline' },
	...textOverflowSx,
};

export const ProductIndividualTitleLink = ({
	color,
	isActive = true,
	isRemoved,
	serialNumber,
	sx,
	title,
	...rest
}: ProductIndividualLinkProps & { title?: string }): JSX.Element => {
	const {
		palette: {
			grey,
			secondary: { main: secondaryMain },
		},
	} = useTheme();

	return (
		<>
			{isActive ? (
				<ProductIndividualLink
					serialNumber={serialNumber}
					sx={{ color: isRemoved ? grey[700] : secondaryMain, ...sx }}
					{...rest}>
					{title || serialNumber}
				</ProductIndividualLink>
			) : (
				<span>{serialNumber}</span>
			)}
		</>
	);
};

const StyledErrorLinksContainer = styled('div')({
	display: 'flex',
	flexDirection: 'column',
});

interface ProductIndividualTitleLinksActionProps extends Sxable {
	serialNumbers: string[];
	title: string;
}

export const ProductIndividualTitleLinksAction = ({
	sx = defaultProductIndividualTitleLinkSx,
	serialNumbers,
	title,
}: ProductIndividualTitleLinksActionProps) => {
	const {
		palette: {
			text: { primary: textPrimary },
		},
	} = useTheme();

	return (
		<StyledErrorLinksContainer>
			{serialNumbers.map((serialNumber) => (
				<ProductIndividualTitleLink
					className="pc-link"
					key={serialNumber}
					sx={{
						...sx,
						color: textPrimary,
						textTransform: 'uppercase',
					}}
					serialNumber={serialNumber}
					title={title}
				/>
			))}
		</StyledErrorLinksContainer>
	);
};
