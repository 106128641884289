import { Box, Paper } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { FileSetDto } from 'api';
import { SectionTitle } from 'library/components';
import { FileSetDisplay } from 'modules/Shared/Components/FileUpload/FileSetDisplay';

export const AdditionalFilesSection = ({
	fileSets,
	additionalAction,
}: {
	fileSets: FileSetDto[];
	additionalAction?: React.ReactNode;
}): JSX.Element => {
	const { t } = useTranslation(['unitOverview', 'fileUpload']);

	return (
		<>
			<SectionTitle
				title={t('unitOverview:additional-files-title')}
				titleVariant="h6">
				{additionalAction}
			</SectionTitle>
			<Box display="grid" gap={3}>
				{fileSets?.map((fileSet) => (
					<Paper key={fileSet.id} variant="bottomBorder">
						<FileSetDisplay {...fileSet} />
					</Paper>
				))}
			</Box>
		</>
	);
};
